import Config from "@/Configuration/Config";
import axios from "axios"

export default {
    allheader: Config.apiBaseUrl + "listoptionheader/getheader",
    getallheaders: Config.apiBaseUrl + "listoptionheader/getheader?clientId=",
    getOptionurl: Config.apiBaseUrl + "listoption/getoptions?clientId=",
    getOptionsByHeaderIdurl: Config.apiBaseUrl + "listoption/getoptionlistbyid?clientId=",
    addOptionurl: Config.apiBaseUrl + "listoption/add",
    updateOptionurl: Config.apiBaseUrl + "listoption/update",
    deleteOptionurl: Config.apiBaseUrl + "listoption/delete?optionId=",
    addOptionHeaderUrl :Config.apiBaseUrl +"listoptionheader/add",
    getOptionsByKeyUrl : Config.apiBaseUrl + "listoption/getoptionlistbykey?clientId=",
    copyOptionsUrl: Config.apiBaseUrl +"listoption/copy",
    allHeaders(filter) {
        return axios.get(this.allheader + "?search=" + filter.search + "&sort=" + filter.sort + "&limit=" + filter.pageSize + "&page=" + filter.currentPage);
    },
    getAllHeaders(clientId) {
        return axios.get(this.getallheaders+clientId+'&limit=1500');
    },
    getHeaderbyId(id) {
        return axios.get(this.geturl + id);
    },
    getOptions(filter) {
        return axios.get(this.getOptionurl + filter.clientId + "&headerId=" + filter.headerId + "&search=" + filter.search + "&sort=" + filter.sort + "&limit=" + filter.pageSize + "&page=" + filter.currentPage);
    },
    getOptionsByHeaderId(clientId,headerId) {
        if (clientId === undefined || clientId === null){
            clientId = 0
        }
        return axios.get(this.getOptionsByHeaderIdurl+clientId+"&headerId=" + headerId)
    },
    addOption(option) {
        return axios.post(this.addOptionurl, option);
    },
    updateOption(option) {
        return axios.put(this.updateOptionurl, option);
    },
    deleteOption(id) {
        return axios.delete(this.deleteOptionurl + id);
    },
    addOptionHeader(header){
        return axios.post(this.addOptionHeaderUrl, header)
    },
    getOptionsByKey(clientId, key){
        return axios.get(this.getOptionsByKeyUrl+ clientId+"&key="+key)
    },

    copyOptions(model) {
        return axios.post(this.copyOptionsUrl,model)
    }

}