<template>
  <div class="container-fluid">
    <div class="container-fluid border-bottom mb-2 p-0">
      <div class="row d-flex align-items-center custom-toolbar">
        <div class="col-12 col-md-8">
          <BreadCrumb :showBread="false"/>
        </div>
        <div class="col-12 col-md-4 d-flex justify-content-end">
          <el-button @click="showAddDialog"
                     class="border-0 mbtn-primary ms-2 text-capitalize">{{
              getMessageByCode('add') ?? 'Add'
            }}
          </el-button>
        </div>
      </div>
    </div>
    <el-tabs v-model="activeTab" type="border-card" @tab-click="handleTabClick">
      <el-tab-pane :name="1" :label="getMessageByCode('markets') ?? 'Markets'">
        <template #label>
        <span class="custom-tabs-label">
          <i class="bi bi-bank me-1"></i>
          <span>{{ getMessageByCode('markets') ?? 'Markets' }}</span>
        </span>
        </template>
        <el-table :data="Markets"
                  :fit="true" :flexible="true" :selectable="(row) => row.status !== 'disabled'" border
                  header-cell-class-name="tbl-header"
                  stripe>
          <template #append>
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :total="filter.totalCount"
                :page="filter.currentPage"
                :page-size="filter.pageSize"
                :page-sizes="[10, 20, 30, 40]"
                layout=" prev, pager, next,sizes"
                class="bg-gray p-2"
                background
                size="small"
            >
            </el-pagination>
          </template>
          <el-table-column label="#" type="index" align="center" width="55"></el-table-column>
          <el-table-column :label="getMessageByCode('title')??'Title'" prop="title" sortable></el-table-column>
          <el-table-column :label="getMessageByCode('active')??'Active'" prop="isActive">
            <template #default="scope">
              <el-tag v-if="scope.row.isActive" type="success" size="small">Yes</el-tag>
              <el-tag v-else type="danger" size="small">Yes</el-tag>
            </template>
          </el-table-column>
          <el-table-column align="center" fixed="right" :label="getMessageByCode('actions')??'Actions'" width="130">
            <template #default="scope">
              <el-space alignment="center" spacer="|">
                <i
                    class="mgc_edit_line text-primary action-btn"
                    @click="editMarket(scope.row,'edit')"
                ></i>
                <i @click="editMarket(scope.row,'del')"
                   class="mgc_delete_2_line text-danger action-btn"
                ></i>
              </el-space>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane :name="2" :label="getMessageByCode('permissions') ?? 'Permissions'">
        <template #label>
        <span class="custom-tabs-label">
          <i class="bi bi-question-octagon me-1"></i>
          <span>{{ getMessageByCode('permissions') ?? 'Permissions' }}</span>
        </span>
        </template>
        <div class="row justify-content-end">
          <div class="col-12 col-md-4 d-flex justify-content-end my-2">
            <el-select v-model="filter.marketId" class="me-2" @change="getMarketFeatures()">
              <el-option v-for="market in Markets" :key="market.id" :label="market.title"
                         :value="market.id"></el-option>
            </el-select>
            <el-button type="primary" @click="addPermissionDialog = true;">
              {{ getMessageByCode('addPermission') ?? 'Add Permission' }}
            </el-button>
          </div>
        </div>
        <el-table :data="marketPermissions"
                  :fit="true" :flexible="true" :selectable="(row) => row.status !== 'disabled'" border
                  header-cell-class-name="tbl-header"
                  stripe>
          <template #append>
            <el-pagination
                @size-change="marketPermissionsPageSizeChange"
                @current-change="marketPermissionsCurrentChange"
                :total="filter.totalCount"
                :page="filter.currentPage"
                :page-size="filter.pageSize"
                :page-sizes="[10, 20, 30, 40]"
                layout=" prev, pager, next,sizes"
                class="bg-gray p-2"
                background
                size="small"
            >
            </el-pagination>
          </template>
          <el-table-column label="#" type="index" align="center" width="55"></el-table-column>
          <el-table-column type="expand" style="width: 100% !important;">
            <template #default="scope">
              <div v-for="(perm,index) in scope.row.featurePermissions" :key="index"
                   class=" py-2 border-bottom">
                <div class="row px-5 w-100 w-md-50">
                  <span class="col-2">{{ index + 1 }}</span>
                  <div class="text-start col-8">
                  <div class="row">
                    <span class="col-6">{{ perm.permissionId }}</span>
                    <span class="col-6">{{perm.permissionDesc}}</span>
                  </div>
                  </div>
                </div>
              </div>
            </template>

          </el-table-column>
          <el-table-column style="width: 100% !important;" :label="getMessageByCode('featureName')??'Feature Name'"
                           prop="featureName" sortable></el-table-column>
          <el-table-column align="center" fixed="right" :label="getMessageByCode('actions')??'Actions'" width="130">
            <template #default="scope">
              <el-space alignment="center" spacer="|">
                <i @click="editPermission(scope.row,'del')"
                   class="mgc_delete_2_line text-danger action-btn"
                ></i>
              </el-space>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>


    <!--    Dialogs-->
    <!--    Edit Dialog-->
    <el-dialog
        v-model="addMarketDialog"
        :title="market.id > 0 ? 'Update Market' : 'Add Market'"
        width="30%"
    >
      <el-form label-position="top">
        <el-form-item>
          <template #label>
            <span class="required">Title</span>
          </template>
          <el-input v-model="market.title"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
      <span class="dialog-footer">
        <el-button bg text @click="addMarketDialog = false">Cancel</el-button>
         <el-button v-if="market.id === 0" :disabled="!market.title" type="primary"
                    @click="addMarketDialog = false; addMarket()">
         Add
        </el-button>
        <el-button v-if="market.id > 0" :disabled="!market.title" type="primary"
                   @click="addMarketDialog = false; updateMarket()">
         Update
        </el-button>
      </span>
      </template>
    </el-dialog>
    <!--    DeleteDialog-->
    <el-dialog
        v-model="deleteDialog"
        title="Delete"
        width="30%"
    >
<span>Are you sure? You want to delete <span
    class="mfw-bold text-danger">{{ market.title }}</span> market? </span>
      <template #footer>
      <span class="dialog-footer">
        <el-button bg text @click="deleteDialog = false">Cancel</el-button>
        <el-button :disabled="!market.title" class="bg-danger text-white border-0"
                   @click="deleteDialog = false; deleteMarket()">
        Delete
        </el-button>
      </span>
      </template>
    </el-dialog>
    <!--    AddPermissionDialog-->
    <el-dialog v-model="addPermissionDialog" :title="getMessageByCode('addSecPerm') ?? 'Add Security Permissions'"
               style="width: auto; max-width: 500px">
      <el-form class="row" label-position="top" require-asterisk-position="right">
        <!--        <el-form-item :label="getMessageByCode('market') ?? 'Market'">
                  <el-select v-model="features.marketId" class="me-2" @change="changeMarketInAdd">
                    <el-option v-for="market in Markets" :key="market.id" :label="market.title"
                               :value="market.id"></el-option>
                  </el-select>
                </el-form-item>-->
        <el-form-item :label="getMessageByCode('permissions') ?? 'Permissions'">
          <el-select v-model="features.features" filterable clearable collapse-tags multiple collapse-tags-tooltip
                     :max-collapse-tags="10" class="me-2" @change="checkPermission(features.features)">
            <el-option v-for="feature in securityFeatures" :key="feature.featureId" :label="feature.featureName"
                       :value="feature.featureId"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="d-flex justify-content-end">
          <el-button text bg @click="addPermissionDialog = false">{{ getMessageByCode('cancel' ?? 'Cancel') }}
          </el-button>
          <el-button type="primary" :loading="loading" @click="addMarketFeatures" class="ms-2">
            {{ getMessageByCode('add' ?? 'Add') }}
          </el-button>
        </div>
      </template>
    </el-dialog>
    <el-dialog
        v-model="deletePermissionDialog"
        :title="getMessageByCode('delPerm') ?? 'Delete Permission'"
        width="30%"
    >
        <span>Are you sure? You want to delete <span
            class="mfw-bold text-danger">{{ featurePermission.featureName }}</span> ? </span>
      <template #footer>
      <span class="dialog-footer">
        <el-button bg text @click="deletePermissionDialog = false">Cancel</el-button>
        <el-button class="bg-danger text-white border-0"
                   @click="deletePermissionDialog = false; deletePermission()">
        Delete
        </el-button>
      </span>
      </template>
    </el-dialog>
    <div v-if="loading" class="spinner-border"></div>
  </div>
</template>
<script>
import BreadCrumb from "@/components/Layout/BreedCrumb.vue";
import marketService from "@/Services/ComponentsServices/marketService";
import {ShowMessage} from "@/Utility/Utility";
import {getLanguage} from "@/Utility/getLanguage";

export default {
  name: "MarketList",
  components: {BreadCrumb},
  data() {
    return {
      loading: false,
      addPermissionDialog: false,
      activeTab: 1,
      Markets: [],
      marketPermissions: [],
      selectedFeatureIds: [],
      translations: [],
      securityFeatures: [],
      addMarketDialog: false,
      deletePermissionDialog: false,
      deleteDialog: false,
      market: {
        "id": 0,
        "title": "",
        "isActive": true,
      },
      features: {
        marketId: 0,
        features: [],
      },
      featurePermission: {},
      filter: {
        marketId: 0,
        clientId: sessionStorage.getItem("clientId") ?? 0,
        providerId: 1,
        search: "",
        sort: "",
        pageSize: 10,
        currentPage: 1,
        totalCount: 0,
        status: 2,
      },
    }
  },
  methods: {
    changeMarketInAdd() {
      this.populateSelectedFeaturesIds(this.marketPermissions, this.features.marketId);
    },
    resetFilter() {
      this.filter = {
        marketId: this.Markets[0].id,
        clientId: sessionStorage.getItem("clientId") ?? 0,
        providerId: 1,
        search: "",
        sort: "",
        pageSize: 10,
        currentPage: 1,
        totalCount: 0,
        status: 2,
      };
    },
    handleTabClick(tabName) {
      this.resetFilter();

      let tab = tabName.index;
      switch (tab) {
        case "0":
          this.getMarkets();
          break;
        case "1":
          this.getMarketFeatures();
          break;
      }
    },
    handleSizeChange(newSize) {
      this.filter.pageSize = newSize;
      this.getMarkets();
    },
    handleCurrentChange(newPage) {
      this.filter.currentPage = newPage;
      this.getMarkets();
    },
    marketPermissionsPageSizeChange(newSize) {
      this.filter.pageSize = newSize;
      this.getMarketFeatures();
    },
    marketPermissionsCurrentChange(newPage) {
      this.filter.currentPage = newPage;
      this.getMarketFeatures();
    },
    editMarket(market, type) {
      this.market = {...market};
      if (type === "edit") {
        this.addMarketDialog = true;
      } else {
        this.deleteDialog = true;
      }
    },
    editPermission(permission, type) {
      this.featurePermission = {...permission};
      if (type === "edit") {
        this.addMarketDialog = true;
      } else {
        this.deletePermissionDialog = true;
      }
    },
    async addMarket() {
      try {
        this.loading = true;
        let response = await marketService.add(this.market);
        if (response.status === 200) {
          await this.getMarkets();
          ShowMessage("success", "Market added successfully");
        }
      } catch (e) {
        console.log(e);
        ShowMessage("error", "Something went wrong!");
      }
    },
    async updateMarket() {
      try {
        this.loading = true;
        let response = await marketService.update(this.market);
        if (response.status === 200) {
          await this.getMarkets();
          ShowMessage("success", "Market update successfully");
        }
        this.loading = false;
      } catch (e) {
        this.loading = false;
        console.log(e);
        ShowMessage("error", "Something went wrong!");
      }
    },
    async deleteMarket() {
      try {
        this.loading = true;
        let response = await marketService.delete(this.market.id);
        if (response.status === 200) {
          await this.getMarkets();
          ShowMessage("success", "Market deleted");
        }
        this.loading = false;
      } catch {
        this.loading = false;
        ShowMessage("error", "Something went wrong!");
      }
    },
    async deletePermission() {
      try {
        this.loading = true;
        let res = await marketService.deletePermission(this.featurePermission)
        if (res?.data) {
          ShowMessage("success", "Permission Deleted");
          await this.getMarketFeatures();
        }
        this.loading = false;
      } catch {
        this.loading = false;
        ShowMessage("error", "Something went wrong!");
      }
    },
    async getMarkets() {
      try {
        this.loading = true;
        let response = await marketService.all(this.filter);
        this.Markets = response.data.items ?? [];
        if (this.Markets.length > 0) {
          this.filter.marketId = this.Markets[0].id;
          this.features.marketId = this.Markets[0].id;
        }
        this.filter.totalCount = response.data.totalCount ?? 0;
        this.filter.currentPage = response.data.currentPage ?? response.data.currentPage === 0 ? 1 : response.data.currentPage || 1;
        this.filter.pageSize = response.data.pageSize;
         this.loading = false;
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
    async getMarketFeatures() {
      try {
        this.loading = true;
        this.features.marketId = this.filter.marketId;
        let response = await marketService.getMarketPermissions(this.filter);
        this.marketPermissions = response.data.items ?? [];
        this.filter.totalCount = response.data.totalCount ?? 0;
        this.filter.currentPage = response.data.currentPage ?? 1;
        this.filter.pageSize = response.data.pageSize;
        this.populateSelectedFeaturesIds(this.marketPermissions, this.filter.marketId);
        this.loading = false;
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
    populateSelectedFeaturesIds(marketPermissions, marketId) {
      this.features.features = [];
      marketPermissions.forEach(item => {
        if (item.featureId && item.marketId === marketId) {
          this.features.features.push(item.featureId);
        }
      });
    },
    async getSecurityFeatures() {
      try {
        this.loading = true;
        let response = await marketService.getSecurityFeatures(this.filter);
        this.securityFeatures = response.data ?? [];
        this.loading = false;
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
    async addMarketFeatures() {
      this.loading = true;
      try {
        let response = await marketService.addFeatures(this.features);
        if (response?.data) {
          ShowMessage("success", "Permissions Added");
          await this.getSecurityFeatures();
        }
        this.addPermissionDialog = false;
      } catch (e) {
        console.log(e)
      }
      this.loading = false;
    },
    showAddDialog() {
      this.market = {
        "id": 0,
        "title": "",
        "isActive": true,
      }
      this.addMarketDialog = true;
    },
    getMessageByCode(code) {
      // Find the object with the matching code
      if (this.translations == null) {
        this.translations = getLanguage();
        return;
      }
      const matchingObject = this.translations.find(obj => obj.code === code);
      return matchingObject ? matchingObject.message : null;
    }
  },
  created() {
    this.translations = getLanguage();
    this.getMarkets();
    this.getSecurityFeatures();
  }
}
</script>

<style scoped>

</style>