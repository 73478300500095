<template>
  <div>
    <span>{{PageTitle}}</span>
    <el-breadcrumb v-if="showBread" separator="/" class="fw-normal mt-2 text-capitalize">
      <el-breadcrumb-item @click="goBack" style="cursor: pointer" class="mfw-bold"><span class="text-primary text-capitalize">{{ getMessageByCode('home')?? 'Home' }}</span></el-breadcrumb-item>
      <el-breadcrumb-item v-for="i in Items" :key="i" >
        <span class="text-capitalize" v-if="(view && !edit ) && i == 'Add' ">{{ getMessageByCode('view')?? 'View' }}</span>
        <span class="text-capitalize" v-else-if="(view && edit ) && i == 'Add' && id > 0">{{getMessageByCode('edit')??'Edit'}}</span>
        <span class="text-capitalize" v-else-if="!view && i == 'Add' && id > 0">{{getMessageByCode('update')??'Update'}}</span>
        <span class="text-capitalize" v-else>{{i}}</span>
      </el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>

<script>
import {getLanguage} from "@/Utility/getLanguage";

export default {
  name: "BreadCrumb",
  props: {
    showBread: {
      type: Boolean,
      default: true
    },
    id:{
      type:Number,
      default: 0
    },
    view:{
      type: Boolean,
      default: false,
    },
    edit:{
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      translations:[],
    }
  },
  methods:{
    goBack() {
      this.$router.go(-1); // Go back to the previous route
    },
    getMessageByCode(code) {
      // Find the object with the matching code
      if(this.translations == null){
        this.translations = getLanguage();
        return ;
      }
      const matchingObject = this.translations.find(obj => obj.code === code);
      return matchingObject ? matchingObject.message : null;
    }
  },
  created() {
    this.translations = getLanguage();
  },
  computed: {
    Items() {
      return this.$route.meta.breadcrumbs;
    },
    PageTitle() {
      return this.$route.meta.pageTitle;
    }
  }
}
</script>

<style scoped>

</style>