import Config from "@/Configuration/Config";
import axios from "axios";

export default {
    getUnlinkedUsersUrl: Config.apiBaseUrl + "syncappuser/getappusers?clientId=",
    getLinkedUsersUrl: Config.apiBaseUrl + "syncappuser/getlinkedcustomers?clientId=",
    getFreeCustomersUrl: Config.apiBaseUrl + "syncappuser/getfreecustomers?clientId=",


    getUnlinkedUsers(filter) {
        return axios.get(this.getUnlinkedUsersUrl + filter.clientId + "&search=" + filter.search + "&sort=" + filter.sort + "&limit=" + filter.pageSize + "&page=" + filter.currentPage);
    },

    getLinkedUsers(filter) {
        return axios.get(this.getLinkedUsersUrl + filter.clientId + "&search=" + filter.search + "&sort=" + filter.sort + "&limit=" + filter.pageSize + "&page=" + filter.currentPage);
    },
    getFreeCustomers(clientId) {
        return axios.get(this.getFreeCustomersUrl + clientId)
    }

}