<template>
  <div class="container-fluid">
    <ToolBar :ShowAddBtn="clientCreate" :options="SelectOptions" :show-bread="false" :showSelect="true"
             :btnText="getMessageByCode('add') ?? 'Add'" clear-id="clientId" routeName="client"
             @selection-changed="getClients"/>
    <div>

      <div class="d-flex justify-content-end mb-1">
        <div class="col-12 col-md-2">
          <div class="d-flex justify-content-end">
            <el-button v-if="selectedIds.length > 1"
                       class="bg-danger text-white fw-normal me-2 border-0"
                       @click="deleteClientDialog = !deleteClientDialog">
              <i class="bi bi-trash me-2"></i>
              {{ selectedIds.length }} {{ getMessageByCode('selected') ?? 'selected' }}
            </el-button>
            <el-select v-model="filter.status" class="me-2" @change="getClients()">
              <el-option v-for="status in Status" :key="status.value"

                         :label="getMessageByCode(status.text.toLowerCase()) ?? status.text" :value="status.value">

              </el-option>
            </el-select>

          </div>
        </div>
        <div class="col-12 col-md-4 col-lg-3 mb-1  p-0 m-0 d-flex justify-content-end">
          <el-input v-model="filter.search" class="align-self-end" clearable
                    :placeholder="getMessageByCode('search') ?? 'Search here...'"
                    @clear="getClients"
                    @keyup="getClients">
            <template #append>
              <el-button @click="getClients">
                <template #icon>
                  <i class="bi bi-search"></i>
                </template>
              </el-button>
            </template>
          </el-input>
        </div>
      </div>
      <el-table :data="Clients" :default-sort="{ prop: 'name', order: 'ascending' }"
                :fit="true" :flexible="true" :selectable="(row) => row.status !== 'disabled'" border
                header-cell-class-name="tbl-header"
                stripe @selection-change="handleSelectionChange">
        <template #append>
          <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :total="filter.totalCount"
              :page="filter.currentPage"
              :page-size="filter.pageSize"
              :page-sizes="[10, 20, 30, 40]"
              layout=" prev, pager, next,sizes"
              class="bg-gray p-2"
              background
              size="small"
          >
          </el-pagination>
        </template>
        <el-table-column class="text-capitalize" :label="getMessageByCode('name') ?? 'Name'" prop="name" width="180"
                         sortable>
          <template #default="scope">
            <span class="router-text" @click="editClient(scope.row, 'edit')">{{
                scope.row.name
              }}</span>
          </template>
        </el-table-column>
        <el-table-column sortable :show-overflow-tooltip="true" class="text-capitalize" :label="getMessageByCode('mobile') ?? 'Mobile'" prop="mobile"
                         width="150"/>
        <el-table-column sortable :show-overflow-tooltip="true" class="text-capitalize" :label="getMessageByCode('address') ?? 'Address'" prop="address1"
                         min-width="200"/>
        <el-table-column sortable :show-overflow-tooltip="true" class="text-capitalize" :label="getMessageByCode('market') ?? 'Market'" prop="market"
                         width="200"/>
        <el-table-column sortable :show-overflow-tooltip="true" class="text-capitalize" :label="getMessageByCode('postcode') ?? 'Postcode'" prop="postCode"
                         width="120"/>
        <el-table-column sortable :show-overflow-tooltip="true" class="text-capitalize" :label="getMessageByCode('city') ?? 'City'" prop="city"
                         width="150"/>
        <el-table-column sortable :show-overflow-tooltip="true" class="text-capitalize" :label="getMessageByCode('status') ?? 'Status'" prop="isActive"
                         width="100">
          <template #default="scope">
            <div class="text-center">
              <el-tag size="small" type="success" v-if="scope.row.isActive">Active</el-tag>
              <el-tag size="small" type="danger" v-else>InActive</el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" fixed="right" class="text-capitalize"
                         :label="getMessageByCode('actions') ?? 'Actions'" width="130">
          <template #default="scope">
            <el-space alignment="center" spacer="|">
              <i
                  class="mgc_edit_line text-primary action-btn"
                  @click="editClient(scope.row, 'edit')"
              ></i>
              <el-tooltip
                  class="box-item"
                  effect="dark"
                  content="Deactivate"
                  placement="bottom"
                  v-if="scope.row.isActive"
              >
                <i
                    class="mgc_forbid_circle_line text-warning action-btn"
                    @click="editClient(scope.row, 'activate')"
                ></i>
              </el-tooltip>
              <el-tooltip
                  class="box-item"
                  effect="dark"
                  content="Activate"
                  placement="bottom"
                  v-else
              >
                <i
                    class="mgc_check_circle_line text-success action-btn"
                    @click="editClient(scope.row,'activate')"
                ></i>
              </el-tooltip>
              <i v-if="clientDel" @click="editClient(scope.row,'del')"
                 class="mgc_delete_2_line text-danger action-btn"
              ></i>
            </el-space>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!--    <DeleteClient :title="clientName" :selectedIds="selectedIds"-->
    <!--                  @deleteSuccess="getClients"></DeleteClient>-->


    <el-dialog
        v-model="deleteClientDialog"

        width="30%"
    >
      <template #header>
        <span>{{ getMessageByCode('delete') ?? 'Delete' }} {{ getMessageByCode('client') ?? 'Client' }}</span>
      </template>
      <span v-if="selectedIds.length === 0">{{ getMessageByCode('do_u_del') ?? 'Do you want to delete' }} {{
          client?.name
        }}</span>
      <span
          v-else>{{
          getMessageByCode('do_u_del') ?? 'Do you want to delete'
        }} {{ getMessageByCode('selected') ?? 'selected' }} {{ getMessageByCode('clients') ?? 'clients' }}</span>
      <template #footer>
      <span class="dialog-footer">
        <el-button text bg @click="deleteClientDialog = false">{{ getMessageByCode('cancel') ?? 'Cancel' }}</el-button>
        <el-button class="bg-danger text-white border-0" @click="deleteClientDialog = false; deleteClient()">
          {{ getMessageByCode('delete') ?? 'Delete' }}
        </el-button>
      </span>
      </template>
    </el-dialog>


    <el-dialog
        v-model="activateDialog"
        class="text-capitalize"
        :title="client.isActive ?  getMessageByCode('de_activate')??'Deactivate' : getMessageByCode('activate')??'Activate'"
        width="30%"
    >
      <span v-if="client.isActive">{{
          getMessageByCode('r_u_sure') ?? 'Are you sure ? You want to '
        }} {{ getMessageByCode('de_activate ') ?? 'deactivate' }} <span
            class="mfw-bold text-warning"> {{ client.name }}</span></span>
      <span v-else>{{
          getMessageByCode('r_u_sure') ?? 'Are you sure ? You want to '
        }} {{ getMessageByCode('activate') ?? 'activate' }} <span
            class="mfw-bold text-success"> {{ client.name }}</span></span>
      <template #footer>
      <span class="dialog-footer">
        <el-button text bg @click="activateDialog = false"
                   class="text-capitalize">{{ getMessageByCode('cancel') ?? 'Cancel' }}</el-button>
        <button v-if="client.isActive" class="btn btn-sm ms-1 btn-warning text-capitalize"
                @click="activateDialog = false; activateClient(client.id)">
             {{ getMessageByCode('de_activate') ?? 'Deactivate' }}
        </button><button v-else class="btn btn-sm ms-1 btn-success text-capitalize"
                         @click="activateDialog = false; activateClient(client.id)">
        {{ getMessageByCode('activate') ?? 'Activate' }}
        </button>
      </span>
      </template>
    </el-dialog>
    <!--    <el-dialog title="Update User" v-model="updateUserDialog" width="600">
          <el-form :model="User" hide-required-asterisk label-position="top">
            <div class="row">
              <div class="col-12 col-md-6" v-if="this.clientId == null">
                <el-form-item>
                  <template #label>
                    <span class="">Client</span>
                  </template>
                  <el-select :disabled="clientId ?? null" v-model="User.clientId" filterable class="w-100"
                             placeholder="Select"
                  >
                    <el-option
                        v-for="item in Clients"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                    />
                  </el-select>
                </el-form-item>
              </div>
              <div class="col-12 col-md-6">
                <el-form-item>
                  <template #label>
                    <span class="required">Full Name</span>
                  </template>
                  <el-input v-model="User.displayName"></el-input>
                </el-form-item>
              </div>
              <div class="col-12 col-md-6">
                <el-form-item>
                  <template #label>
                    <span class="required">Username</span>

                  </template>
                  <el-input v-model="User.userName"></el-input>
                </el-form-item>
              </div>
              <div class="col-12 col-md-6">
                <el-form-item>
                  <template #label>
                    <span class="required">Email</span>
                  </template>
                  <el-input v-model="User.email"></el-input>
                </el-form-item>
              </div>
              <div class="col-12 col-md-6">
                <el-form-item>
                  <template #label>
                    <span class="">Phone</span>
                  </template>
                  <el-input v-model="User.mobile"></el-input>
                </el-form-item>
              </div>
              <div class="col-12 col-md-6 d-flex justify-content-between">
                <el-form-item>
                  <template #label>
                    <span class="">Active</span>
                  </template>
                  <el-switch active-text="Yes" inactive-text="No" v-model="User.isActive"></el-switch>
                </el-form-item>

              </div>
              <div class="col-12 col-md-6 d-flex justify-content-between">
                <el-form-item>
                  <template #label>
                    <span class="">Email Notification</span>
                  </template>
                  <el-switch active-text="Yes" inactive-text="No" v-model="User.isEmailNotification"></el-switch>
                </el-form-item>
              </div>
            </div>
          </el-form>
          <template #footer>
            <div class="d-flex justify-content-end">
              <el-button text bg @click="addUserDialog = false" class="me-2">Cancel</el-button>
              <el-button v-if="User.id < 1" type="primary" @click="addUser()">Add User</el-button>
              <el-button v-else type="primary" @click="updateUser()">Update User</el-button>
            </div>
          </template>
        </el-dialog>-->


    <div v-if="loading" class="spinner-border"></div>
  </div>
</template>

<script>
import ToolBar from "@/components/Layout/ToolBar";
import FilterService from "@/components/Services/FilterService";
import clientService from "@/Services/ComponentsServices/clientService";
import store from "@/store";

import {ShowMessage} from "@/Utility/Utility";
import {checkPermission} from "@/Utility/CheckPermission";
import {getLanguage} from "@/Utility/getLanguage";

export default {
  name: "ClientsList",
  components: {ToolBar},
  data() {
    return {
      activateDialog: false,
      updateUserDialog: false,
      deleteClientDialog: false,
      clientCreate: false,
      clientDel: false,
      translations: [],
      User: {},
      edit: true,
      client: {
        isActive: false,
      },
      Clients: [],
      Status: [
        {text: "All", value: 2},
        {text: "Active", value: 1},
        {text: "InActive", value: 0},
      ],
      selectedIds: [],
      clientName: "",
      clientId: 0,
      loading: false,
      filter: {
        clientId: sessionStorage.getItem("clientId") ?? 0,
        providerId: 1,
        search: "",
        sort: "",
        pageSize: 10,
        currentPage: 1,
        totalCount: 0,
        status: 2,
      },
      SelectOptions: [{label: "All", value: 1}, {label: "Active", value: 2}, {label: "InActive", value: 3}],
    }
  },
  methods: {
    editClient(client, type) {
      this.client = {...client};
      const encodedId = btoa(this.client.id.toString());
      switch (type) {
        case 'edit':
          this.$store.state.pageId = this.client.id;
          this.$router.push({ path: '/client', query: { id: encodedId } });
          break;
        case 'activate':
          this.activateDialog = true;
          break;
        case 'del':
          this.deleteClientDialog = true;
          break;
      }
    },
    handleSelectionChange(selection) {
      this.selectedIds = selection.map(obj => obj.id);
    },
    handleSizeChange(newSize) {
      this.filter.pageSize = newSize;
      this.getClients();
    },
    handleCurrentChange(newPage) {
      this.filter.currentPage = newPage;
      this.getClients();
    },
    getData(client) {
      this.client = client;
      this.activateDialog = true;
    },
    async activateClient(id) {
      try {
        let response = null;
        switch (this.client.isActive) {
          case true:
            response = await clientService.deactivate(id);
            break;
          case false:
            response = await clientService.activate(id);
            break;
        }
        if (response.data === true) {
          if (this.client.isActive) {
            ShowMessage('success', this.client.name + " deactivated successfully")
          } else {
            ShowMessage('success', this.client.name + " activated successfully")
          }
        }
        await this.getClients();
      } catch (e) {
        console.log(e.error)
      }
    },
    async getClients() {
      try {
        this.loading = true;
        let response = await clientService.all(this.filter);
        this.Clients = response.data.items ?? [];

        this.filter.totalCount = response.data.totalCount ?? 0;
        this.filter.currentPage = response.data.currentPage ?? 1;
        this.filter.pageSize = response.data.pageSize;
        this.loading = false;
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
    async deleteClient() {
      try {
        this.loading = true;
        let response = await clientService.deleteClient(this.client.id);
        if (response.status === 200) {
          ShowMessage("success", this.client?.name + " Deleted Successfully");
        }
        this.loading = false;
        await this.getClients();
      } catch (e) {
        this.loading = false;
        ShowMessage("error", e.response.data.message);
      }
      this.selectedIds = [];
    },
    getMessageByCode(code) {
      // Find the object with the matching code
      if (this.translations == null) {
        this.translations = getLanguage();
        return;
      }
      const matchingObject = this.translations.find(obj => obj.code === code);
      return matchingObject ? matchingObject.message : null;
    }
  },
  computed: {
    dialog() {
      return store.state.deleteDialog
    }
  },
  watch: {
    dialog(newValue) {
      if (newValue === false) {
        this.selectedIds = [];
      }
    },
  },
  created() {
    this.translations = getLanguage();
    this.clientCreate = checkPermission("MD_CLIENT_CREATE");
    this.clientDel = checkPermission("MD_CLIENT_DEL");
    FilterService.resetFilter();
    this.getClients();
  }
}
</script>

<style scoped>

</style>