<template>
  <div class="container-fluid">
    <ToolBar :showBread="false" :showSelect="false" :ShowAddBtn="false">
      <template v-slot:select>
        <el-button type="primary" class="ms-2" @click="resetImage();addDialog = true">
          {{ getMessageByCode('add') ?? 'Add' }}
        </el-button>
      </template>
    </ToolBar>
    <div class="row">
      <div class="col-12 col-md-6 mb-1 ms-auto  d-flex justify-content-end">
        <el-select v-model="filter.category" @change="getImages()" class="me-2">
          <el-option v-for="cat in searchCategories" :key="cat.value" :label="cat.text" :value="cat.value"></el-option>
        </el-select>
        <el-input v-model="filter.search" class="align-self-end" clearable
                  :placeholder="getMessageByCode('search') ?? 'Search here...'"
                  @clear="getImages"
                  @keyup="getImages">
          <template #append>
            <el-button @click="getImages">
              <template #icon>
                <i class="bi bi-search"></i>
              </template>
            </el-button>
          </template>
        </el-input>

      </div>
    </div>
    <div class="mt-2">
      <el-table :data="images" border header-cell-class-name="tbl-header">
        <template #append>
          <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :total="filter.totalCount"
              :page="filter.currentPage"
              :page-size="filter.pageSize"
              :page-sizes="[10, 20, 30, 40]"
              layout=" prev, pager, next,sizes"
              class="bg-gray p-2"
              background
              size="small"
          >
          </el-pagination>
        </template>
        <el-table-column sortable :show-overflow-tooltip="true" :label="getMessageByCode('title') ?? 'Title'"
                         prop="title"
                         min-width="150"/>
        <el-table-column sortable :show-overflow-tooltip="true"
                         :label="getMessageByCode('description') ?? 'Description'"
                         prop="description" min-width="200"/>
        <el-table-column sortable :show-overflow-tooltip="true" :label="getMessageByCode('category') ?? 'Category'"
                         prop="category" min-width="200"/>

        <!--        <el-table-column sortable :show-overflow-tooltip="true" :label="getMessageByCode('image') ?? 'Image'" prop="image"-->
        <!--                         width="120">-->
        <!--          <template #default="scope">-->
        <!--            <div class="text-center">-->
        <!--              <el-tooltip :content="getMessageByCode('preview') ?? 'Preview'">-->
        <!--                <n-image :src="scope.row.imageData" width="40"></n-image>-->
        <!--              </el-tooltip>-->
        <!--            </div>-->
        <!--          </template>-->
        <!--        </el-table-column>-->
        <el-table-column sortable :show-overflow-tooltip="true" :label="getMessageByCode('sortOrder') ?? 'Sort Order'"
                         prop="sortOrder" width="120">
          <template #default="scope">
            <div class="text-end">
              <span>{{ scope.row.sortOrder }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column :label="getMessageByCode('action') ?? 'Action'" prop="sortOrder" width="100">
          <template #default="scope">
            <el-space>
              <el-tooltip :content="getMessageByCode('preview') ?? 'Preview'">
                <i class="mgc_pic_2_line action-btn text-warning" @click="editImage(scope.row,'preview')"></i>
              </el-tooltip>
              <i class="mgc_pen_line action-btn text-primary" @click="editImage(scope.row,'edit')"></i>
              <i class="mgc_delete_2_line action-btn text-danger" @click="editImage(scope.row,'del')"></i>

            </el-space>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!--    Dialogs-->
    <el-dialog v-model="addDialog" :align-center="true"
               :title="image.id < 1 ? getMessageByCode('addImage') ?? 'Add Image' : getMessageByCode('updateImage') ?? 'Update Image'"
               style="width: auto; max-width: 500px">
      <el-form class="row" require-asterisk-position="right" label-position="top">
        <el-form-item :label="getMessageByCode('title') ?? 'Title'">
          <el-input v-model="image.title"></el-input>
        </el-form-item>
        <el-form-item :label="getMessageByCode('category') ?? 'Category'">
          <el-select v-model="image.category">
            <el-option v-for="cat in categories" :key="cat.value" :label="cat.text" :value="cat.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="getMessageByCode('description') ?? 'Description'">
          <el-input type="textarea" :rows="3" v-model="image.description"></el-input>
        </el-form-item>
        <el-form-item class="row">
          <div class="col-6">
            <el-form-item :label="getMessageByCode('sortOrder') ?? 'Sort Order'">
              <el-input type="number" v-model="image.sortOrder"></el-input>
            </el-form-item>
          </div>
          <div class="col-6 d-flex justify-content-end">
            <el-form-item :label="getMessageByCode('isActive') ?? 'Is Active'">
              <el-switch v-model="image.isActive"></el-switch>
            </el-form-item>
          </div>
        </el-form-item>
        <el-form-item :label="getMessageByCode('image') ?? 'Image'">
          <div class="d-flex justify-content-between w-100">
            <el-upload id="upload-photo" accept="image/*" :key="fileInputKey" :auto-upload="false"
                       @input="onFileChanged" :show-file-list="false"
                       class="mb-0">
              <template #trigger>
                <el-button bg text class="py-5 upload-btn">{{
                    getMessageByCode('selectimage') ?? 'Select Image'
                  }}
                </el-button>
              </template>
            </el-upload>
            <div class="d-flex flex-column">
              <el-image v-if="image.imageData" fit="fill" style="width: 125px; height: 100px"
                        :src="image.imageData"></el-image>
              <span v-if="image.width > 0 && image.height > 0" class="text-small">Dimensions: {{
                  image.width
                }} * {{ image.height }}</span>
            </div>
          </div>

        </el-form-item>
      </el-form>
      <template #footer>
        <div class="d-flex justify-content-end">
          <el-button bg text @click="addDialog = false">{{ getMessageByCode('cancel') ?? 'Cancel' }}</el-button>
          <el-button v-if="image.id < 1" type="primary" class="ms-2" @click="addDialog = false; addImage()">
            {{ getMessageByCode('add') ?? 'Add' }}
          </el-button>
          <el-button v-else type="primary" class="ms-2" @click="addDialog = false; updateImage()">
            {{ getMessageByCode('update') ?? 'Update' }}
          </el-button>
        </div>
      </template>
    </el-dialog>
    <el-dialog v-model="deleteDialog" :align-center="true"
               :title="getMessageByCode('delete') ?? 'Delete Image'"
               style="width: auto; max-width: 500px">
      <template #default>
         <span>{{
             getMessageByCode('r_u_sure') ?? 'Are you sure ? You want to '
           }} {{ getMessageByCode('delete') ?? 'Delete' }} <span class="mfw-bold text-danger"> {{
               image.title
             }}</span></span>
      </template>
      <template #footer>
        <div class="d-flex justify-content-end">
          <el-button bg text @click="deleteDialog = false">{{ getMessageByCode('cancel') ?? 'Cancel' }}</el-button>
          <el-button type="danger" class="ms-2" @click="deleteDialog = false; deleteImage(image.id)">
            {{ getMessageByCode('delete') ?? 'Delete' }}
          </el-button>
        </div>
      </template>
    </el-dialog>

    <el-dialog v-model="previewDialog" :title="getMessageByCode('preview') ?? 'Preview'"
               style="width: auto; max-width: 500px">
      <el-image :src="getBase64ImageString(imageData)"></el-image>
    </el-dialog>

    <n-spin v-if="loading" size="small" stroke="#000" :stroke-width="15" description="Loading"
            class="cstm-spin"></n-spin>
  </div>
</template>
<script>
import ToolBar from "@/components/Layout/ToolBar.vue";
import {getMessageByCode} from "@/Utility/getMessagebyCode";
import {NSpin} from "naive-ui";
import loginCoreService from "@/Services/ComponentsServices/loginCoreService";
import {ShowMessage} from "@/Utility/Utility";

export default {
  name: "LoginImagesList",

  components: {NSpin, ToolBar},
  data() {
    return {
      loading: false,
      addDialog: false,
      previewDialog: false,
      deleteDialog: false,
      imageData: "",
      fileInputKey: new Date(),
      images: [],
      image: {
        "id": 0,
        "title": "",
        "description": "",
        "category": "[LoginBackground]",
        "imageData": "",
        "sortOrder": 0,
        "isActive": true,
        "width": 0,
        "height": 0
      },
      categories: [
        {text: '[LoginSide]', value: '[LoginSide]'},
        {text: '[LoginBackground]', value: '[LoginBackground]'},
      ],
      searchCategories: [
        {text: 'All', value: 'All'},
        {text: 'LoginSide', value: '[LoginSide]'},
        {text: 'LoginBackground', value: '[LoginBackground]'},
      ],
      filter: {
        search: "",
        category: "All",
        sort: "",
        pageSize: 10,
        currentPage: 1,
        totalCount: 0,
        status: 1
      }
    }
  },
  methods: {
    getMessageByCode,
    editImage(image, type) {
      this.image = JSON.parse(JSON.stringify(image));
      switch (type) {
        case 'preview':
          this.getImage();
          break;
        case 'edit':
          this.getImageById(this.image.id);
          break;
        case 'del':
          this.deleteDialog = true;
          break;
      }
    },
    getBase64ImageString(base64String) {
      // Define the Base64 prefix for a JPEG image
      const base64Prefix = "data:image/jpeg;base64,";

      // Check if the string already starts with the Base64 prefix
      if (base64String.startsWith(base64Prefix)) {
        return base64String; // Return as is
      }

      // If not, assume it's incomplete and prepend the prefix
      return base64Prefix + base64String;
    },
    handleSizeChange(newSize) {
      this.filter.pageSize = newSize;
      this.getImages();
    },
    handleCurrentChange(newPage) {
      this.filter.currentPage = newPage;
      this.getImages();
    },
    async getImages() {
      this.loading = true;
      try {
        let response = await loginCoreService.getImages(this.filter);
        this.images = response?.data?.items ?? [];
        this.filter.currentPage = response?.data?.currentPage ?? 1;
        this.filter.pageSize = response?.data?.pageSize ?? 10;
        this.filter.totalCount = response?.data?.totalCount ?? 0;
      } catch (e) {
        ShowMessage("error", e?.response?.data.msg ?? "Fetching data failed");
      }
      this.loading = false;
    },
    async addImage() {
      this.loading = true;
      try {
        let response = await loginCoreService.addImage(this.image);
        if (response.status === 200) {
          ShowMessage("success", "Image Added");
          await this.getImages();
        }
      } catch (e) {
        ShowMessage("error", e?.response?.data.msg ?? "Image adding failed ");
      }
      this.loading = false;
    },
    async getImage() {
      this.loading = true;
      try {
        let response = await loginCoreService.getImage(this.image.id);
        if (response?.data !== "" && response?.status === 200) {
          this.imageData = response?.data;
          this.previewDialog = true;
        }
      } catch (e) {
        ShowMessage("error", e?.response?.data.msg ?? "Image adding failed ");
      }
      this.loading = false;
    },
    async getImageById(id) {
      this.loading = true;
      try {
        let response = await loginCoreService.getImageById(id);
        if (response?.data !== "" && response?.status === 200) {
          this.image = response?.data;
          this.addDialog = true;
        }
      } catch (e) {
        ShowMessage("error", e?.response?.data.msg ?? "Fetching Image failed ");
      }
      this.loading = false;
    },
    async updateImage() {
      this.loading = true;
      try {
        let response = await loginCoreService.updateImage(this.image);
        if (response.status === 200) {
          ShowMessage("success", "Image Updated");
          await this.getImages();
        }
      } catch (e) {
        ShowMessage("error", e?.response?.data.msg ?? "Image update failed ");
      }
      this.loading = false;
    },
    async deleteImage(id) {
      this.loading = true;
      try {
        let response = await loginCoreService.deleteImage(id);
        if (response.status === 200) {
          ShowMessage("success", "Image Deleted");
          await this.getImages();
        }
      } catch (e) {
        ShowMessage("error", e?.response?.data.msg ?? "Image delete failed ");
      }
      this.loading = false;
    },
    onFileChanged(event) {
      const file = event.target.files[0];
      this.fileName = file.name;
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          this.image.imageData = reader.result;
          const img = new Image();
          img.onload = () => {
            this.image.width = img.width; // Store image width
            this.image.height = img.height; // Store image height
          };
          img.src = this.image.imageData;
        };
        reader.readAsDataURL(file);
      }
    },
    resetImage() {
      this.image = {
        "id": 0,
        "title": "",
        "description": "",
        "category": "[LoginBackground]",
        "imageData": "",
        "sortOrder": 0,
        "isActive": true,
        "width": 0,
        "height": 0
      };
    }
  },
  created() {
    this.getImages();
  }
}
</script>