<template>
  <div class="container-fluid">
    <ToolBar :show-bread="false" :options="SelectOptions" clear-id="propertyId" @selection-changed="getProperties"
             :showSelect="true"
             :ShowAddBtn="propertyCreate" routeName="property" :btnText="getMessageByCode('add')?? 'Add'">
      <template v-slot:select>
        <el-select v-if="clientId == null || Number(clientId) === 0" class="w-100" filterable @change="ChangeClient"
                   v-model="filter.clientId">
          <el-option v-for="item in Clients"
                     :key="item.id"
                     :label="item.name"
                     :value="item.id">
            <template #default>
              <div class="row">
                <span class="col-7">{{ item.name}}</span>
                <span class="text-small col-5">{{ item.market }}</span>
              </div>
            </template>
          </el-option>
        </el-select>
      </template>
    </ToolBar>
    <div class="container-fluid p-0">
      <div class="row p-0">
        <div class="col-12 col-md-8">
          <div class="d-flex justify-content-end">
            <el-button v-if="selectedIds.length > 1"
                       class="bg-danger text-white fw-normal me-2 border-0"
                       @click="deletePropertyDialog = !deletePropertyDialog">
              <i class="bi bi-trash me-2"></i>
              {{ selectedIds.length }} {{ getMessageByCode('selected') ?? 'selected' }}
            </el-button>
          </div>
        </div>
        <div class="col-12 col-md-4 col-lg-4 mb-2 ms-auto  d-flex justify-content-end">
          <el-input class="align-self-end" placeholder="Search here..."
                    @clear="getProperties" v-model="filter.search"
                    @keyup="getProperties"
                    clearable>
            <template #append>
              <el-button @click="getProperties">
                <template #icon>
                  <i class="bi bi-search"></i>
                </template>
              </el-button>
            </template>
          </el-input>
        </div>
      </div>
      <el-table :data="Properties" :default-sort="{ prop: 'titleNo', order: 'ascending' }"
                :fit="true" :flexible="true" :selectable="(row) => row.status !== 'disabled'" border
                header-cell-class-name="tbl-header"
                stripe @selection-change="handleSelectionChange">
        <template #append>
          <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :total="filter.totalCount"
              :page="filter.currentPage"
              :page-size="filter.pageSize"
              :page-sizes="[10, 20, 30, 40]"
              layout=" prev, pager, next,sizes"
              class="bg-gray p-2"
              background
              size="small"
          >
          </el-pagination>
        </template>
        <el-table-column  min-width="150" :label="getMessageByCode('ref')??'Reference'" prop="reference" sortable/>
        <el-table-column  min-width="150" :label="getMessageByCode('title')??'Title'" prop="titleNo" sortable/>
        <el-table-column sortable min-width="250" :label="getMessageByCode('address')??'Address'" prop="address1">
          <template #default="scope">
            <span class="router-text" @click="editProperty(scope.row,'edit')">{{
                scope.row.address1
              }}</span>
          </template>
        </el-table-column>
        <el-table-column sortable v-if="filter.clientId === 0" min-width="140" :label="getMessageByCode('client')??'Client'"
                         prop="clientName"/>
        <el-table-column sortable min-width="130" :label="getMessageByCode('city')??'City'" prop="city"/>
        <el-table-column sortable min-width="100" :label="getMessageByCode('postcode')??'Postcode'" prop="postCode"/>
        <el-table-column sortable :label="getMessageByCode('rooms')??'Rooms'" width="100" prop="numberOfRooms">
          <template #default="scope">
            <div class="text-end">
              <span>{{ scope.row.numberOfRooms }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" fixed="right"  :label="getMessageByCode('actions')??'Actions'" width="120">
          <template #default="scope">
            <el-space alignment="center" spacer="|" v-if="propertyDel">
              <i
                  class="mgc_edit_line text-primary action-btn"
                  @click="editProperty(scope.row,'edit')"
              ></i>
              <i @click="editProperty(scope.row,'delete')"
                 class="mgc_delete_2_line text-danger action-btn"
              ></i>
            </el-space>
            <i v-else
               class="mgc_edit_line text-primary action-btn"
               @click="editProperty(scope.row,'edit')"
            ></i>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div v-if="loading" class="spinner-border d-loader"></div>
    <el-dialog
        v-model="deletePropertyDialog"
        :title="getMessageByCode('delete') ?? 'Delete' + getMessageByCode('prop') ?? 'Property'"
        width="30%"
    >
      <template #header>
        <span>{{ getMessageByCode('delete') ?? 'Delete' }}</span>
        <span> {{ getMessageByCode('prop') ?? 'Property' }}</span>
      </template>
      <span>{{ getMessageByCode('do_u_del') ?? 'Do you want to delete' }}</span> <span
        class="text-danger">{{ property?.address1 }}</span>

      <template #footer>
      <span class="dialog-footer">
        <el-button text bg @click="deletePropertyDialog = false">{{
            getMessageByCode('cancel') ?? 'Cancel'
          }}</el-button>
        <el-button class="bg-danger text-white border-0" @click="deletePropertyDialog = false, deleteProperty()">
          {{ getMessageByCode('delete') ?? 'Delete' }}
        </el-button>
      </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import ToolBar from "@/components/Layout/ToolBar";
import PropertyService from "@/Services/ComponentsServices/PropertyService";
import store from "@/store";
import ClientService from "@/Services/ComponentsServices/clientService";
import {checkPermission} from "@/Utility/CheckPermission";
import {getLanguage} from "@/Utility/getLanguage";
import propertyService from "@/Services/ComponentsServices/PropertyService";
import {ShowMessage} from "@/Utility/Utility";

export default {
  name: "PropertiesList",
  emits: ['click', 'keyup', 'clear'],
  components: {ToolBar},
  data() {
    return {
      clientId: sessionStorage.getItem("clientId"),
      deletePropertyDialog: false,
      propertyCreate: false,
      propertyDel: false,
      edit: true,
      property: {},
      Properties: [],
      translations: [],
      propertyName: "",
      selectedIds: [],
      Clients: [{
        id: 0,
        name: this.getMessageByCode('all') ?? 'All'
      }],
      loading: false,
      SelectOptions: [{label: "All", value: 1}, {label: "Active", value: 2}, {label: "InActive", value: 3}],
      filter: {
        clientId: sessionStorage.getItem("clientId") ?? 0,
        providerId: 1,
        search: "",
        sort: "",
        pageSize: 10,
        currentPage: 1,
        totalCount: 0,
      },
    }
  },
  methods: {
    editProperty(property, type) {
      this.property = {...property};
      const encodedId = btoa(this.property.id.toString());
      switch (type) {
        case 'edit':
          store.state.pageId = this.property.id;
          this.$router.push({ path: '/property', query: { id: encodedId } });

          break;
        case 'delete':
          this.deletePropertyDialog = true;
          break;
      }
    },
    ChangeClient() {
      this.getProperties();
    },
    handleSelectionChange(selection) {
      this.selectedIds = selection.map(obj => obj.id);
    },

    deletePropertys(property) {
      this.property = {...property};
      this.deletePropertyDialog = true
      /*this.propertyName = property.address1;
      this.selectedIds.push(property.id)
      store.state.deleteDialog = true;*/
    },
    handleSizeChange(newSize) {
      this.filter.pageSize = newSize;
      this.getProperties();
    }, handleCurrentChange(newPage) {
      this.filter.currentPage = newPage;
      this.getProperties();
    },
    async deleteProperty() {
      this.loading = true;
      try {
        let response = await propertyService.deleteProperty(this.property.id);
        if (response.status === 200) {
          ShowMessage("success", this.property.titleNo + " Deleted Successfully");
          await this.getProperties();
        }

      } catch (e) {
        console.log(e)
        ShowMessage("error", e.response.data.message);
      }
      this.loading = false;
    },
    async getProperties() {
      try {
        this.loading = true;
        let response = await PropertyService.all(this.filter);
        this.Properties = response.data.items;
        this.filter.totalCount = response.data.totalCount ?? 0;
        this.filter.currentPage = response.data.currentPage ?? 1;
        this.filter.pageSize = response.data.pageSize;
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    },
    async getClients() {
      try {
        if (sessionStorage.getItem('clientId') == null || sessionStorage.getItem('clientId') == 0) {
          this.$store.state.loading = true;
          let response = await ClientService.getAll();
          this.Clients = this.Clients.concat(response.data.items);
          if (this.Clients.length > 0) {
            this.filter.clientId = this.Clients[0].id;
          }
        }
        this.getProperties();
      } catch (e) {
        console.log(e);
        this.$store.state.loading = false;
      }
    },
    getMessageByCode(code) {
      // Find the object with the matching code
      if (this.translations == null) {
        this.translations = getLanguage();
        return;
      }
      const matchingObject = this.translations.find(obj => obj.code === code);
      return matchingObject ? matchingObject.message : null;
    }
  },
  created() {
    this.translations = getLanguage();
    this.propertyDel = checkPermission("MD_PROP_DEL");
    this.propertyCreate = checkPermission("MD_PROP_CREATE");
    this.getClients();
  }
}
</script>

<style scoped>

</style>