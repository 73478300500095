<template>
  <div class="container-fluid">
    <ToolBar :show-select="false" :show-add-btn="false" :show-bread="false"></ToolBar>
    <div class="row justify-content-end p-0">
          <div class="col-12 col-md-4 d-flex justify-content-end align-items-end">
            <div class="d-flex flex-column flex-grow-1">
              <span class="text-small">{{ getMessageByCode('expiring') ?? 'Expiring' }}</span>
              <el-select v-model="filter.days" filterable @change="getDocuments()">
                <el-option v-for="opt in ExpiryOptions" :key="opt.optId" :label="opt.optTitle"
                           :value="opt.optId"></el-option>
              </el-select>
            </div>
            <div class="d-flex flex-column flex-grow-1 ms-2">
              <span class="text-small">{{ getMessageByCode('expiring') ?? 'Expiring' }}</span>
              <el-select v-model="filter.typeId" filterable @change="getDocuments()">
                <el-option v-for="opt in DocTypes" :key="opt.optId" :label="opt.optTitle"
                           :value="opt.optTitle"></el-option>
              </el-select>
            </div>
            <el-dropdown >
              <el-button type="primary" class="ms-2">
                {{getMessageByCode('export')??'Export'}}
                <el-icon class="el-icon--right"><i class="bi bi-chevron-down"></i></el-icon>
              </el-button>
              <template #dropdown>
                <el-dropdown-menu class="w-100">
                  <el-dropdown-item @click="ExportToExcel()">
                    <i class="bi bi-file-excel ms-1"></i>
                    <span>Excel</span>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>

      <el-table :data="Documents"
                :fit="true" :flexible="true" :selectable="(row) => row.status !== 'disabled'" border
                header-cell-class-name="tbl-header" class-name="mt-1"
                stripe>
        <template #append>
          <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :total="filter.totalCount"
              :page="filter.currentPage"
              :page-size="filter.pageSize"
              :page-sizes="[10, 20, 30, 40]"
              layout=" prev, pager, next,sizes"
              class="bg-gray p-2"
              background
              small
          >
          </el-pagination>
        </template>
        <el-table-column sortable  :label="getMessageByCode('document')??'Document'" prop="document"></el-table-column>
        <el-table-column sortable :label="getMessageByCode('expiry')??'Expiry'">
          <template #default="scope">
            <span>{{ scope.row.expiry.toString("dd/mm/yyyy") }}</span>
          </template>
        </el-table-column>
        <el-table-column sortable :label="getMessageByCode('days')??'Days'" prop="days"></el-table-column>
        <el-table-column sortable :label="getMessageByCode('address')??'Address'"  prop="address"></el-table-column>
        <el-table-column sortable :label="getMessageByCode('type')??'Type'" prop="docType"></el-table-column>
        <el-table-column :label="getMessageByCode('actions')??'Actions'"  align="center" fixed="right" width="80">
          <template #default="scope">
            <el-space alignment="center" spacer="|">
              <i
                  class="bi bi-info-circle-fill action-btn"
                  @click="viewProperty(scope.row.id)"
              ></i>

            </el-space>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div v-if="loading" class="spinner-border"></div>
  </div>
</template>
<script>
import ToolBar from "@/components/Layout/ToolBar.vue";
import optionService from "@/Services/ComponentsServices/optionService";
import reportServices from "@/Services/ComponentsServices/reportServices";
import {ShowMessage} from "@/Utility/Utility";
import {getLanguage} from "@/Utility/getLanguage";

export default {
  name: "DocumentsExpiry",
  components: {ToolBar},
  data() {
    return {
      loading: false,
      Documents: [],
      translations: [],
      DocTypes: [{optTitle: "All", optId: 0},],
      ExpiryOptions: [
        {optTitle: "All", optId: 0},
        {optTitle: "90 Days", optId: 90},
        {optTitle: "28 Days", optId: 28},
        {optTitle: "14 Days", optId: 14},
        {optTitle: "07 Days", optId: 7},
        {optTitle: "Expired", optId: -1},
      ],
      filter: {
        clientId: sessionStorage.getItem("clientId") ?? 0,
        providerId: 1,
        days: 0,
        typeId: "All",
        search: "",
        sort: "",
        pageSize: 10,
        currentPage: 1,
        totalCount: 0,
      },
    }
  },
  methods: {
    viewProperty(id) {
      this.$store.state.pageId = id;
      this.$router.push('/property');
    },
    handleSizeChange(newSize) {
      this.filter.pageSize = newSize;
      this.getDocuments();
    }, handleCurrentChange(newPage) {
      this.filter.currentPage = newPage;
      this.getDocuments();
    },
    async getDocuments() {
      try {
        this.loading = true;
        let response = await reportServices.getExpiredDocs(this.filter);
        this.Documents = response.data.items ?? [];
        this.filter.totalCount = response.data.totalCount ?? 0;
        this.filter.currentPage = response.data.currentPage ?? response.data.currentPage === 0 ? 1 : response.data.currentPage || 1;
        this.filter.pageSize = response.data.pageSizes ?? 10;

        this.loading = false;
      } catch (e) {
        this.loading = false;
        console.log(e);
      }
    },
    async getDefaultDocTypes() {
      try {
        let response = await optionService.getOptionsByHeaderId(this.filter.clientId, 12);
        this.DocTypes = this.DocTypes.concat(response.data ?? []);
        if (this.DocTypes.length > 0) {
          this.filter.search = this.DocTypes[0].optTitle;
          this.getDocuments();
        }
      } catch (e) {
        console.log(e)
      }
    },
    async ExportToExcel() {
      try {
        this.loading = true;
        // eslint-disable-next-line no-unused-vars
        let response = await reportServices.downloadExpiryDocs(this.filter);
        if (response.data == null) {
          ShowMessage("error", "Something went wrong!");
          return;
        }
        let sampleArr = this.base64ToArrayBuffer(response.data);
        this.saveByteArray("Docs Expiry Report.xlsx", sampleArr);
        this.loading = false;
        ShowMessage("success", "Report Downloaded");
      } catch {
        this.loading = false;
        ShowMessage("error", "Something went wrong!");
      }
    },
    base64ToArrayBuffer(base64) {
      let binaryString = window.atob(base64);
      let binaryLen = binaryString.length;
      let bytes = new Uint8Array(binaryLen);
      for (let i = 0; i < binaryLen; i++) {
        let ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
      }
      return bytes;
    },
    saveByteArray(reportName, byte) {
      let blob = new Blob([byte], {type: "application/xlsx"});
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = reportName;
      link.click();
    }
    ,
    getMessageByCode(code) {
      // Find the object with the matching code
      if (this.translations == null) {
        this.translations = getLanguage();
        return;
      }
      const matchingObject = this.translations.find(obj => obj.code === code);
      return matchingObject ? matchingObject.message : null;
    }
  },
  created() {
    this.translations = getLanguage();
    this.getDefaultDocTypes();

  }
}
</script>

<style scoped>

</style>