<template>
  <div class="container-fluid">
    <ToolBar :ShowAddBtn="false" :show-bread="false" :show-select="false"/>
    <div class="row">
      <div class="col-12 col-md-6 col-lg-4">
        <router-link to="contacts" class="text-decoration-none">
          <div class="card p-3 py-4 shadow-md ">
            <div class="d-flex justify-content-between align-items-center">
              <h3> {{ getMessageByCode('contacts') ?? 'Contacts' }}</h3>
              <div class="d-icon-div btn-bl-secondary">
                <i class="bi bi-people"></i>
              </div>
            </div>
            <div class="row">
              <span class="f-card-heading">{{ data?.contacts ?? 0 }}</span>
              <span>Contacts</span>
            </div>
          </div>
        </router-link>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <router-link to="customers" class="text-decoration-none">
          <div class="card p-3 py-4 shadow-md">
            <div class="d-flex justify-content-between align-items-center">
              <h3> {{ getMessageByCode('customers') ?? 'Customers' }}</h3>
              <div class="d-icon-div btn-bl-secondary">
                <i class="bi bi-person-add"></i>
              </div>
            </div>
            <div class="row">
              <span class="f-card-heading">{{ data?.customers ?? 0 }}</span>
              <span>Customers</span>
            </div>
          </div>
        </router-link>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <router-link to="app-users" class="text-decoration-none">
          <div class="card p-3 py-4 shadow-md">
            <div class="d-flex justify-content-between align-items-center">
              <h3> {{ getMessageByCode('appUsers') ?? 'App Users' }}</h3>
              <div class="d-icon-div btn-bl-secondary">
                <i class="bi bi-phone"></i>
              </div>
            </div>
            <div class="row">
              <span class="f-card-heading">{{data?.linkedAppUsers ?? 0}} / {{ data?.appUsers ?? 0 }}</span>
              <span>Linked Users / Free Users</span>
            </div>
          </div>
        </router-link>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12 col-md-6 col-lg-4">
          <div class="card p-3 py-4 shadow-md ">
            <div class="d-flex justify-content-between align-items-center">
              <h3> {{ getMessageByCode('transactions') ?? 'Transactions' }}</h3>
              <div class="d-icon-div btn-bl-secondary">
                <i class="bi bi-currency-pound"></i>
              </div>
            </div>
            <div class="row">
              <span class="f-card-heading">{{ data?.totalTransactions ?? 0 }}</span>
              <span>Total Transactions</span>
            </div>
          </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <div class="card p-3 py-4 shadow-md ">
          <div class="d-flex justify-content-between align-items-center">
            <h3> {{ getMessageByCode('accounts') ?? 'Accounts' }}</h3>
            <div class="d-icon-div btn-bl-secondary">
              <i class="bi bi-currency-pound"></i>
            </div>
          </div>
          <div class="row">
            <span class="f-card-heading">{{ data?.totalAccounts ?? 0 }}</span>
            <span>Total Accounts</span>
          </div>
        </div>
      </div>

    </div>

  </div>




  <n-spin v-if="loading" size="small" stroke="#000" :stroke-width="15" description="Loading"
          class="cstm-spin"></n-spin>
</template>
<script>
import {getMessageByCode} from "@/Utility/getMessagebyCode";
import ToolBar from "@/components/Layout/ToolBar.vue";
import dashboardService from "@/Services/ComponentsServices/dashboardService";
import customerService from "@/Services/ComponentsServices/customerService";
import {ShowMessage} from "@/Utility/Utility";
import {NSpin} from "naive-ui";

export default {
  name: "AccDashboard",
  components: {NSpin, ToolBar},
  data() {
    return {
      loading: false,
      unlinkDialog: false,
      data: {},
      customer: {},
      appUser: {},
      clientId: sessionStorage.getItem("clientId") ?? 0,
      filter: {
        clientId: sessionStorage.getItem("clientId") ?? 0,
        search: "",
        sort: "",
        pageSize: 10,
        currentPage: 1,
        totalCount: 0,
      },

    }
  },
  methods: {
    getMessageByCode,
    takeCustomer(customer, type) {
      this.customer = JSON.parse(JSON.stringify(customer));
      switch (type) {
        case 'edit':
          break;
        case 'unlink':
          this.unlinkDialog = true;
          break;
      }
    },
    takeUser(user, type) {
      this.appUser = JSON.parse(JSON.stringify(user));
      switch (type) {
        case 'edit':
          break;
        case 'unlink':
          this.unlinkDialog = true;
          break;
      }
    },
    getCustomerName(customer) {
      return customer?.firstName + " " + customer?.middleName ?? "" + " " + customer?.lastName ?? ""
    },
    formatAddress(customer) {
      const addressParts = [];
      if (customer.address1 && customer.address1.trim()) {
        addressParts.push(customer.address1.trim());
      }
      if (customer.address2 && customer.address2.trim()) {
        addressParts.push(customer.address2.trim());
      }
      if (customer.address3 && customer.address3.trim()) {
        addressParts.push(customer.address3.trim());
      }
      return addressParts.join(", ");
    },
    async unlink() {
      this.loading = true;
      this.unlinkDialog = false;
      try {
        let response = await customerService.unlink(this.customer.id);
        if (response?.data) {
          ShowMessage("success", "Customer Unlinked");
          await this.getData();
        }
      } catch (e) {
        ShowMessage("error", "Customer Unlink failed");
        console.log(e)
      }
      this.loading = false;
    },
    async addCustomer(user) {
      this.loading = true;
      try {
        let customer = {
          firstName: user.name,
          mobile: user.phone,
          email: user.email,
          is_active: true,
          is_deleted: false,
          appUserId: user.id
        }
        let response = await customerService.add(customer);
        if (response?.data !== null) {
          ShowMessage("success", "App User Linked");
          await this.getData();
        }
      } catch (e) {
        console.log(e);
        ShowMessage("error", e?.data?.message ?? "Add Customer Failed");
      }
      this.loading = false;
    },
    async getData() {
      try {
        this.loading = true;
        let response = await dashboardService.getAccData(this.clientId);
        this.data = response.data;
        console.log(this.data)
        this.loading = false;
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    }
  },
  created() {
    this.getData();
  }
}
</script>


<style scoped>
.bg-dashboard {
  background: #faf8fb !important;
  height: 100%;
}

.f-card-heading {
  font-size: 50px;
  font-weight: 500;
}

.cstm-span {
  line-height: normal !important;

}
</style>