<template>
  <div class="container-fluid">
    <ToolBar :showBread="false" :showSelect="false" :ShowAddBtn="true" route-name="staticpage"
             :btn-text="getMessageByCode('add') ?? 'Add'">
    <template v-slot:select>
      <el-select  v-if="clientId === null || clientId < 1" v-model="filter.clientId" filterable class="w-100"
                 placeholder="Select" @change="getPages()"
      >
        <el-option v-for="item in clients"
                   :key="item.id"
                   :label="item.name"
                   :value="item.id">
          <template #default>
            <div class="row">
              <span class="col-7">{{ item.name}}</span>
              <span class="text-small col-5">{{ item.market }}</span>
            </div>
          </template>
        </el-option>
      </el-select>
    </template>
    </ToolBar>
    <div class="row">
      <div class="d-flex justify-content-end mb-1">
        <div class="col-12 col-md-4 col-lg-4 mb-1 ms-auto p-0 m-0 d-flex justify-content-end">

          <el-input v-model="filter.search" class="align-self-end" clearable
                    :placeholder="getMessageByCode('search') ?? 'Search here...'"
                    @clear="getPages"
                    @keyup="getPages">
            <template #append>
              <el-button @click="getPages">
                <template #icon>
                  <i class="bi bi-search"></i>
                </template>
              </el-button>
            </template>
          </el-input>
        </div>
      </div>
    </div>
    <el-table :data="pages" border :default-sort="{ prop: 'key', order: 'ascending' }" header-cell-class-name="tbl-header">
      <template #append>
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :total="filter.totalCount"
            :page="filter.currentPage"
            :page-size="filter.pageSize"
            :page-sizes="[10, 20, 30, 40]"
            layout=" prev, pager, next,sizes"
            class="bg-gray p-2"
            background
            size="small"
        >
        </el-pagination>
      </template>
      <el-table-column sortable min-width="250" prop="pageCode" :label="getMessageByCode('pageCode') ?? 'Page Code'">
      </el-table-column>
      <el-table-column sortable min-width="250" prop="pageTitle" :label="getMessageByCode('pageTitle') ?? 'Page Title'">
      </el-table-column>
      <el-table-column sortable min-width="250" prop="sortOrder" :label="getMessageByCode('sortOrder') ?? 'Sort Order'">
      </el-table-column>
      <el-table-column sortable width="120" :label="getMessageByCode('isActive') ?? 'Is Active'">
        <template #default="scope">
          <div class="text-center">
            <el-tag type="success" effect="dark" size="small" v-if="scope.row.isActive">Yes</el-tag>
            <el-tag type="danger" effect="dark" size="small" v-else>No</el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" fixed="right" :label="getMessageByCode('actions')??'Actions'" width="130">
        <template #default="scope">
          <el-space alignment="center" spacer="|">
            <el-tooltip :content="getMessageByCode('preview') ?? 'Preveiw'">
              <i
                  class="mgc_eye_2_line text-primary action-btn"
                  @click="previewPage(scope.row.pageContent)"
              ></i>
            </el-tooltip>
            <i
                v-if="pageEdit"
                class="mgc_edit_line text-primary action-btn"
                @click="editPage(scope.row,'edit')"
            ></i>
            <i v-if="pageDelete"
               @click="editPage(scope.row,'del')"
               class="mgc_delete_2_line text-danger action-btn"
            ></i>
          </el-space>
        </template>
      </el-table-column>

    </el-table>


    <el-dialog v-model="deleteDialog" width="500" :title="getMessageByCode('delPage') ?? 'Delete Page'">
      <template #default>
        <span>Are you sure? You want to delete <span class="text-danger fw-semibold">{{ page.pageCode }}</span> page.</span>
      </template>
      <template #footer>
        <div class="d-flex justify-content-end">
          <el-button bg text @click="deleteDialog = false">{{ getMessageByCode('cancel') ?? 'Cancel' }}</el-button>
          <el-button type="danger" class="ms-2" @click="deleteDialog= false;deletePage()">{{
              getMessageByCode('delete') ?? 'Deltee'
            }}
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import ToolBar from "@/components/Layout/ToolBar.vue";
import {getLanguage} from "@/Utility/getLanguage";
import staticPagesService from "@/Services/ComponentsServices/staticPagesService";
import {checkPermission} from "@/Utility/CheckPermission";
import ClientService from "@/Services/ComponentsServices/clientService";

export default {
  name: "StaticPages",
  components: {ToolBar},
  data() {
    return {
      loading: false,
      deleteDialog: false,
      pageView: false,
      pageCreate: false,
      pageEdit: false,
      pageDelete: false,
      clientId: sessionStorage.getItem("clientId") ?? 0,
      translations: [],
      page: {},
      pages: [],
      clients: [
        {id: 0, name: "Admin"},
      ],
      filter: {
        clientId: sessionStorage.getItem("clientId") ?? 0,
        providerId: 1,
        search: "",
        sort: "",
        pageSize: 10,
        currentPage: 1,
        totalCount: 0,
      },
    }
  },
  methods: {
    editPage(page, type) {
      this.page = JSON.parse(JSON.stringify(page));
      switch (type) {
        case 'edit':
          this.$store.state.pageId = this.page.id;
          this.$router.push('staticpage')
          break;
        case 'del':
          this.deleteDialog = true;
          break;
      }
    },
    previewPage(htmlContent) {
      const newWindow = window.open('', '_blank');
      newWindow.document.write(htmlContent);
      newWindow.document.close();
    },
    handleSizeChange(newSize) {
      this.filter.pageSize = newSize;
      this.getPages();
    }, handleCurrentChange(newPage) {
      this.filter.currentPage = newPage;
      this.getPages();
    },
    async getPages() {
      this.loading = true;
      try {
        let response = await staticPagesService.getPages(this.filter);
        this.pages = response.data.items ?? [];
        this.filter.totalCount = response.data.totalCount ?? 0;
        this.filter.currentPage = response.data.currentPage ?? response.data.currentPage === 0 ? 1 : response.data.currentPage || 1;
        this.filter.pageSize = response.data.pageSize;
      } catch (e) {
        console.log(e)
      }
      this.loading = false;
    },
    async deletePage() {
      this.loading = true;
      try {
        let response = await staticPagesService.getPages(this.page.id);
        console.log(response);
      } catch (e) {
        console.log(e)
      }
      this.loading = false;
    },
    getMessageByCode(code) {
      // Find the object with the matching code
      if (this.translations == null) {
        this.translations = getLanguage();
        return;
      }
      const matchingObject = this.translations.find(obj => obj.code === code);
      return matchingObject ? matchingObject.message : null;
    },
    async getClients() {
      try {
        this.loading = true;
        if (this.clientId == null || this.clientId < 1) {
          let response = await ClientService.getAll();
          this.clients = this.clients.concat(response.data.items ?? []);
          if (this.clients.length > 0) {
              this.filter.clientId = this.clients[0].id;

          }

        }
        this.loading = false;
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
      await  this.getPages();
    }
  },
  created() {
    this.pageView = checkPermission("STATIC_PAGE_VIEW");
    this.pageCreate = checkPermission("STATIC_PAGE_CREATE");
    this.pageEdit = checkPermission("STATIC_PAGE_EDIT");
    this.pageDelete = checkPermission("STATIC_PAGE_DEL");
    this.getClients();
  }
}
</script>

<style scoped>

</style>