<!--suppress BadExpressionStatementJS -->
<template>
  <div class="container-fluid bg-gray">
    <!--    <ToolBar :showCancel="true" back-route="tenants" :show-select="false" :ShowAddBtn="true" :btn-text="mainBtnText"
                 @addbtn-clicked="AddOrUpdateTenant"/>-->
    <div class="container-fluid border-bottom mb-2 p-0">
      <div class="row align-items-center py-2">
        <div class="col-12 col-md-8">
          <BreadCrumb :view="edit" :showBread="true"/>
        </div>
        <div class="col-12 col-md-4 d-flex justify-content-end">
          <router-link v-if="edit" to="tenants">
            <el-button text bg class="btn btn-light">
              <i class="bi bi-chevron-left me-2"></i>
              {{ getMessageByCode('cancel') ?? 'Cancel' }}
            </el-button>
          </router-link>
          <el-button v-else @click="checkTenant()" text bg class="btn btn-light">
            <i class="bi bi-chevron-left me-2"></i>
            {{ getMessageByCode('cancel') ?? 'Cancel' }}
          </el-button>
          <el-button v-if="tenantEdit && edit" text bg class="border-0 mbtn-primary ms-2" @click="edit = !edit">
            {{ getMessageByCode('edit') ?? 'Edit' }}
          </el-button>
          <el-button @click="AddTenant" v-if="Tenant.id < 1 && tenantCreate"
                     class="border-0 mbtn-primary ms-2 text-capitalize">
            {{ getMessageByCode('add_tnnt') ?? 'Add Tenant' }}
          </el-button>

          <el-button @click="UpdateTenant" v-if="Tenant.id > 0 && tenantEdit && !edit" :disabled="edit"
                     class="border-0 mbtn-primary ms-2 text-capitalize">
            {{ getMessageByCode('upd_tnnt') ?? 'Update Tenant' }}
          </el-button>
        </div>
      </div>
    </div>
    <div class="container-fluid m-0 p-0">
      <el-form ref="tenantForm" :model="Tenant">
        <div class="row">
          <div class="col-12 col-md-4">
            <div class="card border-0 shadow-sm ">
              <div class="card-header card-header-bg2 d-flex justify-content-between align-items-center">
                <span
                    class="card-title mb-0 text-capitalize">{{ getMessageByCode('tnnt_info') ?? 'Tenant Info' }}</span>
                <span class="bi bi-card-checklist"></span>
              </div>
              <el-divider border-style="dashed" class="mt-0 mb-0"></el-divider>
              <div class="card-body">
                <div>
                  <label class="lblText required">{{ getMessageByCode('first_name') ?? 'First Name' }}</label>
                  <el-form-item prop="firstName" :rules="formRules.firstName" class="mb-2">
                    <el-input :disabled="edit" v-model="Tenant.firstName">
                    </el-input>
                  </el-form-item>
                </div>
                <div>
                  <label class="lblText required text-capitalize">{{ getMessageByCode('surname') ?? 'Surname' }}</label>
                  <el-form-item prop="lastName" :rules="formRules.surName" class="mb-2">
                    <el-input :disabled="edit" v-model="Tenant.lastName">
                    </el-input>
                  </el-form-item>
                </div>
                <div>
                  <label class="lblText  text-capitalize">{{ getMessageByCode('dob') ?? 'Date of Birth' }}</label>
                  <el-form-item class="mb-2">
                    <el-date-picker :disabled="edit" class="w-100" :format="dateFormat" v-model="Tenant.dob"/>
                  </el-form-item>
                </div>
                <div>
                  <label class="lblText  text-capitalize">{{ getMessageByCode('gender') ?? 'Gender' }}</label>
                  <el-form-item class="mb-2">
                    <el-select :disabled="edit" v-model="Tenant.gender" class="w-100">
                      <el-option
                          v-for="item in Genders"
                          :key="item.optId"
                          :label="item.optTitle"
                          :value="item.optId"
                      />
                    </el-select>
                  </el-form-item>
                </div>
                <div>
                  <label class="lblText  text-capitalize">{{ getMessageByCode('ethn') ?? 'Ethnicity' }}</label>
                  <el-form-item class="mb-2">
                    <el-select :disabled="edit" v-model="Tenant.ethnicity" class="w-100">
                      <el-option
                          v-for="item in Ethnicities"
                          :key="item.optId"
                          :label="item.optTitle"
                          :value="item.optId"
                      />
                    </el-select>
                  </el-form-item>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-4 mt-3 mt-md-0">
            <div class="card border-0 shadow-sm ">
              <div class="card-header card-header-bg2 d-flex justify-content-between align-items-center">
                <span class="card-title mb-0">{{ getMessageByCode('tnncy') ?? 'Tenancy' }}</span>
                <span class="bi bi-person-check"></span>
              </div>
              <el-divider border-style="dashed" class="mt-0 mb-0"></el-divider>
              <div class="card-body">
                <div>
                  <label
                      class="lblText text-capitalize">{{ getMessageByCode('cl_reg_num') ?? 'Claim Reference Number' }}</label>
                  <el-form-item class="mb-2">
                    <el-input :disabled="edit" v-model="Tenant.claimNumber">
                    </el-input>
                  </el-form-item>
                </div>
                <div>
                  <label class="lblText text-capitalize">{{ getMessageByCode('ni_num') ?? 'N.I Number' }}</label>
                  <el-form-item class="mb-2">
                    <el-input :disabled="edit" v-model="Tenant.niNumber">
                    </el-input>
                  </el-form-item>
                </div>
                <div v-if="clientId == null || clientId == 0">
                  <label class="lblText required text-capitalize">{{ getMessageByCode('client') ?? 'Client' }}</label>
                  <el-form-item class="mb-2">
                    <el-select :disabled="edit" filterable v-model="Tenant.clientId" class="w-100"
                               @change="changeClient()">
                      <el-option v-for="c in Clients" :key="c.id" :label="c.name" :value="c.id"
                                 style="font-weight: normal; color: #0a0a0a"
                      >

                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>
                <div>
                  <label
                      class="lblText required text-capitalize">{{ getMessageByCode('provider') ?? 'Provider' }}</label>
                  <el-form-item class="mb-2">
                    <el-select :disabled="edit" filterable v-model="Tenant.providerId" placeholder="Select" size="large"
                               class="w-100">
                      <el-option
                          v-for="item in Providers"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id"
                      />
                    </el-select>
                  </el-form-item>
                </div>

                <div>
                  <label class="lblText text-capitalize">{{ getMessageByCode('tnncy_from') ?? 'Tenancy From' }}</label>
                  <el-form-item class="mb-2">
                    <el-date-picker disabled :format="dateFormat" :editable="false" class="w-100"
                                    v-model="tenancyStartDate"/>
                  </el-form-item>
                </div>
                <div v-if="tenantInfo">
                  <label class="lblText text-capitalize">{{ getMessageByCode('tnncy_info') ?? 'Tenancy Info' }}</label>
                  <el-form-item class="mb-2">
                    <span @click="goToProperty()" style="cursor: pointer">{{ getMessageByCode('room') ?? 'Room' }} <span
                        class="text-primary mfw-bold">{{
                        tenantRoom
                      }}</span>, {{ getMessageByCode('property') ?? 'Property' }} <span
                        class="text-primary mfw-bold">{{ tenantProperty }}</span></span>


                    <!--                    <el-input disabled v-model="tenantInfo" ></el-input>-->

                  </el-form-item>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-4 mt-3 mt-md-0">
            <div class="card border-0 shadow-sm ">
              <div class="card-header card-header-bg2 d-flex justify-content-between align-items-center">
                <span class="card-title mb-0 text-capitalize">{{ getMessageByCode('key_dates') ?? 'Key Dates' }}</span>
                <span class="bi bi-calendar-date"></span>
              </div>
              <el-divider border-style="dashed" class="mt-0 mb-0"></el-divider>
              <div class="card-body">
                <div>
                  <label class="lblText text-capitalize">{{
                      getMessageByCode('pre_acc_date') ?? 'Pre Acceptance Inspection Date'
                    }}</label>
                  <el-form-item class="mb-2">
                    <el-date-picker :disabled="edit" :format="dateFormat" :editable="false" class="w-100"
                                    v-model="Tenant.datePreAcceptanceInspection"/>
                  </el-form-item>
                </div>
                <div>
                  <label
                      class="lblText text-capitalize">{{ getMessageByCode('sprt_pl_rev_date') ?? 'Support Plan Review Date' }}</label>
                  <el-form-item class="mb-2">
                    <el-date-picker :disabled="edit" :format="dateFormat" :editable="false" class="w-100"
                                    v-model="Tenant.dateSupportPlanReview"/>
                  </el-form-item>
                </div>
                <div>
                  <label
                      class="lblText text-capitalize">{{ getMessageByCode('sprt_pl_comp_date') ?? 'Support Plan Completed Date' }}</label>
                  <el-form-item class="mb-2">
                    <el-date-picker :disabled="edit" :format="dateFormat" :editable="false" class="w-100"
                                    v-model="Tenant.dateSupportPlanCompleted"/>
                  </el-form-item>
                </div>
                <div>
                  <label
                      class="lblText text-capitalize">{{ getMessageByCode('rsk_ass_rev_date') ?? 'Risk Assessment Review Date' }}</label>
                  <el-form-item class="mb-2">
                    <el-date-picker :disabled="edit" :format="dateFormat" :editable="false" class="w-100"
                                    v-model="Tenant.dateRiskAssessmentReview"/>
                  </el-form-item>
                </div>
                <div>
                  <label
                      class="lblText text-capitalize">{{ getMessageByCode('rsk_ass_comp_date') ?? 'Risk Assessment Completed Date' }}</label>
                  <el-form-item class="mb-2">
                    <el-date-picker :disabled="edit" :format="dateFormat" :editable="false" class="w-100"
                                    v-model="Tenant.dateRiskAssessmentCompleted"/>
                  </el-form-item>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-4 mt-3">
            <div class="card border-0 shadow-sm ">
              <div class="card-header card-header-bg2 d-flex justify-content-between align-items-center">
                <span class="card-title mb-0">{{ getMessageByCode('address') ?? 'Address' }}</span>
                <span class="bi bi-house"></span>
              </div>
              <el-divider border-style="dashed" class="mt-0 mb-0"></el-divider>
              <div class="card-body">
                <div>
                  <label class="lblText">{{ getMessageByCode('address ') ?? 'Address ' }} 1</label>
                  <el-form-item prop="address1" class="mb-2">
                    <el-input :disabled="edit" type="text" class="w-100" v-model="Tenant.address1"/>
                  </el-form-item>
                </div>
                <div>
                  <label class="lblText">{{ getMessageByCode('address ') ?? 'Address ' }} 2</label>
                  <el-form-item class="mb-2">
                    <el-input :disabled="edit" type="text" class="w-100" v-model="Tenant.address2"/>
                  </el-form-item>
                </div>
                <div>
                  <label class="lblText">{{ getMessageByCode('address ') ?? 'Address ' }} 3 </label>
                  <el-form-item class="mb-2">
                    <el-input :disabled="edit" type="text" class="w-100" v-model="Tenant.address3"/>
                  </el-form-item>
                </div>
                <div>
                  <label class="lblText">{{ getMessageByCode('postcode') ?? 'Postcode' }}</label>
                  <el-form-item class="mb-2">
                    <el-input :disabled="edit" type="text" class="w-100" v-model="Tenant.postCode"/>
                  </el-form-item>
                </div>
                <div>
                  <label class="lblText">{{ getMessageByCode('city') ?? 'City' }}</label>
                  <el-form-item class="mb-2">
                    <el-input :disabled="edit" type="text" class="w-100" v-model="Tenant.city"/>
                  </el-form-item>
                </div>
                <div>
                  <label class="lblText">{{ getMessageByCode('county') ?? 'County' }}</label>
                  <el-form-item class="mb-2">
                    <el-input v-model="Tenant.county"></el-input>
<!--                    <el-select :disabled="edit" v-model="Tenant.county" class="w-100">-->
<!--                      <el-option-->
<!--                          v-for="item in Countries"-->
<!--                          :key="item.optId"-->
<!--                          :label="item.optTitle"-->
<!--                          :value="item.optId"-->
<!--                      />-->
<!--                    </el-select>-->
                  </el-form-item>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-4 mt-3">
            <div class="card border-0 shadow-sm ">
              <div class="card-header card-header-bg2 d-flex justify-content-between align-items-center">
                <span class="card-title mb-0">{{ getMessageByCode('cont_info') ?? 'Contact Info' }}</span>
                <span class="bi bi-phone-vibrate"></span>
              </div>
              <el-divider border-style="dashed" class="mt-0 mb-0"></el-divider>
              <div class="card-body">
                <div>
                  <label class="lblText">{{ getMessageByCode('mobile') ?? 'Mobile' }}</label>
                  <el-form-item class="mb-2">
                    <el-input :disabled="edit" type="text" class="w-100" v-model="Tenant.mobile"/>
                  </el-form-item>
                </div>
                <div>
                  <label class="lblText">{{ getMessageByCode('email') ?? 'Email' }}</label>
                  <el-form-item class="mb-2">
                    <el-input :disabled="edit" type="text" class="w-100" v-model="Tenant.email"/>
                  </el-form-item>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-form>
    </div>
    <div v-if="loading" class="spinner-border"></div>
  </div>
</template>

<script>
import {ShowMessage} from "@/Utility/Utility";
import store from "@/store/index"
import tenantService from "@/Services/ComponentsServices/tenantService";
import clientService from "@/Services/ComponentsServices/clientService";
import ProviderService from "@/Services/ComponentsServices/ProviderService";
import optionService from "@/Services/ComponentsServices/optionService";
import BreadCrumb from "@/components/Layout/BreedCrumb.vue";
import {checkPermission} from "@/Utility/CheckPermission";
import configService from "@/Services/ComponentsServices/configService";
import {getLanguage} from "@/Utility/getLanguage";

export default {
  name: "AddTenant",
  components: {BreadCrumb},
  data() {
    return {
      loading: false,
      mainBtnText: "Add Tenant",
      tenantEdit: false,
      tenantCreate: false,
      edit: true,
      dateFormat: "DD/MM/YYYY",
      currency: "£",
      currencyPosition: "left",
      Ethnicities: [],
      clientId: sessionStorage.getItem("clientId"),
      Clients: [],
      Providers: [],
      Genders: [],
      Countries: [],
      translations: [],
      formRules: {
        firstName: [
          {required: true, message: 'Please enter first name', trigger: 'blur'},
          // You can add more rules for the name field if needed
        ],
        surName: [
          {required: true, message: 'Please enter surname', trigger: 'blur'},
          // You can add more rules for the name field if needed
        ],
        address1: [
          {required: true, message: 'Please enter your name', trigger: 'blur'},
          // You can add more rules for the name field if needed
        ],
        emailRule: [
          {required: true, message: 'Please enter the email', trigger: 'blur'},
          {type: 'email', message: 'Please enter a valid email', trigger: ['blur', 'change']},
          {validator: this.validateEmail, trigger: 'blur'}
        ],

      },
      propertyId: 0,
      tenantInfo: "",
      tenancyStartDate: null,
      tenantRoom: "",
      tenantProperty: null,
      Tenant: {
        "id": sessionStorage.getItem("tenantId"),
        "clientId": sessionStorage.getItem("clientId") ?? 0,
        "providerId": "",
        "firstName": "",
        "lastName": "",
        "gender": "",
        "ethnicity": "",
        "claimNumber": "",
        "referralMethod": 0,
        "localAuthority": 0,
        "dateSupportPlanCompleted": null,
        "dateRiskAssessmentCompleted": null,
        "datePreAcceptanceInspection": null,
        "dateRiskAssessmentReview": null,
        "dateSupportPlanReview": null,
        "niNumber": "",
        "dob": null,
        "email": "",
        "mobile": "",
        "address1": "",
        "address2": "",
        "address3": "",
        "city": "",
        "postCode": "",
        "county": "",
        "notes": ""
      }
    }
  },
  methods: {
    checkTenant() {
      if (this.Tenant.id < 1) {
        this.$router.push('tenants');
        return;
      }
      this.edit = !this.edit
    },
    goToProperty() {
      if (this.propertyId === 0) {
        ShowMessage("error", "No property present for the Id");
        return;
      }
      this.$store.state.pageId = this.propertyId;
      this.$router.push('/property')
    },
    changeClient() {
      this.getProviders(true);
      this.getGenders();
      this.getEthnicities();
      this.getCountries();
    },
    async AddTenant() {
      this.$refs.tenantForm.validate(async (valid) => {
        if (valid) {
          try {
            this.Tenant.gender = this.Tenant.gender || 0;
            this.Tenant.ethnicity = this.Tenant.ethnicity || 0;
            this.Tenant.providerId = this.Tenant.providerId || 0;
            this.Tenant.id = this.Tenant.id || 0;


            this.loading = false;
            if (this.Tenant.clientId < 1 || this.Tenant.providerId < 1) {
              ShowMessage("error", "Please select valid client or provider");
              return
            }
            let response = await tenantService.add(this.Tenant)
            if (response.status === 200 && response?.data !== null) {
              this.Tenant =response?.data
              ShowMessage('success', "Tenant added successfully");
            }
          } catch (e) {
            ShowMessage("error", e.response?.data?.message);
          }
        } else {
          ShowMessage("error", "Please enter the required fields!");
          this.loader = false;
        }
      });
    },
    async UpdateTenant() {
      this.$refs.tenantForm.validate(async (valid) => {
        if (valid) {
          try {
            this.loading = true;
            if (this.Tenant.clientId < 1 || this.Tenant.providerId < 1) {
              ShowMessage("error", "Please select valid client or provider");
              return
            }
            let response = await tenantService.update(this.Tenant)
            if (response.status === 200 && response?.data !== null) {
              this.Tenant = response?.data;
              ShowMessage('success', "Tenant added successfully");
            }
            this.loading = false;
          } catch (e) {
            console.log(e);
            ShowMessage("error", "Something went wrong!");
          }
        } else {
          ShowMessage("error", "Please enter the required fields!");
          this.loader = false;
        }
      });
      this.loading = false;
    },
    async getTenant() {
      try {
        this.$store.state.loading = true;
        // eslint-disable-next-line no-unused-vars
        //let response = await tenantService.getbyId(store.state.pageId);
        let response2 = await tenantService.getTenantById(store.state.pageId);
        this.Tenant = response2.data.tenant ?? {};
        this.Tenant.id = store.state.pageId;
        this.tenantInfo = response2.data.tenancyInfo ?? "";
        this.tenantRoom = response2.data.room ?? "";
        this.tenantProperty = response2.data.property ?? "";
        this.tenancyStartDate = response2.data.tenancyFrom ?? "";
        this.propertyId = response2.data.propertyId ?? 0;
        if (!this.Genders.some(obj => obj.id === this.Tenant.gender)) {
          this.Tenant.gender = ""
        }
        if (!this.Ethnicities.some(obj => obj.id === this.Tenant.ethnicity)) {
          this.Tenant.ethnicity = ""
        }
        this.loading = false;
      } catch (e) {
        console.log(e);
        this.$store.state.loading = false;
      }
    },
    async getClients() {
      try {
        this.$store.state.loading = true;
        if (this.clientId == null || this.clientId == 0) {
          let response = await clientService.getAll();
          this.Clients = response.data.items ?? [];
          if (this.Tenant.id < 1 && this.Clients.length > 0) {
            this.Tenant.clientId = this.Clients[0].id;
            this.getProviders();
          }
          this.loading = false;
        } else {
          this.Tenant.clientId = parseInt(this.clientId);
          this.getProviders();
        }
        this.getEthnicities();
        this.getGenders();
        this.getCountries();
      } catch (e) {
        console.log(e);
        this.$store.state.loading = false;
      }
    },
    async getProviders(changing) {
      try {
        let response = await ProviderService.getAll(this.Tenant.clientId)
        this.Providers = response.data.items ?? [];
        if (this.Tenant.providerId < 1 && this.Providers.length > 0) {
          this.Tenant.providerId = this.Providers[0].id
        }
        if (changing && this.Providers.length > 0) {
          this.Tenant.providerId = this.Providers[0].id
        }
        if (this.Providers.length < 1) {
          this.Tenant.providerId = 0
        }
      } catch (e) {
        console.log(e);
      }
    },
    async getEthnicities() {
      try {
        let response = await optionService.getOptionsByHeaderId(this.Tenant.clientId, 21)
        this.Ethnicities = response.data ?? [];
        if (this.Ethnicities.length > 0) {
          this.Tenant.ethnicity = this.Ethnicities[0].optId;
        } else {
          this.Tenant.ethnicity = "";
        }
      } catch (e) {
        console.log(e);
        this.$store.state.loading = false;
      }
    },
    async getGenders() {
      try {
        let response = await optionService.getOptionsByHeaderId(this.Tenant.clientId, 20)
        this.Genders = response.data ?? [];
        if (this.Genders.length > 0) {
          this.Tenant.gender = this.Genders[0].optId;
        } else {
          this.Tenant.gender = ""
        }
      } catch (e) {
        console.log(e);
        this.$store.state.loading = false;
      }
    },
    async getCountries() {
      try {
        this.loader = true;
        let response = await optionService.getOptionsByHeaderId(this.Tenant.clientId, 7)
        this.Countries = response.data ?? {};
        if (this.Countries.length > 0) {
          this.Tenant.county = this.Countries[0].optTitle;
        } else {
          this.Tenant.county = "";
        }
        this.loader = false;
      } catch (e) {
        console.log(e);
        ShowMessage("error", "Something went wrong!");
        this.loader = false;
      }
    },
    async getConfigs() {
      try {
        this.loading = true;
        let id = 0;
        if (this.clientId === null) {
          id = 0;
        } else {
          id = this.clientId;
        }
        let response = await configService.getByKey(id, "CurrencySymbol");
        this.currency = response.data.value ?? "£";
        let response2 = await configService.getByKey(id, "CurrencyPosition");
        this.currencyPosition = response2.data.value ?? "left";
        let response3 = await configService.getByKey(id, "DateFormat");
        this.currencyPosition = response3.data.value ?? "DD/MM/YYYY";
        this.loading = false;
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
    getMessageByCode(code) {
      // Find the object with the matching code
      if (this.translations == null) {
        this.translations = getLanguage();
        return;
      }
      const matchingObject = this.translations.find(obj => obj.code === code);
      return matchingObject ? matchingObject.message : null;
    }
  },
  created() {
    this.translations = getLanguage();
    this.tenantEdit = checkPermission("MD_TEN_EDIT");
    this.tenantCreate = checkPermission("MD_TEN_CREATE");
    this.getClients();

    const encodedId = this.$route.query.id;
    const decodedId = atob(encodedId);
    if (decodedId) {
      store.state.pageId = decodedId;
      this.Tenant.id = decodedId;
      this.mainBtnText = "Update Tenant";
      this.getTenant();
    } else {
      this.edit = false;
      this.mainBtnText = "Add Tenant"
    }
    this.getConfigs();
  }


}
</script>

<style scoped>

</style>