<template>
  <div class="container-fluid">
    <ToolBar :ShowAddBtn="clientCreate" :options="SelectOptions" :show-bread="false" :showSelect="true"
             :btnText="getMessageByCode('add') ?? 'Add'" clear-id="clientId"
             routeName="clientcontact"
             @selection-changed="getClientContacts"/>
    <div>
      <div class="d-flex justify-content-end mb-1">
        <div class="col-12 col-md-4">
          <div class="d-flex justify-content-end align-items-end">
            <div class="d-flex flex-column me-2 w-100">
              <span class="text-small">{{ getMessageByCode('market' ?? 'Market') }}</span>
              <el-select class="me-2" v-model="filter.marketId" @change="getClientContacts()">
                <el-option v-for="market in markets" :key="market.id" :label="market.title"
                           :value="market.id"></el-option>
              </el-select>
            </div>
            <div class="d-flex flex-column me-2 w-100">
              <span class="text-small">{{ getMessageByCode('status' ?? 'Status') }}</span>
              <el-select v-model="filter.status"  @change="getClientContacts()">
                <el-option v-for="status in Status" :key="status.value"
                           :label="getMessageByCode(status.text.toLowerCase()) ?? status.text" :value="status.value">
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-4 col-lg-3 p-0 m-0 d-flex justify-content-end">
          <el-input v-model="filter.search" class="align-self-end" clearable
                    :placeholder="getMessageByCode('search') ?? 'Search here...'" @clear="getClientContacts"
                    @keyup="getClientContacts">
            <template #append>
              <el-button @click="getClientContacts">
                <template #icon>
                  <i class="bi bi-search"></i>
                </template>
              </el-button>
            </template>
          </el-input>
        </div>
      </div>
      <el-table :data="clientContacts" :default-sort="{ prop: 'name', order: 'ascending' }" :fit="true" :flexible="true"
                :selectable="(row) => row.status !== 'disabled'" border header-cell-class-name="tbl-header" stripe
                @selection-change="handleSelectionChange">
        <template #append>
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                         :total="filter.totalCount" :page="filter.currentPage" :page-size="filter.pageSize"
                         :page-sizes="[10, 20, 30, 40]" layout=" prev, pager, next,sizes" class="bg-gray p-2" background
                         size="small">
          </el-pagination>
        </template>
        <el-table-column :show-overflow-tooltip="true" class="text-capitalize" :label="getMessageByCode('name') ?? 'Name'" prop="firmName" width="200"
                         sortable>
          <template #default="scope">
            <span class="router-text" @click="editClient(scope.row, 'edit')">{{
                scope.row.firmName
              }}</span>
          </template>
        </el-table-column>
        <el-table-column sortable :show-overflow-tooltip="true" class="text-capitalize" :label="getMessageByCode('email') ?? 'Email'" prop="email"
                      width="250"  />
        <el-table-column sortable :show-overflow-tooltip="true" class="text-capitalize" :label="getMessageByCode('telephone') ?? 'Telephone'" prop="telephone"
                        width="150"/>
        <el-table-column sortable :show-overflow-tooltip="true" class="text-capitalize" :label="getMessageByCode('postCode') ?? 'Postcode'" prop="postcode"
                         width="120"/>
        <el-table-column sortable :show-overflow-tooltip="true" class="text-capitalize" :label="getMessageByCode('address') ?? 'Address'" prop="address1"
                         min-width="200">
          <template #default="scope">
            <span>{{formatAddress(scope.row)}}</span>
          </template>
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true" align="center" fixed="right" class="text-capitalize"
                         :label="getMessageByCode('actions') ?? 'Actions'" width="130">
          <template #default="scope">
            <el-space alignment="center" spacer="|">
              <i class="mgc_edit_line text-primary action-btn" @click="editClient(scope.row, 'edit')"></i>
              <el-tooltip class="box-item" effect="dark" content="Deactivate" placement="bottom"
                          v-if="scope.row.isActive">
                <i class="mgc_forbid_circle_line text-warning action-btn"
                   @click="editClient(scope.row, 'activate')"></i>
              </el-tooltip>
              <el-tooltip class="box-item" effect="dark" content="Activate" placement="bottom" v-else>
                <i class="mgc_check_circle_line text-success action-btn" @click="editClient(scope.row, 'activate')"></i>
              </el-tooltip>
              <i v-if="clientDel" @click="editClient(scope.row, 'del')"
                 class="mgc_delete_2_line text-danger action-btn"></i>
            </el-space>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-dialog v-model="deleteClientDialog" width="30%">
      <template #header>
        <span>{{ getMessageByCode('delete') ?? 'Delete' }} {{ getMessageByCode('client') ?? 'Client' }}</span>
      </template>
      <span v-if="selectedIds.length === 0">{{ getMessageByCode('do_u_del') ?? 'Do you want to delete' }} {{
          clientContact?.name
        }}</span>
      <span v-else>{{
          getMessageByCode('do_u_del') ?? 'Do you want to delete'
        }} {{ getMessageByCode('selected') ?? 'selected' }} {{ getMessageByCode('clients') ?? 'clients' }}</span>
      <template #footer>
        <span class="dialog-footer">
          <el-button text bg @click="deleteClientDialog = false">{{
              getMessageByCode('cancel') ?? 'Cancel'
            }}</el-button>
          <el-button class="bg-danger text-white border-0" @click="deleteClientDialog = false; deleteClient()">
            {{ getMessageByCode('delete') ?? 'Delete' }}
          </el-button>
        </span>
      </template>
    </el-dialog>


    <el-dialog v-model="activateDialog" class="text-capitalize"
               :title="clientContact.isActive ? getMessageByCode('de_activate') ?? 'Deactivate' : getMessageByCode('activate') ?? 'Activate'"
               width="30%">
      <span v-if="clientContact.isActive">{{
          getMessageByCode('r_u_sure') ?? 'Are you sure ? You want to '
        }} {{ getMessageByCode('de_activate ') ?? 'deactivate' }} <span class="mfw-bold text-warning"> {{
            clientContact.name
          }}</span></span>
      <span v-else>{{
          getMessageByCode('r_u_sure') ?? 'Are you sure ? You want to '
        }} {{ getMessageByCode('activate') ?? 'activate' }} <span class="mfw-bold text-success"> {{
          clientContact.name
        }}</span></span>
      <template #footer>
        <span class="dialog-footer">
          <el-button text bg @click="activateDialog = false" class="text-capitalize">{{
              getMessageByCode('cancel') ??
              'Cancel'
            }}</el-button>
          <button v-if="clientContact.isActive" class="btn btn-sm ms-1 btn-warning text-capitalize"
                  @click="activateDialog = false; activateClient(clientContact.id)">
            {{ getMessageByCode('de_activate') ?? 'Deactivate' }}
          </button><button v-else class="btn btn-sm ms-1 btn-success text-capitalize"
                           @click="activateDialog = false; activateClient(clientContact.id)">
            {{ getMessageByCode('activate') ?? 'Activate' }}
          </button>
        </span>
      </template>
    </el-dialog>
    <div v-if="loading" class="spinner-border"></div>
  </div>
</template>

<script>
import ToolBar from "@/components/Layout/ToolBar";
import clientContactService from "@/Services/ComponentsServices/clientContactService";
import store from "@/store";

import {ShowMessage} from "@/Utility/Utility";
import {checkPermission} from "@/Utility/CheckPermission";
import {getLanguage} from "@/Utility/getLanguage";
import marketService from "@/Services/ComponentsServices/marketService";

export default {
  name: "ClientContacts",
  components: {ToolBar},
  data() {
    return {
      activateDialog: false,
      updateUserDialog: false,
      deleteClientDialog: false,
      clientCreate: false,
      clientDel: false,
      translations: [],
      User: {},
      edit: true,
      clientContact: {
      },
      clientContacts: [],
      Status: [
        {text: "All", value: 2},
        {text: "Active", value: 1},
        {text: "InActive", value: 0},
      ],
      markets: [
        {id: 0, title: "All"},
      ],
      clientName: "",
      clientId: 0,
      loading: false,
      filter: {
        clientId: sessionStorage.getItem("clientId") ?? 0,
        providerId: 1,
        marketId: 0,
        search: "",
        sort: "",
        pageSize: 10,
        currentPage: 1,
        totalCount: 0,
        status: 2,
      },
      SelectOptions: [{label: "All", value: 1}, {label: "Active", value: 2}, {label: "InActive", value: 3}],
    }
  },
  methods: {
    editClient(client, type) {
      this.clientContact = {...client};
      const encodedId = btoa(this.clientContact.id.toString());
      switch (type) {
        case 'edit':
          this.$store.state.pageId = this.clientContact.id;
          this.$router.push({ path: '/clientcontact', query: { id: encodedId } });
          break;
        case 'activate':
          this.activateDialog = true;
          break;
        case 'del':
          this.deleteClientDialog = true;
          break;
      }
    },
    formatAddress(customer) {
      const addressParts = [];
      if (customer.address1 && customer.address1.trim()) {
        addressParts.push(customer.address1.trim());
      }
      if (customer.address2 && customer.address2.trim()) {
        addressParts.push(customer.address2.trim());
      }
      if (customer.address3 && customer.address3.trim()) {
        addressParts.push(customer.address3.trim());
      }
      return addressParts.join(", ");
    },
    handleSelectionChange(selection) {
      this.selectedIds = selection.map(obj => obj.id);
    },
    handleSizeChange(newSize) {
      this.filter.pageSize = newSize;
      this.getClientContacts();
    },
    handleCurrentChange(newPage) {
      this.filter.currentPage = newPage;
      this.getClientContacts();
    },
    getData(client) {
      this.clientContact = client;
      this.activateDialog = true;
    },
    async activateClient(id) {
      try {
        let response = null;
        switch (this.clientContact.isActive) {
          case true:
            response = await clientContactService.deactivate(id);
            break;
          case false:
            response = await clientContactService.activate(id);
            break;
        }
        if (response.data === true) {
          if (this.clientContact.isActive) {
            ShowMessage('success', this.clientContact.name + " deactivated successfully")
          } else {
            ShowMessage('success', this.clientContact.name + " activated successfully")
          }
        }
        await this.getClientContacts();
      } catch (e) {
        console.log(e.error)
      }
    },
    async getClientContacts() {
      try {
        this.loading = true;
        let response = await clientContactService.getContacts(this.filter);
        this.clientContacts = response.data.items ?? [];
        this.filter.totalCount = response.data.totalCount ?? 0;
        this.filter.currentPage = response.data.currentPage ?? 1;
        this.filter.pageSize = response.data.pageSize;
        this.loading = false;
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
    async deleteClient() {
      try {
        this.loading = true;
        let response = await clientContactService.deleteClient(this.clientContact.id);
        if (response.status === 200) {
          ShowMessage("success", this.clientContact?.name + " Deleted Successfully");
        }
        this.loading = false;
        await this.getClientContacts();
      } catch (e) {
        this.loading = false;
        ShowMessage("error", e.response.data.message);
      }
      this.selectedIds = [];
    },
    getMessageByCode(code) {
      // Find the object with the matching code
      if (this.translations == null) {
        this.translations = getLanguage();
        return;
      }
      const matchingObject = this.translations.find(obj => obj.code === code);
      return matchingObject ? matchingObject.message : null;
    },
    async getMarkets() {
      try {
        let response = await marketService.getAll();
        this.markets = this.markets.concat(response.data.items ?? []);
      } catch (e) {
        console.log(e)
      }
    },
  },
  computed: {
    dialog() {
      return store.state.deleteDialog
    }
  },
  watch: {
    dialog(newValue) {
      if (newValue === false) {
        this.selectedIds = [];
      }
    },
  },
  created() {
    this.translations = getLanguage();
    this.clientCreate = checkPermission("MD_CLIENT_CREATE");
    this.clientDel = checkPermission("MD_CLIENT_DEL");
    this.getMarkets();
  }
}
</script>

<style scoped></style>