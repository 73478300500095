<template>
  <div class="container-fluid">
    <div class="container-fluid border-bottom mb-2 p-0">
      <div class="row py-2 align-items-center">
        <div class="col-12 col-md-8">
          <BreadCrumb :showBread="false"/>
        </div>
        <div class="col-12 col-md-4 d-flex justify-content-end">
          <el-button type="primary" @click="addDialog = true; resetFaq()">{{
              getMessageByCode('add') ?? 'Add'
            }}
          </el-button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="d-flex justify-content-end mb-1">
        <div class="col-12 col-md-4 col-lg-4 mb-1 ms-auto p-0 m-0 d-flex justify-content-end">

          <el-input v-model="filter.search" class="align-self-end" clearable
                    :placeholder="getMessageByCode('search') ?? 'Search here...'"
                    @clear="getFaqs"
                    @keyup="getFaqs">
            <template #append>
              <el-button @click="getFaqs">
                <template #icon>
                  <i class="bi bi-search"></i>
                </template>
              </el-button>
            </template>
          </el-input>
        </div>
      </div>
    </div>
    <el-table :data="faqs" border :default-sort="{ prop: 'key', order: 'ascending' }" header-cell-class-name="tbl-header">
      <template #append>
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :total="filter.totalCount"
            :page="filter.currentPage"
            :page-size="filter.pageSize"
            :page-sizes="[10, 20, 30, 40]"
            layout=" prev, pager, next,sizes"
            class="bg-gray p-2"
            background
            size="small"
        >
        </el-pagination>
      </template>
      <el-table-column sortable min-width="250" prop="category" :label="getMessageByCode('category') ?? 'Category'">
      </el-table-column>
      <el-table-column sortable min-width="250" prop="question" :label="getMessageByCode('question') ?? 'Question'">
        <template #default="scope">
          <n-ellipsis style="max-width: 240px">
            {{ scope.row.question }}
          </n-ellipsis>
        </template>
      </el-table-column>
<!--      <el-table-column min-width="250" prop="pageTitle" :label="getMessageByCode('answer') ?? 'Answer'">
        <template #default="scope">
          <n-ellipsis style="max-width: 240px">
            <span>   {{ scope.row.answer }}</span>

          </n-ellipsis>

        </template>
      </el-table-column>-->
      <el-table-column sortable width="120" prop="sortOrder" :label="getMessageByCode('sortOrder') ?? 'Sort Order'">
      </el-table-column>
      <el-table-column sortable width="120" :label="getMessageByCode('isActive') ?? 'Is Active'">
        <template #default="scope">
          <div class="text-center">
            <el-tag type="success" effect="dark" size="small" v-if="scope.row.isActive">Yes</el-tag>
            <el-tag type="danger" effect="dark" size="small" v-else>No</el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" fixed="right" :label="getMessageByCode('actions')??'Actions'" width="130">
        <template #default="scope">
          <el-space alignment="center" spacer="|">
            <i
                v-if="faqEdit"
                class="mgc_edit_line text-primary action-btn"
                @click="editFaq(scope.row,'edit')"
            ></i>
            <i v-if="faqDelete"
               @click="editFaq(scope.row,'del')"
               class="mgc_delete_2_line text-danger action-btn"
            ></i>
          </el-space>
        </template>
      </el-table-column>

    </el-table>


    <el-dialog v-model="deleteDialog" width="500" :title="getMessageByCode('delPage') ?? 'Delete Page'">
      <template #default>
        <span>Are you sure? You want to delete <span class="text-danger fw-semibold">{{
            faq.pageCode
          }}</span> page.</span>
      </template>
      <template #footer>
        <div class="d-flex justify-content-end">
          <el-button bg text @click="deleteDialog = false">{{ getMessageByCode('cancel') ?? 'Cancel' }}</el-button>
          <el-button type="danger" class="ms-2" @click="deleteDialog= false;deleteFaq()">{{
              getMessageByCode('delete') ?? 'Delete'
            }}
          </el-button>
        </div>
      </template>
    </el-dialog>
    <el-dialog v-model="addDialog" align-center width="500"
               :title="faq.id < 1 ? getMessageByCode('addFaq') ?? 'Add Faq' : 'Update Faq'">
      <template #default>
        <el-form class="row" require-asterisk-position="right" label-position="top">
          <el-form-item required class="col-12" :label="getMessageByCode('category') ?? 'Category'">

            <n-input-group>
              <el-select v-model="faq.catId">
                <el-option v-for="cat in categories" :label="cat.title" :value="cat.id" :key="cat.id">

                </el-option>

              </el-select>
              <el-tooltip :content="getMessageByCode('addCat') ?? 'Add Category'">
                <el-button @click="addCatDialog = true" type="primary">+</el-button>
              </el-tooltip>
            </n-input-group>
          </el-form-item>
          <el-form-item class="col-12 col-md-6" :label="getMessageByCode('isActive') ?? 'Is Active'">
            <el-switch v-model="faq.isActive"></el-switch>
          </el-form-item>
          <el-form-item class="col-12 col-md-6" :label="getMessageByCode('sortOrder') ?? 'Sort Order'">
            <el-input type="number" v-model="faq.sortOrder"></el-input>
          </el-form-item>
          <el-form-item required class="col-12" :label="getMessageByCode('question') ?? 'Question'">
            <el-input @input="validateQuestion" v-model="faq.question"></el-input>
            <el-collapse-transition><span v-if="validate.question" class="text-small text-danger">Question field is required</span>
            </el-collapse-transition>
          </el-form-item>
          <el-form-item required class="col-12 editor-div" :label="getMessageByCode('answer') ?? 'Answer'">
<!--            <el-input @input="validateAnswer" type="textarea" :rows="3" v-model="faq.answer"></el-input>-->

            <ckeditor  :editor="editor" class="w-100" v-model="faq.answer" :config="editorConfig"
                      style="width: 100% !important; min-height: 200px !important;"></ckeditor>
            <el-collapse-transition><span v-if="validate.answer"
                                          class="text-small text-danger">Answer field is required</span>
            </el-collapse-transition>

          </el-form-item>
        </el-form>
      </template>
      <template #footer>
        <div class="d-flex justify-content-end">
          <el-button bg text @click="addDialog = false">{{ getMessageByCode('cancel') ?? 'Cancel' }}</el-button>
          <el-button v-if="faq.id < 1" type="primary" class="ms-2" @click="addFaq()">{{
              getMessageByCode('add') ?? 'Add'
            }}
          </el-button>
          <el-button v-else type="primary" class="ms-2" @click="updateFaq()">{{
              getMessageByCode('update') ?? 'Update'
            }}
          </el-button>
        </div>
      </template>
    </el-dialog>
    <el-dialog v-model="addCatDialog" align-center width="500"
               :title="category.id < 1 ? getMessageByCode('addCat') ?? 'Add Category' : 'Update Category'">
      <template #default>
        <el-form class="row" require-asterisk-position="right" label-position="top">
          <el-form-item class="col-12 col-md-6" :label="getMessageByCode('code') ?? 'Code'">
            <el-input @keydown.prevent.space v-model="category.code"></el-input>
          </el-form-item>
          <el-form-item class="col-12 col-md-6" :label="getMessageByCode('title') ?? 'Title'">
            <el-input v-model="category.title"></el-input>
          </el-form-item>
          <el-form-item class="col-12 col-md-6" :label="getMessageByCode('target') ?? 'Target'">
            <el-input v-model="category.target"></el-input>
          </el-form-item>
          <el-form-item class="col-12 col-md-6" :label="getMessageByCode('isActive') ?? 'Is Active'">
            <el-switch v-model="faq.isActive"></el-switch>
          </el-form-item>
        </el-form>
      </template>
      <template #footer>
        <div class="d-flex justify-content-end">
          <el-button bg text @click="addCatDialog = false">{{ getMessageByCode('cancel') ?? 'Cancel' }}</el-button>
          <el-button v-if="category.id < 1" type="primary" class="ms-2" @click="addCategory()">{{
              getMessageByCode('add') ?? 'Add'
            }}
          </el-button>
          <el-button v-else type="primary" class="ms-2" @click="updateCategory()">{{
              getMessageByCode('update') ?? 'Update'
            }}
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import {getLanguage} from "@/Utility/getLanguage";
import {checkPermission} from "@/Utility/CheckPermission";
import faqsService from "@/Services/ComponentsServices/faqsService";
import {ShowMessage} from "@/Utility/Utility";
import BreadCrumb from "@/components/Layout/BreedCrumb.vue";
import {NInputGroup} from "naive-ui";
import {NEllipsis} from "naive-ui";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
export default {
  name: "StaticPages",
  components: {NEllipsis, NInputGroup, BreadCrumb,ckeditor: CKEditor.component,},
  data() {
    return {
      loading: false,
      addDialog: false,
      addCatDialog: false,
      deleteDialog: false,
      faqView: false,
      faqCreate: false,
      faqEdit: false,
      faqDelete: false,
      clientId: sessionStorage.getItem("clientId"),
      translations: [],
      editor: ClassicEditor,
      editorConfig: {
        height: 500,
        shouldNotGroupWhenFull: false,
        indent_style: 'tab',
        tab_width: 4,
        charset: 'utf-8',
        end_of_line: 'lf',
        trim_trailing_whitespace: true,
        insert_final_newline: true
      },

      category: {
        "id": 0,
        "code": "",
        "title": "",
        "target": "",
        "isActive": true
      },
      faq: {
        "id": 0,
        "catId": 0,
        "question": "",
        "answer": "",
        "isActive": true,
        "sortOrder": 0
      },
      validate: {
        question: false,
        answer: false,
      },
      categories: [],
      faqs: [],
      filter: {
        clientId: sessionStorage.getItem("clientId") ?? 0,
        providerId: 1,
        search: "",
        sort: "",
        pageSize: 10,
        currentPage: 1,
        totalCount: 0,
      },
    }
  },
  methods: {
    editFaq(faq, type) {
      this.faq = JSON.parse(JSON.stringify(faq));
      switch (type) {
        case 'edit':
          this.$store.state.pageId = this.faq.id;
          this.addDialog = true;
          break;
        case 'del':
          this.deleteDialog = true;
          break;
      }
    },
    previewPage(htmlContent) {
      const newWindow = window.open('', '_blank');
      newWindow.document.write(htmlContent);
      newWindow.document.close();
    },
    handleSizeChange(newSize) {
      this.filter.pageSize = newSize;
      this.getFaqs();
    }, handleCurrentChange(newPage) {
      this.filter.currentPage = newPage;
      this.getFaqs();
    },
    async getFaqs() {
      this.loading = true;
      try {
        let response = await faqsService.getFaqs(this.filter);
        this.faqs = response.data.items ?? [];
        this.filter.totalCount = response.data.totalCount ?? 0;
        this.filter.currentPage = response.data.currentPage ?? response.data.currentPage === 0 ? 1 : response.data.currentPage || 1;
        this.filter.pageSize = response.data.pageSize;
      } catch (e) {
        console.log(e)
      }
      this.loading = false;
    },
    async getCategories(type) {
      this.loading = true;
      try {
        let response = await faqsService.getAllCategories(this.filter);
        this.categories = response.data.items ?? [];
        if (this.categories.length > 1 && type !== 'new') {
          this.faq.catId = this.categories[0].id
        }
      } catch (e) {
        console.log(e)
      }
      this.loading = false;
    },

    validateQuestion() {
      this.validate.question = !this.faq.question;
    },
    validateAnswer() {
      this.validate.answer = !this.faq.answer;
    },

    async addFaq() {
      this.loading = true;
      try {
        this.validateQuestion();
        this.validateAnswer();
        if (this.validate.question || this.validate.answer) {
          return;
        }
        let response = await faqsService.addFaq(this.faq);
        if (response.status === 200) {
          ShowMessage("success", "Faq Added");
          this.addDialog = false;
          await this.getFaqs();
        }
      } catch (e) {
        ShowMessage("error", e?.data?.msg ?? "Adding faq failed");

      }
    },
    async updateFaq() {
      this.loading = true;
      try {
        let response = await faqsService.updateFaq(this.faq);
        if (response.status === 200) {
          ShowMessage("success", "Faq Updated");
          await this.getFaqs();
          this.addDialog = false;
        }
      } catch (e) {
        ShowMessage("error", e?.data?.msg ?? "Updating category failed");

      }
    },
    async addCategory() {
      this.loading = true;
      try {
        let response = await faqsService.addCategory(this.category);
        if (response.status === 200 && response.data !== null) {
          ShowMessage("success", "Category Added");
          this.faq.catId = response?.data.id;
          this.addCatDialog = false;
          await this.getCategories('new');
        }
      } catch (e) {
        ShowMessage("error", e?.data?.msg ?? "Adding category failed");

      }
    },
    async updateCategory() {
      this.loading = true;
      try {
        let response = await faqsService.updateCategory(this.category);
        if (response.status === 200) {
          ShowMessage("success", "Category Updated");
          await this.getFaqs();
          this.addDialog = false;
        }
      } catch (e) {
        ShowMessage("error", e?.data?.msg ?? "Updating category failed");

      }
    },
    async deleteFaq() {
      this.loading = true;
      try {
        let response = await faqsService.deleteFaq(this.faq.id)
        console.log(response);
      } catch (e) {
        console.log(e)
      }
      this.loading = false;
    },
    getMessageByCode(code) {
      // Find the object with the matching code
      if (this.translations == null) {
        this.translations = getLanguage();
        return;
      }
      const matchingObject = this.translations.find(obj => obj.code === code);
      return matchingObject ? matchingObject.message : null;
    },
    resetFaq() {
      this.faq = {
        "id": 0,
        "catId": this.categories[0]?.id ?? "",
        "question": "",
        "answer": "",
        "isActive": true,
        "sortOrder": 0
      };
    }
  },
  created() {
    this.faqView = checkPermission("STATIC_PAGE_VIEW");
    this.faqCreate = checkPermission("STATIC_PAGE_CREATE");
    this.faqEdit = checkPermission("STATIC_PAGE_EDIT");
    this.faqDelete = checkPermission("STATIC_PAGE_DEL");
    this.getFaqs();
    this.getCategories();
  }
}
</script>

<style scoped>
</style>