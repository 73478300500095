<template>
  <div class="container-fluid">
    <div class="container-fluid border-bottom mb-2 p-0">
      <div class="row align-items-center py-2">
        <div class="col-12 col-md-8">
          <BreadCrumb :view="edit"  :showBread="true"/>
        </div>
        <div class="col-12 col-md-4 d-flex justify-content-end">
          <router-link v-if="edit" to="properties">
            <el-button text bg class="btn btn-light">
              <i class="bi bi-chevron-left me-2"></i>
              <span>{{ getMessageByCode('cancel') ?? 'Cancel' }}</span>
            </el-button>
          </router-link>
          <el-button v-else @click="checkProperty()" text bg class="btn btn-light">
            <i class="bi bi-chevron-left me-2"></i>
            <span>{{ getMessageByCode('cancel') ?? 'Cancel' }}</span>
          </el-button>
          <el-button v-if="propertyEdit && edit" text bg class="border-0 mbtn-primary ms-2" @click="edit = !edit">
            <span>{{ getMessageByCode('edit') ?? 'Edit' }}</span>
          </el-button>
          <el-button @click="UpdateProperty" v-if="Property.id > 0 && propertyEdit && !edit" :disabled="edit"
                     class="border-0 mbtn-primary ms-2">
            <span>{{ getMessageByCode('upd_prop') ?? 'Update Property' }}</span>
          </el-button>
          <el-button @click="AddProperty" v-if="Property.id < 1 && propertyCreate"
                     class="border-0 mbtn-primary ms-2">
            <span>{{ getMessageByCode('add_prop') ?? 'Add Property' }}</span>
          </el-button>

        </div>
      </div>
    </div>
    <div class="container-fluid m-0 p-0">
      <el-tabs v-model="activeTab" class="demo-tabs" type="border-card" @tab-click="handleTabClick">
        <el-tab-pane name="details">
          <template #label>
        <span class="custom-tabs-label">
          <i class="bi bi-card-checklist me-1"></i>
          <span>{{ getMessageByCode('details') ?? 'Details' }}</span>
        </span>
          </template>
          <el-form ref="propertyForm" hide-required-asterisk :model="Property" label-position="top">
            <div class="row d-flex align-items-stretch">
              <div class="col-12 col-md-6 col-lg-4 ">
                <div class="card  border-0 shadow-sm h-100">
                  <div class="card-header card-header-bg2 d-flex justify-content-between align-items-center">
                    <span class="card-title mb-0">{{ getMessageByCode('prop') ?? 'Property' }}</span>
                    <span class="bi bi-building-add"></span>
                  </div>
                  <el-divider border-style="dashed" class="mt-0 mb-0"></el-divider>
                  <div class="p-3">
                    <el-form-item v-if="clientId === null">
                      <template #label>
                        <span class="required">{{ getMessageByCode('client') ?? 'Client' }}</span>
                      </template>
                      <el-select :disabled="edit" v-model="Property.clientId" filterable class="w-100"
                                 placeholder="Select"
                                 @change="getProviders(), getLocalAuthorities()">
                        <el-option
                            v-for="item in Clients"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                        />
                      </el-select>
                    </el-form-item>
                    <el-form-item prop="reference" :rules="formRules.reference">
                      <template #label>
                        <span class="required">{{ getMessageByCode('ref') ?? 'Reference' }}</span>
                      </template>
                      <el-input :disabled="edit" v-model="Property.reference"></el-input>
                    </el-form-item>
                    <el-form-item>
                      <template #label>
                        <span>{{ getMessageByCode('title_num') ?? 'Title Number' }}</span>
                      </template>
                      <el-input :disabled="edit" v-model="Property.titleNo"></el-input>
                    </el-form-item>
                    <el-form-item>
                      <template #label>
                        <span>{{ getMessageByCode('last_reg_num') ?? 'Last Reg Number' }}</span>
                      </template>
                      <el-input :disabled="edit" v-model="Property.lastRegNumber"></el-input>
                    </el-form-item>
                    <el-form-item>
                      <template #label>
                        <span>{{ getMessageByCode('local_auth') ?? 'Local Authority' }}</span>
                      </template>
                      <el-select :disabled="edit" filterable placeholder="Select" v-model="Property.localAuth"
                                 class="w-100">
                        <el-option
                            v-for="item in LocalAuthorities"
                            :key="item.optId"
                            :label="item.optTitle"
                            :value="item.optId"
                        />
                      </el-select>
                    </el-form-item>

                  </div>
                </div>
              </div>
              <!--              <div class="col-12 col-md-6 col-lg-4 ">-->
              <!--                <div class="card  border-0 shadow-sm">-->
              <!--                  <div class="card-header card-header-bg2 d-flex justify-content-between align-items-center">-->
              <!--                    <span class="card-title mb-0">Provider</span>-->
              <!--                    <span class="bi bi-person-badge"></span>-->
              <!--                  </div>-->
              <!--                  <el-divider border-style="dashed" class="mt-0 mb-0"></el-divider>-->
              <!--                  <div class="p-3">-->
              <!--                    <el-form-item>-->
              <!--                      <template #label>-->
              <!--                        <span>Provider</span>-->
              <!--                      </template>-->
              <!--                      <el-select :disabled="edit" v-model="Property.providerId" filterable class="w-100"-->
              <!--                                 placeholder="Select">-->
              <!--                        <el-option-->
              <!--                            v-for="item in Providers"-->
              <!--                            :key="item.id"-->
              <!--                            :label="item.name"-->
              <!--                            :value="item.id"-->
              <!--                        />-->
              <!--                      </el-select>-->
              <!--                    </el-form-item>-->
              <!--                  </div>-->
              <!--                </div>-->
              <!--              </div>-->
              <div class="col-12 col-md-6 col-lg-4  ">
                <div class="card  border-0 shadow-sm  h-100">
                  <div class="card-header card-header-bg2 d-flex justify-content-between align-items-center">
                    <span class="card-title mb-0">{{ getMessageByCode('address') ?? 'Address' }}</span>
                    <span class="bi bi-sign-turn-right"></span>
                  </div>
                  <el-divider border-style="dashed" class="mt-0 mb-0"></el-divider>
                  <div class="p-3">
                    <el-form-item>
                      <template #label>
                        <span>{{ getMessageByCode('provider') ?? 'Provider' }}</span>
                      </template>
                      <el-select :disabled="edit" v-model="Property.providerId" filterable class="w-100"
                                 placeholder="Select">
                        <el-option
                            v-for="item in Providers"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                        />
                      </el-select>
                    </el-form-item>
                    <el-form-item>
                      <template #label>
                        <span class="text-capitalize">{{ getMessageByCode('address') ?? 'Address' }} 1</span>
                      </template>
                      <el-input :disabled="edit" v-model="Property.address1"></el-input>
                    </el-form-item>
                    <el-form-item>
                      <template #label>
                        <span class="text-capitalize">{{ getMessageByCode('address') ?? 'Address' }} 2</span>
                      </template>
                      <el-input :disabled="edit" v-model="Property.address2"></el-input>
                    </el-form-item>
                    <el-form-item>
                      <template #label>
                        <span class="text-capitalize">{{ getMessageByCode('address') ?? 'Address' }} 3</span>
                      </template>
                      <el-input :disabled="edit" v-model="Property.address3"></el-input>
                    </el-form-item>
                    <el-form-item>
                      <template #label>
                        <span>{{ getMessageByCode('city') ?? 'City' }} </span>
                      </template>
                      <el-input :disabled="edit" v-model="Property.city"></el-input>
                    </el-form-item>
                    <el-form-item>
                      <template #label>
                        <span>{{ getMessageByCode('postcode') ?? 'Postcode' }} </span>
                      </template>
                      <el-input :disabled="edit" v-model="Property.postCode"></el-input>
                    </el-form-item>

                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="card  border-0 shadow-sm  h-100">
                  <div class="card-header card-header-bg2 d-flex justify-content-between align-items-center">
                    <span class="card-title mb-0 text-capitalize">{{
                        getMessageByCode('imp_dates') ?? 'Important Dates'
                      }} </span>
                    <span class="bi bi-calendar-check"></span>
                  </div>
                  <el-divider border-style="dashed" class="mt-0 mb-0"></el-divider>
                  <div class="p-3">
                    <el-form-item>
                      <template #label>
                        <span class="text-capitalize">{{ getMessageByCode('ex_date') ?? 'Exempt Date' }} </span>
                      </template>
                      <el-date-picker v-model="Property.dateExempt"
                                      :disabled="edit"
                                      :editable="false"
                                      class="text-start w-100"
                                      :format="dateFormat"
                      ></el-date-picker>
                    </el-form-item>
                    <el-form-item>
                      <template #label>
                        <span class="text-capitalize">{{ getMessageByCode('sla_from') ?? 'SLA From' }} </span>
                      </template>
                      <el-date-picker v-model="Property.dateSlaStart"
                                      :disabled="edit"
                                      :editable="false"
                                      class="text-start w-100"
                                      :format="dateFormat"
                      ></el-date-picker>
                    </el-form-item>
                    <el-form-item>
                      <template #label>
                        <span class="text-capitalize">{{ getMessageByCode('sla_to') ?? 'SLA To' }} </span>
                      </template>
                      <el-date-picker v-model="Property.dateSlaEnd"
                                      :disabled="edit"
                                      :editable="false"
                                      class="text-start w-100"
                                      :format="dateFormat"
                      ></el-date-picker>
                    </el-form-item>
                    <el-form-item>
                      <template #label>
                        <span class="text-capitalize">{{ getMessageByCode('lease_from') ?? 'Lease From' }} </span>
                      </template>
                      <el-date-picker v-model="Property.dateLeaseStart"
                                      :disabled="edit"
                                      :editable="false"
                                      class="text-start w-100"
                                      :format="dateFormat"
                      ></el-date-picker>
                    </el-form-item>
                    <el-form-item>
                      <template #label>
                        <span class="text-capitalize">{{ getMessageByCode('lease_to') ?? 'Lease To' }} </span>
                      </template>
                      <el-date-picker v-model="Property.dateLeaseEnd"
                                      :disabled="edit"
                                      :editable="false"
                                      class="text-start w-100"
                                      :format="dateFormat"
                      ></el-date-picker>
                    </el-form-item>
                  </div>
                </div>
              </div>
            </div>
            <div class="row my-row mt-4 d-flex align-items-stretch">

              <div class="col-12 col-md-6 col-lg-4 ">
                <div class="card  border-0 shadow-sm  h-100">
                  <div class="card-header card-header-bg2 d-flex justify-content-between align-items-center">
                    <span class="card-title mb-0">{{ getMessageByCode('insp') ?? 'Inspections' }}</span>
                    <span class="bi bi-calendar-check"></span>
                  </div>
                  <el-divider border-style="dashed" class="mt-0 mb-0"></el-divider>
                  <div class="p-3">
                    <el-form-item>
                      <template #label>
                        <span
                            class="text-capitalize">{{
                            getMessageByCode('pre_acc_insp') ?? 'Pre Acceptance Inspection'
                          }} </span>
                      </template>
                      <el-date-picker v-model="Property.datePreAcceptInsp"
                                      :disabled="edit"
                                      :editable="false"
                                      class="text-start w-100"
                                      :format="dateFormat"
                      ></el-date-picker>
                    </el-form-item>
                    <el-form-item>
                      <template #label>
                        <span class="text-capitalize">{{ getMessageByCode('insp_date') ?? 'Inspection Date' }} </span>
                      </template>
                      <el-date-picker v-model="Property.dateInspection"
                                      :disabled="edit"
                                      :editable="false"
                                      class="text-start w-100"
                                      :format="dateFormat"
                      ></el-date-picker>
                    </el-form-item>
                    <el-form-item>
                      <template #label>
                        <span class="text-capitalize">{{ getMessageByCode('quat_audit') ?? 'Quarterly Audit' }} </span>
                      </template>
                      <el-date-picker v-model="Property.dateQuarterlyAudit"
                                      :disabled="edit"
                                      :editable="false"
                                      class="text-start w-100"
                                      :format="dateFormat"
                      ></el-date-picker>
                    </el-form-item>
                    <el-form-item>
                      <template #label>
                        <span
                            class="text-capitalize">{{
                            getMessageByCode('quat_insp') ?? 'Quarterly Inspection'
                          }} </span>
                      </template>
                      <el-date-picker v-model="Property.dateQuarterlyInsp"
                                      :disabled="edit"
                                      :editable="false"
                                      class="text-start w-100"
                                      :format="dateFormat"
                      ></el-date-picker>
                    </el-form-item>
                  </div>
                </div>
              </div>
            </div>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="Rooms" name="rooms" v-if="Property.id > 0 && roomView">
          <template #label>
            <i class="bi-columns me-1"></i>
            <span class="text-capitalize">{{ getMessageByCode('rooms') ?? 'Rooms' }} </span>
          </template>
          <div class="d-flex justify-content-end mb-2">
            <el-tooltip
                class="box-item"
                content="Add Room"
                effect="dark"
                placement="top"
            >
              <el-button
                  v-show="roomAdd"
                  type="primary"
                  @click="RoomDialog"
              ><i class="bi-plus fs-4"></i
              ></el-button>
            </el-tooltip>
          </div>
          <el-table :data="Rooms"
                    :default-sort="{ prop: 'roomNo', order: 'descending' }"
                    :fit="true" :flexible="true" border
                    header-cell-class-name="tbl-header"
                    stripe>
            <template #append>
              <el-pagination
                  @size-change="handleSizeChange()"
                  @current-change="handleCurrentChange()"
                  :total="filter.totalCount"
                  :page="filter.currentPage"
                  :page-size="filter.pageSize"
                  :page-sizes="[10, 20, 30, 40]"
                  layout=" prev, pager, next,sizes"
                  class="bg-gray p-2"
                  background
                  size="small"
              >
              </el-pagination>
            </template>
            <el-table-column min-width="100" :label="getMessageByCode('room')  ?? 'Room ' +'#'" prop="roomNo" sortable/>
            <el-table-column min-width="150" :label="getMessageByCode('name')  ?? 'Name'" prop="roomName"/>
            <el-table-column min-width="180" :label="getMessageByCode('tenant')  ?? 'Tenant'" prop="tenant"/>
            <el-table-column min-width="120" :label="getMessageByCode('core_rent')  ?? 'Core Rent'" prop="coreRent">
              <template #default="scope">
                <div class="text-end" v-if="currencyPosition === 'left'">
                  <span>{{ currency + " " + (scope.row.serviceCharge !== null ? scope.row.serviceCharge : 0) }}</span>
                </div>
                <div class="text-end" v-else>{{ scope.row.serviceCharge + ' ' + currency }}
                  <span>{{ (scope.row.serviceCharge !== null ? scope.row.serviceCharge : 0) + " " + currency }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column min-width="120" :label="getMessageByCode('serv_charge')  ?? 'Service Charges'"
                             prop="serviceCharge">
              <template #default="scope">
                <div class="text-end" v-if="currencyPosition === 'left'">
                  <span>{{ currency + " " + (scope.row.coreRent !== null ? scope.row.coreRent : 0) }}</span>
                </div>
                <div class="text-end" v-else>
                  <span>{{ (scope.row.coreRent !== null ? scope.row.coreRent : 0) + " " + currency }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column min-width="130" :label="getMessageByCode('pers_charge')  ?? 'Service Charges'"
                             prop="personalCharge">
              <template #default="scope">
                <div class="text-end" v-if="currencyPosition === 'left'">
                  <span>{{ currency + " " + (scope.row.personalCharge !== null ? scope.row.personalCharge : 0) }}</span>
                </div>
                <div class="text-end" v-else>
                  <span>{{ (scope.row.personalCharge !== null ? scope.row.personalCharge : 0) + " " + currency }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" fixed="right"  :label="getMessageByCode('actions')  ?? 'Actions'"
                             width="120">
              <template #default="scope">
                <el-space alignment="center" spacer="|">
                  <i @click="editRoom(scope.row, 'edit')"
                     class="mgc_edit_line text-primary action-btn"

                  ></i>
                  <i @click="editRoom(scope.row,'del')"
                     class="mgc_delete_2_line text-danger action-btn"
                  ></i>
                </el-space>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="Notes" name="notes" v-if="Property.id > 0 && notesView">
          <template #label>
            <i class="bi-journal-check me-1"></i>
            <span>{{ getMessageByCode('notes') ?? 'Notes' }}</span>
          </template>
          <div class="d-flex justify-content-end mb-2">
            <el-tooltip
                class="box-item"
                content="Add Notes"
                effect="dark"
                placement="top"
            >
              <el-button
                  v-show="notesAdd"
                  type="primary"
                  @click="addNote()"
              ><i class="bi-plus fs-4"></i
              ></el-button>
            </el-tooltip>
          </div>
          <el-table :data="Notes"
                    :fit="true" :flexible="true" :selectable="(row) => row.status !== 'disabled'" border
                    header-cell-class-name="tbl-header"
                    stripe>
            <el-table-column type="index" :index="indexMethod" label="#" min-width="40px"/>
            <el-table-column :label="getMessageByCode('note')  ?? 'Notes'" prop="note" min-width="400px"/>
            <el-table-column v-if="notesEdit || notesDelete" align="center" fixed="right" label="Actions" width="100">
              <template #default="scope">
                <el-space alignment="center" spacer="|">
                  <i v-show="notesEdit"
                     class="mgc_edit_line text-primary action-btn"
                     @click="editNote(scope.row.id)"
                  ></i>
                  <i v-show="notesDelete" @click="deleteNote(scope.row.id)"
                     class="mgc_delete_2_line text-danger action-btn"
                  ></i>
                </el-space>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="Documents" name="documents" v-if="Property.id > 0 && docView">
          <template #label>
            <i class="bi-file-earmark me-1"></i>
            <span class="text-capitalize">{{ getMessageByCode('documents') ?? 'Documents' }}</span>
          </template>
          <div class="d-flex justify-content-end mb-2">
            <el-tooltip
                class="box-item"
                content="Add Document"
                effect="dark"
                placement="top"
            >
              <el-button
                  v-show="docCreate"
                  type="primary"
                  @click="addDocumentD = true"
              ><i class="bi-plus fs-4"></i
              ></el-button>
            </el-tooltip>
          </div>
          <el-table :data="Documents"
                    :fit="true" :flexible="true" :selectable="(row) => row.status !== 'disabled'" border
                    header-cell-class-name="tbl-header"
                    stripe>
            <el-table-column type="index" :index="indexMethod" label="#" min-width="20px"/>
            <el-table-column :label="getMessageByCode('title')  ?? 'Title'" prop="documentFileName" min-width="120px"/>
            <el-table-column :label="getMessageByCode('mime_type')  ?? 'Mime Type'" prop="documentMimeType"
                             min-width="120px"/>
            <el-table-column :label="getMessageByCode('ext')  ?? 'Extension'" prop="documentFileExtension"
                             min-width="120px"/>
            <el-table-column :label="getMessageByCode('size')  ?? 'Soze'" prop="documentSize" min-width="70px">
              <template #default="scope">
                <div class="text-end">
                  <span>{{ getFormattedSize(scope.row.documentSize) }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column v-if="docDownload || docDel" align="center" fixed="right"
                             :label="getMessageByCode('actions')  ?? 'Actions'" width="120">
              <template #default="scope">
                <el-space alignment="center" spacer="|">
                  <i v-if="docDownload"
                     class="bi bi-file-earmark-arrow-down text-primary action-btn"
                     @click="downloadDoc(scope.row.documentId, 'd')"
                  ></i>
                  <i v-if="docDel" @click="deleteDoc(scope.row.documentId, 'd')"
                     class="mgc_delete_2_line text-danger action-btn"
                  ></i>
                </el-space>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="Compliance Documents" name="compliancedocuments" v-if="Property.id > 0 && docView">
          <template #label>
            <i class="bi-file-earmark-break me-1"></i>
            <span class="text-capitalize">{{ getMessageByCode('compl_docs') ?? 'Compliance Documents' }}</span>
          </template>
          <div class="d-flex justify-content-end mb-2">
            <el-tooltip
                class="box-item"
                content="Add Document"
                effect="dark"
                placement="top"
            >
              <el-button
                  v-show="docCreate"
                  type="primary"
                  @click="addComDoc()"
              ><i class="bi-plus fs-4"></i
              ></el-button>
            </el-tooltip>
          </div>
          <el-table :data="ComplianceDocuments"
                    :fit="true" :flexible="true" :selectable="(row) => row.status !== 'disabled'" border
                    header-cell-class-name="tbl-header"
                    stripe>
            <el-table-column type="index" :index="indexMethod" label="#" min-width="20px"/>
            <el-table-column :label="getMessageByCode('title')  ?? 'Title'" prop="name" min-width="120px"/>
            <el-table-column :label="getMessageByCode('mime_type')  ?? 'Mime Type'" prop="mimeType" min-width="120px"/>
            <el-table-column :label="getMessageByCode('type')  ?? 'Type'" prop="docType" min-width="120px"/>
            <el-table-column l:label="getMessageByCode('ext')  ?? 'Extension'" prop="extension" min-width="120px"/>
            <el-table-column :label="getMessageByCode('size')  ?? 'Size'" prop="size" min-width="70px">
              <template #default="scope">
                <div class="text-end">
                  <span>{{ getFormattedSize(scope.row.size) }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column v-if="docDownload || docDel" fixed="right" align="center"
                             :label="getMessageByCode('actions')  ?? 'Actions'" width="120">
              <template #default="scope">
                <el-space alignment="center" spacer="|">
                  <i v-if="docDownload"
                     class="bi bi-file-earmark-arrow-down text-primary action-btn"
                     @click="downloadDoc(scope.row.id, 'c')"
                  ></i>
                  <i v-if="docDel" @click="deleteDoc(scope.row.id, 'c')"
                     class="mgc_delete_2_line text-danger action-btn"
                  ></i>
                </el-space>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </div>

    <!-- Dialogs   -->
    <AddNote :note-id="noteId" @update:loading="updateLoading" @noteadded="getNotes()"></AddNote>
    <AddComplianceDoc :doc-id="docId" @update:loading="updateLoading" @noteadded="getNotes()"/>

    <el-dialog
        v-model="addRoomDialog"
        :title="RoomCardTitle"
        align-center
        class="w-100 w-md-50 text-capitalize"
        draggable
    >
      <div>
        <el-space fill style="width: 100%">

          <div class="row gx-2">
            <el-form-item class="col-12 col-md-4">
              <label class="required text-capitalize">{{ getMessageByCode('room_name') ?? 'Room Name' }}</label>
              <el-input v-model="Room.roomName" type="text"></el-input>
            </el-form-item>
            <el-form-item class="col-12 col-md-3">
              <label class="required text-capitalize">{{ getMessageByCode('room') ?? 'Room' }}
                {{ getMessageByCode('number') ?? 'Number' }}</label>
              <el-input disabled v-model.number="Room.roomNo" @input="checkRoomNo" min="0" max="10"
                        input-style="text-align:right" type="number"></el-input>
            </el-form-item>
            <el-form-item class="col-12 col-md-5">
              <div class="d-flex flex-column w-100">
                <label class="required text-capitalize">{{ getMessageByCode('tenant') ?? 'Tenant' }}</label>
                <el-select :disabled="!Room.endTenancy" v-model="Room.tenantId" class="w-100" filterable placeholder="Select Tenant">
                  <el-option
                      v-for="item in Tenants"
                      :key="item.id"
                      :label="item.firstName + '' + item.lastName"
                      :value="item.id"
                  >
                  </el-option>
                </el-select>


                <!--                  <el-autocomplete-->
                <!--                      v-model="tenant"-->
                <!--                      :fetch-suggestions="querySearch"-->
                <!--                      :popper-append-to-body="false"-->
                <!--                      :value-key="'firstName'"-->
                <!--                      clearable-->
                <!--                      placeholder="Type to search"-->
                <!--                      @select="selectRoomTenant"-->
                <!--                  >-->
                <!--                    <template #suffix>-->
                <!--                      <el-icon class="el-input__icon" style="cursor: pointer">-->
                <!--                        <ArrowDown/>-->
                <!--                      </el-icon>-->
                <!--                    </template>-->
                <!--                    <template #default="{ item }">-->
                <!--                      <div>{{ item.firstName }} {{ item.lastName }}</div>-->
                <!--                    </template>-->

                <!--                  </el-autocomplete>-->
              </div>
            </el-form-item>
          </div>
          <div class="row gx-2">
            <el-form-item class="col-12 col-md-4">
              <label class=" text-capitalize">{{ getMessageByCode('core_rent') ?? 'Core Rent' }}</label>
              <el-input
                  v-model.number="Room.coreRent"
                  :controls="false"
                  input-style="text-align:right"
                  type="number" :step="0.01" :min="0" placeholder='00.00'
              >
                <template #prefix>
                  {{ currency }}
                </template>

              </el-input>
            </el-form-item>
            <el-form-item class="col-12 col-md-4">
              <label class=" text-capitalize">{{ getMessageByCode('serv_charge') ?? 'Service Charges' }}</label>
              <el-input
                  v-model.number="Room.serviceCharge"
                  :controls="false"
                  input-style="text-align:right"
                  type="number" :step="0.01" :min="0" placeholder='00.00'
              >
                <template #prefix>
                  {{ currency }}
                </template>
              </el-input>
            </el-form-item>
            <el-form-item class="col-12 col-md-4">
              <label class="text-capitalize">{{ getMessageByCode('pers_charge') ?? 'Personal Charges' }}</label>
              <el-input
                  v-model.number="Room.personalCharge"
                  :controls="false"
                  input-style="text-align:right"
                  type="number" :step="0.01" :min="0" placeholder='00.00'
              >
                <template #prefix>
                  {{ currency }}
                </template>
              </el-input>
            </el-form-item>
          </div>
          <div class="row gx-2">
            <el-form-item class="col-12 col-md-6">
              <label class="text-capitalize">{{ getMessageByCode('ten_strt_date') ?? 'Tenancy Start Date' }}</label>
              <el-date-picker v-model="Room.tenancyStartDate" :editable="false"
                              class="w-100" :format="dateFormat"
              ></el-date-picker>
            </el-form-item>
            <el-form-item  class="col-12 col-md-6 d-flex flex-column justify-content-center align-items-center" style="line-height: 32px;" :label="getMessageByCode('active') ?? 'Is Active'">

              <el-switch v-model="Room.isActive" ></el-switch>
            </el-form-item>

            <el-form-item class="col-12 col-md-6" style="margin-bottom: 18px">
              <div class="d-flex justify-content-between">

                <div class="d-flex flex-column me-2">
                  <label class="text-capitalize" style="line-height: 32px !important;">{{
                      getMessageByCode('end_ten') ?? 'End Tenancy'
                    }}</label>
                  <el-switch @change="endTenancy()" v-model="Room.endTenancy" type="text"></el-switch>
                </div>
                <el-form-item class="col-12 col-md-8" v-if="Room.endTenancy">
                  <label
                      class="d-block text-capitalize">{{ getMessageByCode('ten_end_date') ?? 'Tenancy End Date' }}</label>
                  <el-date-picker :editable="false" v-model="Room.tenancyEndDate" :format="dateFormat"
                                  class="w-100"></el-date-picker>
                </el-form-item>
              </div>
            </el-form-item>
            <el-form-item class="col-12 col-md-6" v-if="Room.endTenancy">
              <label class="text-capitalize required">{{ getMessageByCode('etr') ?? 'End Tenancy Reason' }}</label>
              <el-select v-model="Room.tenancyEndReason" class="w-100">
                <el-option v-for="reason in tenancyEndReasons" :key="reason.id" :label="reason.optTitle"
                           :value="reason.optId"></el-option>
              </el-select>
            </el-form-item>

            <!--            <div class="row px-0">-->
            <!--              <el-form-item class="col-12 col-md-6 d-flex justify-content-between">-->
            <!--                <div class="d-flex flex-column">-->
            <!--                  <label class="text-capitalize">{{ getMessageByCode('active') ?? 'Is Active' }}</label>-->
            <!--                  <el-switch v-model="Room.isActive" type="text"></el-switch>-->
            <!--                </div>-->
            <!--                <div class="d-flex flex-column">-->
            <!--                  <label class="text-capitalize">{{ getMessageByCode('end_ten') ?? 'End Tenancy' }}</label>-->
            <!--                  <el-switch v-model="Room.endTenancy" type="text"></el-switch>-->
            <!--                </div>-->
            <!--              </el-form-item>-->
            <!--              <el-form-item class="col-12 col-md-6 px-0">-->
            <!--                <div class="d-flex flex-column w-100">-->
            <!--                  <label class="text-capitalize">{{ getMessageByCode('etr') ?? 'End Tenancy' }}</label>-->
            <!--                  <el-select v-model="Room.tenancyEndReason" class="w-100">-->
            <!--                    <el-option v-for="reason in tenancyEndReasons" :key="reason.id" :label="reason.optTitle"-->
            <!--                               :value="reason.optId"></el-option>-->
            <!--                  </el-select>-->
            <!--                </div>-->
            <!--              </el-form-item>-->
            <!--              <el-form-item class="col-12 col-md-4" v-if="RoomCardTitle !== 'Add Room'">-->
            <!--                <div class="d-flex flex-column">-->
            <!--                  <label class="text-capitalize">{{ getMessageByCode('tenant_mov') ?? 'Tenant Moving' }}</label>-->
            <!--                  <el-switch-->
            <!--                      v-model="Room.isTenantIsMoving"-->
            <!--                      type="text"-->
            <!--                      @change="tenantMoving()"-->
            <!--                  ></el-switch>-->
            <!--                </div>-->
            <!--              </el-form-item>-->
            <!--              <el-form-item class="col-12 col-md-4" v-if="RoomCardTitle !== getMessageByCode('add_room') ?? 'Add Room'">-->
            <!--                <div class="d-flex flex-column">-->
            <!--                  <label class="text-capitalize">{{ getMessageByCode('tenant_leav') ?? 'Tenant Leaving' }}</label>-->
            <!--                  <el-switch-->
            <!--                      v-model="Room.isTenantLeaving"-->
            <!--                      type="text"-->
            <!--                      @change="tenantLeaving()"-->
            <!--                  ></el-switch>-->
            <!--                </div>-->
            <!--              </el-form-item>-->
            <!--            </div>-->
          </div>

        </el-space>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button text bg class="btn-plain" @click="addRoomDialog = false"
          >{{ getMessageByCode('cancel') ?? 'Cancel' }}</el-button
          >
          <el-button
              v-if="Room.endTenancy"
              class="btn-plain mbtn-primary"
              type="primary"
              @click="AddOrUpdateRoom"
          >
         End Tenancy
          </el-button>
          <el-button
              v-else
              class="btn-plain mbtn-primary"
              type="primary"
              @click="AddOrUpdateRoom"
          >
         {{ RoomBtnTitle }}
          </el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog
        v-model="addDocumentD"
        :title="getMessageByCode('upload')??'Upload'+' '+getMessageByCode('document')??'Document'"
        width="30%"
    >
      <div>
        <div>
          <label>{{ getMessageByCode('name') ?? 'Name' }}</label>
          <el-form-item>
            <el-input v-model="Document.documentFileName" type="text">
            </el-input>
          </el-form-item>
        </div>
        <div>
          <label>{{ getMessageByCode('category') ?? 'Category' }}</label>
          <el-form-item>
            <el-select v-model="documentType" class="w-100" @change="selectType">
              <el-option v-for="item in DocumentTypes" :key="item.id" :value="item.type">
                {{ item.type }}
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div>
          <label>{{ getMessageByCode('document') ?? 'Document' }}</label>
          <el-form-item>
            <el-input v-model="fileName">
              <template #prepend>
                <el-upload :auto-upload="false" :on-change="handleFileChange" :show-file-list="false"
                           class="mb-0">
                  <template #trigger>
                    <el-button type="primary">{{ getMessageByCode('select_file') ?? 'select file' }}</el-button>
                  </template>
                </el-upload>
              </template>
            </el-input>
            <div class="w-100 d-flex justify-content-end">

              <el-space>
                <span class="custom-caption">{{ Document.documentSize }}</span>
                <span class="custom-caption">{{ Document.documentFileExtension }}</span>
              </el-space>

            </div>
          </el-form-item>
        </div>
      </div>
      <template #footer>
          <span class="dialog-footer">
            <el-button text bg @click="addDocumentD = false">{{ getMessageByCode('cancel') ?? 'Cancel' }}</el-button>
            <el-button type="primary" @click="addDocumentD = false; AddPropertyDoc()">
            {{ getMessageByCode('upload') ?? '  Upload' }}
            </el-button>
          </span>
      </template>
    </el-dialog>
    <el-dialog
        v-model="addDocDialog"
        title="Add Notes"
        width="30%"
    >
      <div>
        <div>
          <label>Category</label>
          <el-form-item>
            <el-select v-model="documentType" class="w-100" @change="selectType">
              <el-option v-for="item in NotesCategories" :key="item.id" :value="item.type">
                {{ item.name }}
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div>
          <label>Document</label>
          <el-form-item>
            <el-input v-model="fileName">
              <template #prepend>
                <el-upload :auto-upload="false" :on-change="handleFileChange" :show-file-list="false"
                           class="mb-0">
                  <template #trigger>
                    <el-button type="primary">select file</el-button>
                  </template>
                </el-upload>
              </template>
            </el-input>
            <div class="w-100 d-flex justify-content-end">

              <el-space>
                <span class="custom-caption">{{ Document.documentSize }}</span>
                <span class="custom-caption">{{ Document.documentFileExtension }}</span>
              </el-space>

            </div>
          </el-form-item>
        </div>
      </div>
      <template #footer>
          <span class="dialog-footer">
            <el-button @click="addDocumentD = false">Cancel</el-button>
            <el-button type="primary" @click="addDocumentD = false,AddPropertyDoc()">
              {{ DocBtnTitle }}
            </el-button>
          </span>
      </template>
    </el-dialog>
    <el-dialog
        v-model="deleteRoomDialog"
        title="Delete Room"
        width="30%"
    >
      <span>Do you want to delete {{ Room.roomNo }}</span>
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="deleteRoomDialog = false">Cancel</el-button>
        <el-button class="bg-danger text-white border-0" @click="deleteRoomDialog = false, deleteRoom()">
          Delete
        </el-button>
      </span>
      </template>
    </el-dialog>
    <div v-if="loading" class="spinner-border"></div>
  </div>
</template>

<script>
import {ShowMessage} from "@/Utility/Utility";
import ClientService from "@/Services/ComponentsServices/clientService";
import ProviderService from "@/Services/ComponentsServices/ProviderService";
import store from "@/store";
import PropertyService from "@/Services/ComponentsServices/PropertyService";
import roomService from "@/Services/ComponentsServices/roomService";
import documentService from "@/Services/ComponentsServices/documentService";
import tenantService from "@/Services/ComponentsServices/tenantService";
import optionService from "@/Services/ComponentsServices/optionService";
import AddNote from "@/components/Properties/Dialogs/AddNote.vue";
import noteService from "@/Services/ComponentsServices/noteService";
import SizeCalc from "@/components/Services/SizeCalc";
import AddComplianceDoc from "@/components/Properties/Dialogs/AddComplianceDoc.vue";
import complianceDocService from "@/Services/ComponentsServices/complianceDocService";
import BreadCrumb from "@/components/Layout/BreedCrumb.vue";
import {checkPermission} from "@/Utility/CheckPermission";
import configService from "@/Services/ComponentsServices/configService";
import {getLanguage} from "@/Utility/getLanguage";

export default {
  name: "AddProvider",
  components: {BreadCrumb, AddComplianceDoc, AddNote},
  data() {
    return {
      mainBtnText: "Add Property",
      deleteRoomDialog: false,
      propertyEdit: false,
      propertyCreate: false,
      edit: true,
      roomView: false,
      roomAdd: false,
      roomEdit: false,
      roomDelete: false,
      notesView: false,
      notesEdit: false,
      notesAdd: false,
      notesDelete: false,
      docView: false,
      docDownload: false,
      docEdit: false,
      docDel: false,
      docCreate: false,
      dateFormat: "DD/MM/YYYY",
      currency: "£",
      currencyPosition: "left",
      noteId: 0,
      docId: 0,
      clientId: sessionStorage.getItem("clientId"),
      LocalAuthorities: [],
      translations: [],
      RoomCardTitle: "Add Room",
      RoomBtnTitle: "Add",
      activeTab: 'details',
      addDocDialog: false,
      addNoteDialog: false,
      fileName: '',
      fileSize: '',
      addDocumentD: false,
      addDisabled: false,
      addRoomDialog: false,
      tenant: "",
      documentType: "",
      ComplianceDocuments: [],
      Document: {
        "documentId": 0,
        "documentPropertyId": store.state.pageId,
        "documentFileName": "",
        "documentType": 1,
        "documentFileExtension": "",
        "documentMimeType": "",
        "documentObject": "",
        "documentSize": 0,
      },

      documentsColumns: [
        {"label": "Name", "prop": "name", "width": "150"},
        {"label": "Category", "prop": "category", "width": "150"},
        {"label": "Description", "prop": "description", "width": "150"},
        {"label": "Type", "prop": "extension", "width": "100"},
        {"label": "Size", "prop": "size", "width": "100"},
      ],
      loading: false,
      Clients: [],
      client: {},
      provider: {},
      Rooms: [],
      Notes: [],
      Tenants: [],
      Documents: [],
      Providers: [],
      DocumentTypes: [],
      tenancyEndReasons: [],
      NotesCategories: [],
      Room: {
        id: 0,
        propertyId: 0,
        tenantId: "",
        tenant: "",
        roomNo: 0,
        roomName: "",
        tenancyStartDate: null,
        tenancyEndDate: null,
        coreRent: '',
        serviceCharge: '',
        personalCharge: '',
        isTenantIsMoving: false,
        isTenantLeaving: false,
        isActive: true,
        endTenancy: false,
        tenancyEndReason: ""
      },
      formRules: {
        title: [
          {required: true, message: 'Please enter title', trigger: 'blur'},
          // You can add more rules for the name field if needed
        ], reference: [
          {required: true, message: 'Please enter reference', trigger: 'blur'},
          // You can add more rules for the name field if needed
        ],
        address1: [
          {required: true, message: 'Please enter address', trigger: 'blur'},
          // You can add more rules for the name field if needed
        ],

      },
      Property: {
        id: store.state.pageId,
        clientId: sessionStorage.getItem("clientId") ?? 0,
        reference: "",
        providerId: "",
        areaId: 0,
        agentId: 0,
        address1: "",
        address2: "",
        address3: "",
        city: "",
        postCode: "",
        county: "",
        country: 0,
        fireExitBlanket: 0,
        numberOfRooms: 0,
        titleNo: "",
        lastRegNumber: 0,
        localAuth: "",
        dateSlaStart: null,
        dateSlaEnd: null,
        dateLeaseStart: null,
        dateLeaseEnd: null,
        datePreAcceptInsp: null,
        dateInspection: null,
        dateExempt: null,
        dateQuarterlyAudit: null,
        dateQuarterlyInsp: null,
        isPublished: true,
        clientName: "",
        providerName: "",
      },

      filter: {
        clientId: sessionStorage.getItem("clientId") ?? 0,
        tenantType: 1,
        providerId: 1,
        propertyId: store.state.pageId,
        search: "",
        sort: "",
        pageSize: 10,
        currentPage: 1,
        totalCount: 0,
      },
    }
  },
  computed: {
    inputFileName() {
      if (this.fileName === '') {
        return this.Document.documentFileName;
      } else {
        return this.fileName;
      }
    }
  },
  methods: {
    endTenancy() {
      if (!this.Room.endTenancy) {
        this.Room.tenancyEndReason = "";
            this.Room.tenancyEndDate = null;
      } else {
        this.Room.tenantId = "";
        this.Room.tenancyEndDate = new Date();
      }
    },
    tenantMoving() {
      if (this.Room.isTenantIsMoving) {
        this.Room.tenantId = "";
      }
    }, tenantLeaving() {
      if (this.Room.isTenantLeaving) {
        this.Room.tenantId = "";
      }
    },
    checkProperty() {
      if (this.Property.id < 1) {
        this.$router.push('properties');
        return;
      }
      this.edit = !this.edit
    },
    checkRoomNo() {
      // Check if the entered value is less than 0 or greater than 10
      if (this.Room.roomNo < 0) {
        this.Room.roomNo = 0;
      } else if (this.Room.roomNo > 10) {
        this.Room.roomNo = 10;
      }
    },
    async downloadDoc(id, type) {
      try {
        if (type === 'd') {
          // eslint-disable-next-line no-unused-vars
          let response = await documentService.download(id);

        } else if (type === 'c') {
          // eslint-disable-next-line no-unused-vars
          let response = await complianceDocService.download(id);

        }

      } catch (e) {
        // eslint-disable-next-line no-unused-vars
        let a = e;
      }
    },
    async deleteDoc(id, type) {
      if (type === 'd') {
        let response = await documentService.deletePropertyDoc(id);
        if (response.status === 200) {
          ShowMessage("success", "Document deleted successfully");
          await this.getDocuments();
        }
      } else if (type === 'c') {
        let response = await complianceDocService.delete(id);
        if (response.status === 200) {
          ShowMessage("success", "Document deleted successfully");
        }
      }
    }
    ,
    getFormattedSize(size) {
      if (size > 0) {
        return SizeCalc.FormattedSize(size)
      } else {
        return 0;
      }
    }
    ,
    editNote(id) {
      this.noteId = id;
      store.state.addNoteDialog = true;
    }
    ,
    async deleteNote(id) {
      try {
        let response = await noteService.delete(id);
        if (response.status == 200) {
          this.getNotes();
        }
      } catch (e) {
        console.log(e)
      }
    }
    ,
    indexMethod(number) {
      return number + 1;
    }
    ,
    updateLoading(value) {
      this.loading = value;
    }
    ,
    addNote() {
      this.noteId = 0;
      store.state.addNoteDialog = true;
    }
    ,
    addComDoc() {
      this.docId = 0;
      store.state.addComplianceDocDialog = true;
    }
    ,

    handleSizeChange(newSize) {
      this.filter.pageSize = newSize;
      this.getRooms();
    }
    ,
    handleCurrentChange(newPage) {
      this.filter.currentPage = newPage;
      this.getRooms();
    }
    ,
    selectType() {
      this.Document.documentType = this.DocumentTypes.find(item => item.type === this.documentType).id;
    }
    ,
    handleFileChange(file) {
      this.fileName = file.raw.name;
      this.Document.documentSize = file.raw.size;
      this.Document.documentFileExtension = file.raw.name.split('.').pop();
      this.Document.documentMimeType = file.raw.type;
      try {
        if (file) {
          const reader = new FileReader();
          reader.onload = () => {
            const base64String = btoa(new Uint8Array(reader.result).reduce((data, byte) => data + String.fromCharCode(byte), ''));
            this.Document.documentObject = base64String;
          };
          reader.readAsArrayBuffer(file.raw);
        }
      } catch (error) {
        console.error(error);
      }
    }
    ,

    RoomDialog() {
      this.RoomCardTitle = "Add Room";
      this.RoomBtnTitle = "Add";
      let greatestRoomNo = 0;
      for (let room of this.Rooms) {
        if (room.roomNo > greatestRoomNo) {
          greatestRoomNo = room.roomNo;
        }
      }
      this.Room = {
        id: 0,
        propertyId: store.state.pageId,
        tenantId: "",
        tenant: "",
        roomNo: greatestRoomNo + 1,
        roomName: "",
        tenancyStartDate: null,
        tenancyEndDate: null,
        coreRent: 0,
        serviceCharge: 0,
        personalCharge: 0,
        isTenantIsMoving: false,
        isTenantLeaving: false,
        isActive: true,
      }
      this.addRoomDialog = true;
    }
    ,
    editRoom(rowData, type) {
      this.Room = {...rowData};
      if (type === 'edit') {
        // let tnnt = {
        //   id: rowData.tenantId,
        //   firstName: rowData.tenant,
        //   lastName: "",
        // };
        // this.Tenants.push(tnnt);

        if (this.Room.tenantId < 1) {
          this.Room.tenantId = "";
        }
        this.addRoomDialog = true;
        this.RoomCardTitle = "Update Room";
        this.RoomBtnTitle = this.getMessageByCode('update') ?? 'Update';
      }
      if (type === 'del') {
        this.deleteRoomDialog = true;
      }
    },
    async deleteRoom() {
      try {
        // eslint-disable-next-line no-unused-vars
        let response = await roomService.delete(this.Room.id);
      } catch (e) {
        ShowMessage("error", e.response.data.message ?? "Something went wrong!.")
      }
    },
    handleTabClick(tabName) {
      let tab = tabName.index;
      switch (tab) {
        case "0":
          this.getProperty();
          break;
        case "1":
          this.getRooms();
          break;
        case "2":
          this.getNotes();
          break;
        case "3":
          this.getDocuments();
          break;
        case "4":
          this.getComplianceDocuments();
          break;
        default:
          this.getProperty();
          break;
      }
    }
    ,
    async getClients() {
      try {
        this.loading = true;
        if (this.clientId == null || this.clientId == 0) {
          let response = await ClientService.getAll();
          this.Clients = response.data.items ?? [];
          if (this.Clients.length > 0) {
            if (this.Property.id < 1) {
              this.Property.clientId = this.Clients[0].id;
              await this.getLocalAuthorities();
            }
            await this.getProviders();
            await this.getTenants();
          }

        } else {
          this.Property.clientId = parseInt(this.clientId);
          await this.getProviders();
          await this.getTenants();
        }
        this.loading = false;
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    }
    ,

    async AddPropertyDoc() {
      try {
        this.loading = true;
        // eslint-disable-next-line no-unused-vars
        let response = await documentService.addPropertyDoc(this.Document);

      } catch (e) {
        console.log(e)
      }
    }
    ,
    async AddProperty() {
      this.loading = true;
      this.$refs.propertyForm.validate(async (valid) => {
        if (valid) {
          try {

            this.Property.localAuth = Number(this.Property.localAuth) ?? 0;
            let response = await PropertyService.add(this.Property);
           if(response?.status === 200 && response?.data !== null){
             this.Property = response.data;
             this.mainBtnText = "Update Property";
             ShowMessage("success", "Property added successfully");
           }
            this.loading = false;
          } catch (e) {
            console.log(e)
            ShowMessage("error", e.response.data.message);
            this.loading = false;
          }
        } else {
          ShowMessage("error", "Please enter the required fields!");
          this.loading = false;
        }
      });
    }
    ,
    async UpdateProperty() {
      this.loading = true;
      this.$refs.propertyForm.validate(async (valid) => {
        if (valid) {
          try {
            this.Property.localAuth = Number(this.Property.localAuth) ?? 0;
            // eslint-disable-next-line no-unused-vars
            let response = await PropertyService.update(this.Property);
            if(response?.status === 200 && response?.data !== null){
              this.Property = response.data;
              this.mainBtnText = "Update Property";
              ShowMessage("success", "Property updated successfully");
            }
          } catch (e) {
            console.log(e)
            ShowMessage("error", "Property updating failed");
            this.loading = false;
          }
        } else {
          this.loading = false;
          ShowMessage("error", "Please enter the required fields!")
        }
      });
    },
    async getProperty() {
      try {
        this.loading = true;
        let response = await PropertyService.get(store.state.pageId);
        this.Property = response.data ?? {};
        await this.getClients();
        this.loading = false;
      } catch (e) {
        console.log(e);
        ShowMessage("error", "Something went wrong!");
        this.loading = false;
      }
    }
    ,
    getProviders: async function () {
      try {
        this.loading = true;
        let response = await ProviderService.getAll(this.Property.clientId);
        this.Providers = response.data.items ?? [];
        if (this.Providers.length > 0) {
          this.Property.providerId = this.Providers[0].id;
        } else {
          this.Property.providerId = "";
        }
        this.loading = false;
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    }
    ,
    AddOrUpdateRoom() {
      if (this.Room.id > 0) {
        this.UpdateRoom();
      } else {
        this.AddRoom();
      }
    }
    ,
    async AddRoom() {
      try {
        this.Room.tenantId = Number(this.Room.tenantId) ?? 0;
        this.Room.tenancyEndReason = Number(this.Room.tenancyEndReason) ?? 0;
        this.addRoomDialog = false;
        let response = await roomService.add(this.Room);
        switch (response.status) {
          case 200:
            ShowMessage("success", "Room added successfully");
            await this.getRooms();
            break;
          default:
            ShowMessage("error", "Something went wrong!");
        }
      } catch (e) {
        console.log(e);
        ShowMessage("error", "Something went wrong!");
      }
    }
    ,
    UpdateRoom: async function () {
      try {

        if(this.Room.endTenancy && this.Room.tenancyEndReason < 1){
          ShowMessage("error", "Please select reason for tenancy end");
          return;
        }
        this.Room.tenantId = Number(this.Room.tenantId) ?? 0;
        this.addRoomDialog = false;
        // eslint-disable-next-line no-unreachable
        let response = await roomService.update(this.Room);
        switch (response.status) {
          case 200:
            ShowMessage("success", "Room updated successfully");
            await this.getRooms();
            break;
          default:
            ShowMessage("error", "Something went wrong!");
        }
      } catch (e) {
        console.log(e);
        ShowMessage("error", "Something went wrong!");
      }
    }
    ,
    async getRooms() {
      this.loading = true;
      try {
        if (this.Property.id > 0) {
          this.filter.propertyId = this.Property.id;
          let response = await roomService.getAll(this.filter.propertyId);
          this.Rooms = response.data.items ?? [];
          this.loading = false;
          this.filter.totalCount = response.data.totalCount ?? 0;
          this.filter.currentPage = response.data.currentPage ?? 1;
        }
        this.loading = false;
      } catch (e) {
        this.loading = false;
        console.log(e)
      }
    }
    ,
    async getNotes() {
      this.loading = true;
      try {
        if (this.Property.id > 0) {
          this.filter.propertyId = this.Property.id;
          let response = await noteService.getByPropertyId(this.Property.id);
          this.Notes = response.data ?? [];
          this.loading = false;

        }
        this.loading = false;
      } catch (e) {
        this.loading = false;
        console.log(e)
      }
    }
    ,

    async getDocuments() {
      try {
        this.loading = true;
        if (this.Property.id > 0) {
          let response = await documentService.getpropertydocs(this.Property.id);
          this.Documents = response.data.items ?? [];
        }
        this.loading = false;
      } catch (e) {
        console.log(e);
        this.loading = false;
      }

    },
    async getComplianceDocuments() {
      try {
        this.loading = true;
        if (this.Property.id > 0) {
          let response = await complianceDocService.getbyProperty(this.Property.id);
          this.ComplianceDocuments = response.data.items ?? [];
        }
        this.loading = false;
      } catch (e) {

        console.log(e);
        this.loading = false;
      }
    },
    async getTenants() {
      try {
        this.filter.clientId = this.Property.clientId;
        this.filter.tenantType = 1;
        let response = await tenantService.getnonoccupied(this.Property.clientId);
        this.Tenants = response.data.items ?? [];
      } catch (e) {
        console.log(e);
        this.$store.state.loading = false;
      }
    },
    async getDocumentTypes() {
      try {
        let response = await documentService.getTypes(this.Property.clientId);
        this.DocumentTypes = response.data.items ?? [];
        if (this.DocumentTypes.length > 0) {
          this.documentType = this.DocumentTypes[0].type;
          this.Document.documentType = this.DocumentTypes[0].id;
        }
      } catch (e) {
        console.log(e);
      }
    },
    async getLocalAuthorities() {
      try {
        let response = await optionService.getOptionsByHeaderId(this.Property.clientId, 19)
        this.LocalAuthorities = response.data ?? [];
        if (this.LocalAuthorities.length > 0) {
          this.Property.localAuth = this.LocalAuthorities[0].optId;
        } else {
          this.Property.localAuth = ""
        }
      } catch (e) {
        console.log(e);
        this.$store.state.loading = false;
      }
    },
    async getConfigs() {
      try {
        this.loading = true;
        let id = 0;
        if (this.clientId === null) {
          id = 0;
        } else {
          id = this.clientId;
        }
        let response = await configService.getByKey(id, "CurrencySymbol");
        this.currency = response.data.value ?? "£";
        let response2 = await configService.getByKey(id, "CurrencyPosition");
        this.currencyPosition = response2.data.value ?? "left";
        let response3 = await configService.getByKey(id, "DateFormat");
        this.dateFormat = response3.data.value ?? "DD/MM/YYYY";
        this.loading = false;
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
    async getTenancyEndReasons() {
      this.loading = true;
      try {
        let response = await optionService.getOptionsByKey(this.clientId ?? 0, "TenancyEndReason");
        this.tenancyEndReasons = response?.data;

      } catch (e) {
        console.log(e)
      }
    },
    getMessageByCode(code) {
      // Find the object with the matching code
      if (this.translations == null) {
        this.translations = getLanguage();
        return;
      }
      const matchingObject = this.translations.find(obj => obj.code === code);
      return matchingObject ? matchingObject.message : null;
    }
  },
  created() {
    this.translations = getLanguage();
    this.propertyEdit = checkPermission("MD_PROP_EDIT");
    this.propertyCreate = checkPermission("MD_PROP_CREATE");
    this.roomView = checkPermission("MD_ROOM_VIEW");
    this.roomAdd = checkPermission("MD_ROOM_CREATE");
    this.notesView = checkPermission("MD_NOTES_VIEW");
    this.docCreate = checkPermission("MD_DOCUMENT_CREATE");
    this.docEdit = checkPermission("MD_DOCUMENT_EDIT");
    this.docDel = checkPermission("MD_DOCUMENT_DEL");
    this.docView = checkPermission("MD_DOCUMENT_VIEW");
    this.notesAdd = checkPermission("MD_NOTES_CREATE");
    this.getDocumentTypes();
    this.getTenancyEndReasons();
    const encodedId = this.$route.query.id;
    const decodedId = atob(encodedId);
    if (decodedId) {
      this.$store.state.pageId = decodedId;
      this.Property.id = decodedId;
      this.mainBtnText = "Update Property";
      this.Room.propertyId = store.state.pageId;
      this.getProperty();
    } else {
      this.edit = false;
      this.mainBtnText = "Add Property";

    }
    this.getClients();
    this.getLocalAuthorities();
    this.getConfigs();
    this.RoomCardTitle = this.getMessageByCode('add_room') ?? 'Add Room';
    this.RoomBtnTitle = this.getMessageByCode('add') ?? 'Add';
    //  this.currency =  configService.getByKey(sessionStorage.getItem("clientId")??0,"CurrencySymbol").configValue ?? "£";
    // this.currencyPosition = configService.getByKey(sessionStorage.getItem("clientId")??0,"CurrencyPosition").configValue ?? "right";

  }
}
</script>

<style scoped>

</style>