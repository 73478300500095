<template>
  <div class="container-fluid">
    <ToolBar :show-bread="false" clear-id="customerId"
             :showSelect="false" :ShowAddBtn="contactCreate" routeName="contact"
             :btnText="getMessageByCode('add') ?? 'Add'">
      <template v-slot:select>
        <el-select v-if="clientId === null || clientId === 0" class="w-100" filterable @change="getContacts()"
                   v-model="filter.clientId">
          <el-option v-for="item in clients"
                     :key="item.id"
                     :label="item.name"
                     :value="item.id">
            <template #default>
              <div class="row">
                <span class="col-7">{{ item.name }}</span>
                <span class="text-small col-5">{{ item.market }}</span>
              </div>
            </template>
          </el-option>
        </el-select>
        <el-button type="primary" class="ms-2" @click="resetContact(); addDialog = true">{{getMessageByCode('add') ?? "Add"}}</el-button>
      </template>
    </ToolBar>
    <div class="container-fluid p-0">
      <div class="row">
        <div class="col-12 col-md-6 mb-2 ms-auto d-flex justify-content-end">

          <el-select filterable @change="getContacts()"
                     v-model="filter.category" style="width: 250px">
            <el-option
                v-for="item in categories"
                :key="item.id"
                :label="item.title"
                :value="item.id"

            >

            </el-option>
          </el-select>


          <el-input class="align-self-end ms-2" :placeholder="getMessageByCode('search') ?? 'Search here..'"
                    @clear="getContacts" v-model="filter.search"
                    @keyup="getContacts"
                    clearable>
            <template #append>
              <el-button @click="getContacts">
                <template #icon>
                  <i class="bi bi-search"></i>
                </template>
              </el-button>
            </template>
          </el-input>


        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <el-table :data="contacts"
                    :fit="true" :flexible="true" :selectable="(row) => row.status !== 'disabled'" border
                    header-cell-class-name="tbl-header"
                    stripe @selection-change="handleSelectionChange">
            <template #append>
              <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :total="filter.totalCount"
                  :page="filter.currentPage"
                  :page-size="filter.pageSize"
                  :page-sizes="[10, 20, 30, 40]"
                  layout=" prev, pager, next,sizes"
                  class="bg-gray p-2"
                  background
                  size="small"
              >
              </el-pagination>
            </template>
            <el-table-column :label="getMessageByCode('title') ?? 'Title'" prop="title" min-width="80"
            >
            </el-table-column>
            <el-table-column :label="getMessageByCode('firstName') ?? 'First Name'" prop="firstName" min-width="120"
                             sortable>
            </el-table-column>
            <el-table-column sortable show-overflow-tooltip :label="getMessageByCode('surname') ?? 'Surname'"
                             prop="surname" min-width="120"/>
            <el-table-column sortable show-overflow-tooltip :label="getMessageByCode('email') ?? 'Email'" prop="email"
                             min-width="200"/>
            <el-table-column sortable show-overflow-tooltip min-width="250"
                             :label="getMessageByCode('address')??'Address'" prop="address1">
              <template #default="scope">
                <span>{{ scope.row.address1 }}</span><span v-if="scope.row.address2">, {{
                  scope.row.address2
                }}</span><span v-if="scope.row.address3">, {{ scope.row.address3 }}</span>
              </template>
            </el-table-column>
            <el-table-column sortable show-overflow-tooltip :label="getMessageByCode('postcode') ?? 'Postcode'"
                             prop="postcode" min-width="120"></el-table-column>
            <el-table-column sortable show-overflow-tooltip :label="getMessageByCode('city/town') ?? 'City/Town'"
                             prop="city" min-width="200"/>
            <el-table-column sortable show-overflow-tooltip :label="getMessageByCode('type') ?? 'Type'" prop="category"
                             min-width="120"/>
            <el-table-column align="center" fixed="right" :label="getMessageByCode('actions') ?? 'Actions'" width="80">
              <template #default="scope">
                <div class="d-flex justify-content-center">
                  <!--                  <el-tooltip content="View">-->
                  <!--                    <i @click="takeRow(scope.row, 'view')"-->
                  <!--                       class="mgc_eye_2_line text-primary action-btn"-->
                  <!--                    ></i>-->
                  <!--                  </el-tooltip>-->
                  <el-tooltip content="Edit">
                    <i @click="takeRow(scope.row, 'edit')"
                       class="mgc_edit_line text-primary action-btn ms-2"
                    ></i>
                  </el-tooltip>
                  <el-tooltip content="Delete">
                    <i @click="takeRow(scope.row, 'delete')"
                       class="mgc_delete_2_line text-danger action-btn ms-2"
                    ></i>
                  </el-tooltip>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>


    <el-dialog v-model="deleteDialog" width="500" title="Delete Customer">
      <template #default>
        <span>Are you sure? You want to delete <span class="text-danger fw-semibold">{{ contact.firstName }}</span> customer.</span>
      </template>
      <template #footer>
        <div class="d-flex justify-content-end">
          <el-button bg text @click="deleteDialog = false">Cancel</el-button>
          <el-button type="danger" class="ms-2" @click="deleteContact()">Delete</el-button>
        </div>
      </template>
    </el-dialog>

    <el-dialog v-model="addDialog" width="650" :title="contact.id > 0 ? 'Edit Contact' : 'Add Contact'" draggable
               align-center>
      <template #default>
        <el-form label-position="top">
          <div class="row">
            <el-form-item class="col-12" v-if="clientId === null || clientId === 0 || !clientId">
              <template #label>
                <span class="required">{{ getMessageByCode('client') ?? 'Client' }}</span>
              </template>
              <el-select v-model="contact.clientId" filterable class="w-100"
                         placeholder="Select"
              >
                <el-option v-for="item in clients"
                           :key="item.id"
                           :label="item.name"
                           :value="item.id">
                  <template #default>
                    <div class="row">
                      <span class="col-7">{{ item.name }}</span>
                      <span class="text-small col-5">{{ item.market }}</span>
                    </div>
                  </template>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item class="col-12 col-md-6">
              <template #label>
                <span class="required">{{ getMessageByCode('category') ?? 'Category' }}</span>
              </template>
              <n-button-group class="w-100">
                <el-select v-model="contact.categoryId" filterable class="w-100"
                           placeholder="Select Category"
                >
                  <el-option
                      v-for="item in categories"
                      :key="item.id"
                      :label="item.title"
                      :value="item.id"
                      :disabled="Number(item.id)=== 0"
                  >
                    <template #default>
                      <div class="d-flex justify-content-between align-items-center">
                       <span>
                         {{ item.title }}
                       </span>
                        <i v-if="item.title !== 'Tenants' && item.title !== 'Landlords' && item.title !== 'All' && clientId < 1"
                           class="mgc_delete_2_line action-btn text-danger" @click="deleteCategory(item.id)"></i>
                      </div>
                    </template>
                  </el-option>
                </el-select>
                <el-button type="primary" @click="addCategoryDialog = true">+</el-button>
              </n-button-group>
            </el-form-item>
            <el-form-item class="col-12 col-md-6" :label="getMessageByCode('title') ?? 'Title'">
              <el-select v-model="contact.title" class="w-100">
                <el-option v-for="type in honorifics"
                           :key="type.optId"
                           :label="type.optTitle"
                           :value="type.optTitle"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item class="col-12 col-md-6" :label="getMessageByCode('firstName') ?? 'First Name'">
              <el-input v-model="contact.firstName"></el-input>
            </el-form-item>
            <el-form-item class="col-12 col-md-6" :label="getMessageByCode('surname') ?? 'Surname'">
              <el-input v-model="contact.surname"></el-input>
            </el-form-item>
            <el-form-item required class="col-12 col-md-6" :label="getMessageByCode('email') ?? 'Email'">
              <el-input v-model="contact.email"></el-input>
            </el-form-item>
            <el-form-item class="col-12 col-md-6" :label="getMessageByCode('fax') ?? 'Fax'">
              <el-input v-model="contact.fax"></el-input>
            </el-form-item>
            <el-form-item class="col-12 col-md-6" :label="getMessageByCode('phone') ?? 'Telephone'">
              <el-input v-model="contact.telephone"></el-input>
            </el-form-item>
            <el-form-item class="col-12 col-md-6" :label="getMessageByCode('mobile') ?? 'Mobile'">
              <el-input v-model="contact.mobile"></el-input>
            </el-form-item>
            <el-form-item class="col-12 col-md-6" :label="getMessageByCode('address') ?? 'Address ' + '1'">
              <el-input v-model="contact.address1"></el-input>
            </el-form-item>
            <el-form-item class="col-12 col-md-6" :label="getMessageByCode('address') ?? 'Address ' + '2'">
              <el-input v-model="contact.address2"></el-input>
            </el-form-item>
            <el-form-item class="col-12 col-md-6" :label="getMessageByCode('address') ?? 'Address ' + '3'">
              <el-input v-model="contact.address3"></el-input>
            </el-form-item>
            <el-form-item class="col-12 col-md-6" :label="getMessageByCode('postcode') ?? 'Postcode'">
              <el-input v-model="contact.postCode"></el-input>
            </el-form-item>
            <el-form-item class="col-12 col-md-6" :label="getMessageByCode('city') ?? 'City'">
              <el-input v-model="contact.city"></el-input>
            </el-form-item>
            <el-form-item class="col-12 col-md-6" :label="getMessageByCode('region') ?? 'Region'">
              <el-input v-model="contact.region"></el-input>
            </el-form-item>
          </div>
        </el-form>
      </template>
      <template #footer>
        <div class="d-flex justify-content-end">
          <el-button bg text @click="addDialog = false">{{ getMessageByCode('cancel') ?? 'Cancel' }}</el-button>
          <el-button type="primary" v-if="contact.id < 1" @click="addContact()">
            {{ getMessageByCode('add') ?? 'Add' }}
          </el-button>
          <el-button type="primary" v-else @click="update()">{{ getMessageByCode('update') ?? 'Update' }}
          </el-button>
        </div>
      </template>
    </el-dialog>
    <el-dialog v-model="addCategoryDialog" width="400" :title="contactCategory.id > 0 ? 'Add Category' : 'Add Category'"
               draggable
               align-center>
      <template #default>
        <el-form label-position="top">
          <el-form-item class="col-12" :label="getMessageByCode('title') ?? 'Title'">
            <el-input v-model="contactCategory.title"></el-input>
          </el-form-item>
          <el-form-item class="col-12" :label="getMessageByCode('sortOrder') ?? 'Sort Order'">
            <template #label>
              <div class="d-flex justify-content-between align-items-center">
                <span>{{ getMessageByCode('sortOrder') ?? 'Sort Order' }}</span>
                <el-input class="w-50" type="number" v-model="contactCategory.order"></el-input>
              </div>
            </template>
          </el-form-item>

        </el-form>
      </template>
      <template #footer>
        <div class="d-flex justify-content-end">
          <el-button bg text @click="addCategoryDialog = false">{{ getMessageByCode('cancel') ?? 'Cancel' }}</el-button>
          <el-button type="primary" v-if="contactCategory.id < 1" @click="addCategory()">
            {{ getMessageByCode('add') ?? 'Add' }}
          </el-button>
          <el-button type="primary" v-else @click="updateCategory()">{{
              getMessageByCode('updateType') ?? 'Update Type'
            }}
          </el-button>
        </div>
      </template>
    </el-dialog>

    <n-spin v-if="loading" size="small" stroke="#000" :stroke-width="15" description="Loading"
            class="cstm-spin"></n-spin>
  </div>
</template>
<script>
import ToolBar from "@/components/Layout/ToolBar.vue";
import {getLanguage} from "@/Utility/getLanguage";
import clientService from "@/Services/ComponentsServices/clientService";
import {NSpin} from "naive-ui";
import {ShowMessage} from "@/Utility/Utility";
import addCompliance from "@/components/ComponentsDialogs/AddCompliance.vue";
import configService from "@/Services/ComponentsServices/configService";
import moment from "moment";
import contactService from "@/Services/ComponentsServices/contactService";
import optionService from "@/Services/ComponentsServices/optionService";
import {NButtonGroup} from "naive-ui";

export default {
  name: "CustomersList",
  computed: {
    addCompliance() {
      return addCompliance
    }
  },
  components: {NButtonGroup, NSpin, ToolBar},
  data() {
    return {
      loading: false,
      addDialog: false,
      addCategoryDialog: false,
      deleteTypeDialog: false,
      syncDialog: false,
      unlinkDialog: false,
      deleteDialog: false,
      contactCreate: false,
      customerDelete: true,
      profileDialog: false,
      selectedIds: [],
      translations: [],
      contactCategory: {
        "id": 0,
        "title": "",
        "order": 0,
        "clientId": sessionStorage.getItem("clientId") ?? 0,
      },
      categories: [],
      clients: [],
      contacts: [],
      syncModel: {
        appUserId: "",
        customerId: 0,
      },
      appUsers: [],
      dateFormat: "DD/MM/YYYY",
      contact: {
        "id": 0,
        "title": "",
        "firstName": "",
        "surname": "",
        "address1": "",
        "address2": "",
        "address3": "",
        "postCode": "",
        "region": "",
        "city": "",
        "telephone": "",
        "mobile": "",
        "fax": "",
        "email": "",
        "categoryId": 0,
        "clientId": sessionStorage.getItem("clientId"),
      },
      honorifics: [],
      clientId: Number(sessionStorage.getItem("clientId")) ?? 0,
      filter: {
        clientId: Number(sessionStorage.getItem("clientId") ?? 0),
        providerId: 1,
        search: "",
        sort: "",
        pageSize: 10,
        currentPage: 1,
        totalCount: 0,
        status: -1,
        clientGuid: 0,
        category: 0,
      },
    }
  },
  methods: {
    getFormattedDate(date) {
      if (date === null || date === undefined) {
        return "";
      }
      return moment.utc(date).local().format(this.dateFormat);
    },
    takeRow(contact, type) {
      this.contact = JSON.parse(JSON.stringify(contact));
      if (this.contact.appUserId === 0) {
        this.contact.appUserId = "";
      }
      switch (type) {
        case 'edit':
          this.addDialog = true;
          break;
        case 'delete':
          this.deleteDialog = true;
          break;

      }
    },
    async addContact() {

      this.loading = true;

      if(this.contact.categoryId < 1){
        ShowMessage("warning", "Please select a category first");
        this.loading = false;
        return;
      }
      if (!this.contact.email || !this.contact.firstName){
        ShowMessage("warning", "Please input the required fields, (marked with *).");
        this.loading = false;
        return;
      }
      try {
        let response = await contactService.add(this.contact);

        if (response?.data.success) {
          ShowMessage("success", "Contact Added");
          await this.getContacts();
          this.addDialog = false;
        } else {
          ShowMessage("error", response?.data.message ?? "Contact adding failed");
        }
      } catch (e) {
        ShowMessage("error", e?.data?.message ?? "Add Contact Failed");
      }
      this.loading = false;
    },
    async addCategory() {
      this.addCategoryDialog = false;
      try {
        let response = await contactService.addType(this.contactCategory);
        if (response?.data.success) {
          ShowMessage("success", "Category Added");
          await this.getCategories(response?.data?.category.id);
          this.addCategoryDialog = false;
        } else {
          ShowMessage("error", response?.data.message ?? "Category adding failed");
        }
      } catch (e) {
        ShowMessage("error", e?.data?.message ?? "Add Category Failed");
      }
    },
    async updateCategory() {
      this.addCategoryDialog = false;
      try {
        let response = await contactService.updateType(this.contactCategory);

        if (response?.data.success) {
          ShowMessage("success", "Category Updated");
          await this.getCategories();
          this.addCategoryDialog = false;
        } else {
          ShowMessage("error", response?.data.message ?? "Category updated failed");
        }
      } catch (e) {
        ShowMessage("error", e?.data?.message ?? "Category Updated Failed");
      }
    },
    async deleteCategory(typeId) {
      this.deleteTypeDialog = false;
      try {
        let response = await contactService.deleteType(typeId);
        if (response.data.success === 200) {
          ShowMessage("success", response?.data?.message ?? "Category deleted");
          await this.getCategories();
          this.deleteTypeDialog = false;
        } else {
          ShowMessage("error", response?.data.message ?? "Category deleted failed");
        }
      } catch (e) {
        ShowMessage("error", e?.data?.message ?? "Category deleted Failed");
      }
    },
    handleSelectionChange(selection) {
      this.selectedIds = selection.map(obj => obj.id);
    },

    handleSizeChange(newSize) {
      this.filter.pageSize = newSize;
      this.getContacts();
    }, handleCurrentChange(newPage) {
      this.filter.currentPage = newPage;
      this.getContacts();
    },
    async getContacts() {
      this.loading = true;
      try {
        let response = await contactService.all(this.filter);
        this.contacts = response?.data?.items ?? [];
      } catch (e) {
        ShowMessage("error", e?.response?.message ?? "Something went wrong")
      }
      this.loading = false;
    },
    async getClients() {
      try {
        this.loading = true;
        if (Number(sessionStorage.getItem('clientId')) === null || Number(sessionStorage.getItem('clientId')) === 0) {
          let response = await clientService.getAll();
          this.clients = response.data.items ?? [];
          if (this.clients.length > 0) {
            this.filter.clientGuid = this.clients[0].clientGuid;
            this.contact.clientId = this.clients[0].id;
            this.filter.clientId = this.clients[0].id;
          }
        }
        await this.getCategories();
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    },
    async update() {
      this.loading = true;
      this.addDialog = false;
      try {
        let response = await contactService.update(this.contact);
        if (response?.data?.id > 0) {
          ShowMessage("success", "Contact Updated")
          await this.getContacts();
        }
      } catch (e) {
        console.log(e)
      }
      this.loading = false;
    },

    async deleteContact() {
      this.loading = true;
      this.deleteDialog = false;
      try {
        let response = await contactService.delete(this.contact.id);
        if (response?.data) {
          ShowMessage("success", "Contact Deleted");
          await this.getContacts();
        }
      } catch (e) {
        ShowMessage("error", "Contact Unlink failed");
        console.log(e)
      }
      this.loading = false;
    },
    async getCategories(id) {
      this.loading = true;
      try {
        let response = await contactService.getAllCategories(this.clientId);
        this.categories = [
          {id: 0, title: 'All'}
        ]
        this.categories = this.categories.concat(response?.data ?? [])
        if (this.categories.length > 0) {
          if (id !== undefined) {
            this.contact.categoryId = id;
          } else {
            this.contact.categoryId = this.categories[0].id;
          }
        }
      } catch (e) {
        console.log(e)
      }
      this.loading = false;
      await this.getContacts();
    },
    getMessageByCode(code) {
      // Find the object with the matching code
      if (this.translations == null) {
        this.translations = getLanguage();
        return;
      }
      const matchingObject = this.translations.find(obj => obj.code === code);
      return matchingObject ? matchingObject.message : null;
    },
    async getConfigs() {
      try {
        this.loading = true;
        let id = 0;
        if (this.clientId === null) {
          id = 0;
        } else {
          id = this.clientId;
        }

        let response3 = await configService.getByKey(id, "DateFormat");
        this.dateFormat = response3.data.value ?? "DD/MM/YYYY";
        this.loading = false;
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
    resetContact() {
      this.contact = {
        "id": 0,
        "title": this.honorifics[0]?.optTitle ?? "",
        "firstName": "",
        "surname": "",
        "address1": "",
        "address2": "",
        "address3": "",
        "postCode": "",
        "telephone": "",
        "mobile": "",
        "fax": "",
        "email": "",
        "categoryId": this.categories[1]?.id,
        "clientId": sessionStorage.getItem("clientId"),
      };
      if (Number(sessionStorage.getItem('clientId')) === null || Number(sessionStorage.getItem('clientId')) === 0) {
        if (this.clients.length > 0) {
          this.contact.clientId = this.clients[0].id;
        }
      }
    },
    async getListOptions() {
      try {
        this.loader = true;
        let response = await optionService.getOptionsByHeaderId(0, 43)
        this.honorifics = response.data ?? {};
        if (this.honorifics.length > 0) {
          if (this.contact.title === "") {
            this.contact.title = this.honorifics[0].optTitle;
          }
        }
        this.loader = false;
      } catch (e) {
        console.log(e);
        ShowMessage("error", "Something went wrong!");
        this.loader = false;
      }
    },
    checkContact(id) {
      let contact = this.contacts.find(x => x.id === id);
      if (contact !== undefined && contact !== null) {
        this.contact = JSON.parse(JSON.stringify(contact));
        this.addDialog = true;
      }
    }
  },
  async created() {
    await this.getClients();
    await this.getListOptions();
    const {fromTenancy, pageId} = this.$route.query;

    if (fromTenancy && pageId > 0) {
      this.checkContact(Number(pageId));
    }
  }
}
</script>

<style scoped>
.el-form-item {
  margin-bottom: 5px !important;
  margin-top: 5px !important;
}

.el-select__wrapper.is-focused {
  box-shadow: #2A5699 !important;
}
</style>