<template>
  <div class="container-fluid p-2 p-md-0 main-wrapper" style="height: 100vh; overflow: hidden"
       :style="{ backgroundImage: 'url(' + imageUrl + ')' }">
    <div class="row login-wrapper h-100  justify-content-center align-items-center">
      <div class="col-12 col-md-4 bg-white py-4 shadow  rounded text-center">
        <div class="mb-3">
          <div class="logo py-4">
            <img :src="logo" alt="logo" width="150">
          </div>
          <h3 class="fw-bold">Forgotten Password?</h3>
          <span>Enter your username or email below to request a change to your password.</span>
        </div>
        <div class="d-flex flex-column align-items-center justify-content-center py-3">
          <div class="w-100 px-3">
            <div class="text-start">
              <label class="required">Username</label>
              <el-input
                  v-model="username"
                  class="inpt-bg w-100"
                  size="large"
                  tabindex="1"

              ></el-input>
            </div>
            <div class="mt-5">
              <el-button
                  :disabled="loading || (!username)"
                  :loading="loading"
                  class="mbtn-primary rounded-1 w-100"
                  size="large"
                  @click="resetPassword"
              >{{ loading ? "Requesting" : "Request password change" }}
              </el-button
              >
            </div>
            <el-divider>OR</el-divider>
            <el-form-item>
              <router-link to="/" class="w-100">
                <el-button bg class="btn btn-light w-100" size="large" text>
                  <span class="ms-2">Login</span>
                </el-button>
              </router-link>
            </el-form-item>
          </div>
        </div>
        <div class="text-center text-small">
                    <span>
                      <span>&#169;</span><span>Light CRM</span> <span>{{ new Date().getFullYear() }}</span>
                    </span>
        </div>
      </div>
    </div>
    <div v-if="loading" class="spinner-border signup-loader"></div>
  </div>
</template>
<script>
import loginServices from "@/Services/loginServices";
import {ShowMessage} from "@/Utility/Utility";
import configService from "@/Services/ComponentsServices/configService";

export default {
  name: "ForgotPassword",
  data() {
    return {
      imageUrl: "",
      loading: false,
      username: "",
      logo: "",

    }
  },
  methods: {
    async resetPassword() {
      try {
        this.loading = true;
        let response = await loginServices.resetPassword(this.username);
        if (response.status == 200) {
          ShowMessage("success", "Password sent to your email.");
        } else {
          ShowMessage("error", "Something went wrong");
        }
        this.loading = false;
      } catch (e) {
        this.loading = false;
        ShowMessage("error", "Something went wrong");
        console.log(e.message)
      }
    },
    async getLogo() {
      let logo = await configService.getByKey(0, "LOGO");
      this.logo = logo.data.value
    }
  },
  created() {
    this.getLogo();
    configService.getByKey(0, "LoginBackground").then((res) => {
      this.imageUrl = res.data.value;
    });

  }
}
</script>

<style scoped>
.main-wrapper {
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>