<template>
  <div class="container-fluid">
    <ToolBar :ShowAddBtn="docCreate" :options="SelectOptions" :show-bread="false" :showSelect="true"
             :btnText="getMessageByCode('add') ?? 'Add'" clear-id="clientId"
             @selection-changed="getDocuments"
             @addbtn-clicked="addDocumentD = true; resetDocument()">
      <template v-slot:select>
        <el-select v-if="clientId < 1" class="w-100" filterable v-model="filter.clientId" @change="ChangeClient">
          <el-option v-for="item in Clients"
                     :key="item.id"
                     :label="item.name"
                     :value="item.id">
            <template #default>
              <div class="row">
                <span class="col-7">{{ item.name }}</span>
                <span class="text-small col-5">{{ item.market }}</span>
              </div>
            </template>
          </el-option>
        </el-select>
      </template>
    </ToolBar>
    <div class="container-fluid p-0">
      <div class="row">
        <!--        <div class="col-12 col-md-8">-->
        <!--          <div class="d-flex justify-content-end">-->
        <!--            <el-button class="bg-danger text-white fw-normal me-2 border-0"-->
        <!--                       @click="this.$store.state.allDeleteDialog = !this.$store.state.allDeleteDialog"-->
        <!--                       v-if="this.$store.state.selectedIds.length > 0">-->
        <!--              <i class="bi bi-trash me-2"></i>-->
        <!--              {{ selectedRows.length }} selected-->
        <!--            </el-button>-->
        <!--          </div>-->
        <!--        </div>-->
        <div class="col-12 col-md-4 col-lg-4 mb-2 ms-auto d-flex justify-content-end">
          <el-input class="align-self-end" :placeholder="getMessageByCode('search') ?? 'Search here...'"
                    @clear="getDocuments" v-model="filter.search"
                    @keyup="getDocuments"
                    clearable>
            <template #append>
              <el-button @click="getDocuments">
                <template #icon>
                  <i class="bi bi-search"></i>
                </template>
              </el-button>
            </template>
          </el-input>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <el-table :data="Documents"
                    :fit="true" :flexible="true" :selectable="(row) => row.status !== 'disabled'" border
                    header-cell-class-name="tbl-header"
                    stripe @selection-change="handleSelectionChange">
            <template #append>
              <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :total="filter.totalCount"
                  :page="filter.currentPage"
                  :page-size="filter.pageSize"
                  :page-sizes="[10, 20, 30, 40]"
                  layout=" prev, pager, next,sizes"
                  class="bg-gray p-2"
                  background
                  size="small"
              >
              </el-pagination>
            </template>
            <!--            <el-table-column align="center" type="selection" width="55"/>-->
            <el-table-column sortable :label="getMessageByCode('name')??'Name'" prop="documentFileName">
            </el-table-column>
            <el-table-column sortable :label="getMessageByCode('ext')??'Extension'" width="120" prop="documentFileExtension"/>
            <el-table-column sortable :label="getMessageByCode('size')??'Size'" class="text-end" prop="documentSize" width="120">
              <template #default="scope">
                <div class="text-end">
                  <span class="text-end">{{ size(scope.row.documentSize) }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column sortable :label="getMessageByCode('category')??'Category'" width="150" prop="category"/>
            <el-table-column align="center" fixed="right" :label="getMessageByCode('actions')??'Actions'" width="120">
              <template #default="scope">
                <el-space alignment="center" spacer="|">
                  <i v-if="docDel" @click="downloadDocument(scope.row)"
                     class="mgc_download_2_line text-primary action-btn"
                  ></i>
                  <i v-if="docDownload" @click="deleteDoc(scope.row.id)"
                           class="mgc_delete_2_line text-danger action-btn"
                ></i>
                </el-space>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>


    <!--    <AddDocument/>-->
    <!--    Add Dialog-->
    <el-dialog
        v-model="addDocumentD"
        :title="getMessageByCode('add')??'Add'+' '+getMessageByCode('document')??'Document'"
        width="30%"
    >
      <div>
        <div v-if="clientId < 1">
          <el-form>
            <el-form-item v-if="Document.clinetId == null">
              <label>{{ getMessageByCode('client') ?? 'Client' }}</label>
              <el-select v-model="Document.clientId" filterable class="w-100" placeholder="Select" size="large">
                <el-option
                    v-for="item in Clients"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                    style="font-weight: normal; color: #0a0a0a"
                />
              </el-select>
            </el-form-item>
          </el-form>
        </div>
        <div>
          <label>{{ getMessageByCode('title') ?? 'Title' }}</label>
          <el-form-item>
            <el-input v-model="Document.name" type="text">
            </el-input>
          </el-form-item>
        </div>
        <div>
          <label>{{ getMessageByCode('category') ?? 'Category' }}</label>
          <el-form-item>
            <el-select v-model="Document.categoryId">
              <el-option v-for="item in categories"
                         :key="item.optId"
                         :label="item.optTitle"
                         :value="item.optId"></el-option>
            </el-select>
          </el-form-item>
        </div>

        <div>
          <label>{{ getMessageByCode('document') ?? 'Document' }}</label>
          <el-form-item>
            <el-input v-model="Document.documentFileName">
              <template #prepend>
                <el-upload :auto-upload="false" :on-change="handleFileChange" :show-file-list="false"
                           class="mb-0">
                  <template #trigger>
                    <el-button type="primary">{{ getMessageByCode('select_file') ?? 'select file' }}</el-button>
                  </template>
                </el-upload>
              </template>
            </el-input>
            <div class="w-100 d-flex justify-content-end">
              <el-space>
                <span class="custom-caption">{{ Document.documentSize }}</span>
                <span class="custom-caption">{{ Document.documentFileExtension }}</span>
              </el-space>
            </div>
          </el-form-item>
        </div>
      </div>
      <template #footer>
          <span class="dialog-footer">
            <el-button text bg @click="addDocumentD = false">{{ getMessageByCode('cancel') ?? 'Cancel' }}</el-button>
            <el-button type="primary" class="text-capitalize" @click="addDocumentD = false, AddDocument()">
            {{ getMessageByCode('add_doc') ?? 'Add Document' }}
            </el-button>
          </span>
      </template>
    </el-dialog>


    <DeleteMultiple entity-name="Documents" resource="documentId" slug="document"
                    @deleteSuccess="getDocuments"></DeleteMultiple>
    <DeleteEntity :selected-rows="selectedRows" resource="documentId" slug="documentId" title="Document"
                  @deleteSuccess="getDocuments"/>


    <div v-if="loading" class="spinner-border"></div>
  </div>
</template>

<script>
import ToolBar from "@/components/Layout/ToolBar";
import DeleteEntity from "@/components/UI/DeleteEntity";
import DeleteMultiple from "@/components/UI/DeleteMultiple";
import store from "@/store";
import SizeCalc from "@/components/Services/SizeCalc";
import documentService from "@/Services/ComponentsServices/documentService";
import clientService from "@/Services/ComponentsServices/clientService";
import {checkPermission} from "@/Utility/CheckPermission";
import {ShowMessage} from "@/Utility/Utility";
import {getLanguage} from "@/Utility/getLanguage";
import optionService from "@/Services/ComponentsServices/optionService";

export default {
  name: "DocumentsList",
  components: {DeleteMultiple, ToolBar, DeleteEntity},
  data() {
    return {
      clientId: sessionStorage.getItem("clientId"),
      addDocumentD: false,
      docCreate: false,
      docView: false,
      docDownload: false,
      docDel: false,
      docEdit: false,
      edit: false,
      Documents: [],
      client: sessionStorage.getItem('clientId'),
      Clients: [{
        id: 0,
        name: "Super Admin"
      }],
      selectedRows: [],
      translations: [],
      loading: false,
      categories: [],
      Document: {
        "id": 0,
        "categoryId": 0,
        "name": "",
        "extension": "",
        "mimeType": "",
        "docObject": "",
        "docObjectArr": "",
        "size": 0,
        "parentId": 0,
        "typeId": 0,
        "clientId": sessionStorage.getItem("clientId"),
      },
      SelectOptions: [{label: "All", value: 1}, {label: "Active", value: 2}, {label: "InActive", value: 3}],
      filter: {
        clientId: sessionStorage.getItem("clientId"),
        providerId: 1,
        search: "",
        sort: "",
        pageSize: 10,
        currentPage: 1,
        totalCount: 0,
      },
    }
  },
  methods: {

    resetDocument() {
      this.Document = {
        "id": 0,
        "categoryId": this.categories[0].optId,
        "name": "",
        "extension": "",
        "mimeType": "",
        "docObject": "",
        "docObjectArr": "",
        "size": 0,
        "parentId": 0,
        "typeId": 0,
        "clientId": sessionStorage.getItem("clientId"),
      };
    },
    deleteDoc(id) {
      console.log(id)
    },
    size(size) {
      if (size === undefined || size === null) {
        return "";
      }
      return SizeCalc.FormattedSize(size);
    },
    ChangeClient() {
      this.getDocuments();
    },
    handleSelectionChange(selection) {
      this.selectedRows = selection;
    },
    editProperty(id) {
      store.state.pageId = id;
      this.$router.push('/property');
    },
    handleSizeChange(newSize) {
      this.filter.pageSize = newSize;
      this.getDocuments();
    }, handleCurrentChange(newPage) {
      this.filter.currentPage = newPage;
      this.getDocuments();
    },
    async AddDocument() {
      try {
        this.loading = true;
        let response = await documentService.addDocument(this.Document);

        if (response.status === 200) {
          ShowMessage("success", "Document uploaded");
          await  this.getDocuments();
        }
        this.loading = false;
      } catch (e) {
        this.loading = false;
        ShowMessage("error", "Something went wrong!");
      }
    },
    async getDocuments() {
      try {
        if (this.filter.clientId > 0) {
          this.loading = true;
          let response = await documentService.all(this.filter);
          this.Documents = response.data.items ?? [];
          this.filter.totalCount = response.data.totalCount ?? 0;
          this.filter.currentPage = response.data.currentPage ?? response.data.currentPage === 0 ? 1 : response.data.currentPage || 1;
          this.filter.pageSize = response.data.pageSize;

          this.loading = false;
        }
      } catch (e) {
        console.log(e);
        this.$store.state.loading = false;
      }
    },

    downloadDocument(doc) {
      if (!doc || !doc.documentObject) {
        console.error("Invalid attachment data");
        return;
      }

      try {
        // Ensure the doc.documentObject is a valid hexadecimal string
        const hexString = doc.documentObject;

        // Convert the hex string back to binary data
        const byteArray = new Uint8Array(hexString.length / 2);
        for (let i = 0; i < hexString.length; i += 2) {
          byteArray[i / 2] = parseInt(hexString.substr(i, 2), 16);
        }

        // Create a Blob from the binary data
        const blob = new Blob([byteArray], { type: doc.documentMimeType });

        // Create a download link
        const downloadLink = document.createElement("a");
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.download = doc.documentFileName || "Document";

        // Trigger the download
        downloadLink.click();

        // Revoke the object URL to free up memory
        URL.revokeObjectURL(downloadLink.href);
      } catch (error) {
        console.error("Failed to download the document:", error);
      }
    }
    ,
    async getClients() {
      this.loading = true;
      try {
        if (sessionStorage.getItem("clientId") == null || Number(sessionStorage.getItem("clientId")) === 0) {
          this.loading = true;
          let response = await clientService.getAll();
          this.Clients = this.Clients.concat(response.data.items ?? []);
          if (this.Clients.length > 0) {
            this.filter.clientId = this.Clients[0].id;
          }
        }
        await this.getDocuments();
      } catch (e) {
        console.log(e);
        this.$store.state.loading = false;
      }
      this.loading = false;
    },
    handleFileChange(file) {
      const selectedFile = file.raw;
      this.Document.documentFileName = file.name;
      this.Document.size = file.raw.size;
      this.Document.extension = file.raw.name.split('.').pop();
      this.Document.mimeType = selectedFile.type;

      // Convert file to hexadecimal string
      this.returnDocObject(selectedFile, (hexString) => {
        this.Document.docObjectArr = hexString;

        // Now you can send this.Document to your API
        console.log("Document ready to upload:", this.Document);
      });
    },

    returnDocObject(file, callback) {
      const reader = new FileReader();
      reader.onload = () => {
        const byteArray = new Uint8Array(reader.result);

        // Convert byte array to hexadecimal string
        let hexString = '';
        for (let i = 0; i < byteArray.length; i++) {
          hexString += byteArray[i].toString(16).padStart(2, '0'); // Convert each byte to 2 hex digits
        }

        callback(hexString); // Return the hexadecimal string
      };

      reader.readAsArrayBuffer(file); // Read the file as binary data
    },
    getMessageByCode(code) {
      // Find the object with the matching code
      if (this.translations == null) {
        this.translations = getLanguage();
        return;
      }
      const matchingObject = this.translations.find(obj => obj.code === code);
      return matchingObject ? matchingObject.message : null;
    },
    async getDocumentCategories() {
      try {
        this.loading = true;
        let response = await optionService.getOptionsByKey(this.clientId, "DocumentCategories");

        this.categories = response.data ?? [];
        if (this.categories.length > 0) {
          if (this.Document.categoryId < 1) {
            this.Document.categoryId = this.categories[0].optId;
          }
        }

      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    },
  },
  created() {
    this.getDocumentCategories();
    this.translations = getLanguage();
    this.docCreate = checkPermission("MD_DOCUMENT_CREATE")
    this.docView = checkPermission("MD_DOCUMENT_VIEW")
    this.docDel = checkPermission("MD_DOCUMENT_DEL")
    this.docDownload = checkPermission("MD_DOCUMENT_DOWNLOAD")
    this.getClients();
  }
}
</script>

<style scoped>

</style>