<template>
  <div class="container-fluid">
    <ToolBar :showBread="false" :showSelect="false" :ShowAddBtn="false"/>
    <div class="row">
      <div class="row justify-content-between pe-0">
        <div class="col-12 col-md-5" v-if="clientId < 1">
          <div class="d-flex">
            <el-select v-if="clientId < 1" class="w-100" filterable
                       v-model="filter.clientId"
                       @change="ChangeClient">
              <el-option v-for="item in Clients"
                         :key="item.id"
                         :label="item.name"
                         :value="item.id">
                <template #default>
                  <div class="row">
                    <span class="col-7">{{ item.name }}</span>
                    <span class="text-small col-5">{{ item.market }}</span>
                  </div>
                </template>
              </el-option>
            </el-select>
            <el-select filterable v-model="filter.headerId" placeholder="Select header..."
                       @change="ChangeHeader" :class="clientId < 1 ? 'ms-2' : 'ms-0'">
              <el-option
                  v-for="item in OptionHeaders"
                  :key="item.id"
                  :label="item.title"
                  :value="item.id"
              />
            </el-select>
            <el-tooltip content="Add Option Header" v-if="clientId == null || Number(clientId) === 0">
              <el-button type="primary" class="ms-2" @click="addHeaderDialog = true">
                <i class="bi bi-plus"></i>
              </el-button>
            </el-tooltip>
            <el-tooltip content="Copy List Options" v-if="clientId == null || Number(clientId) === 0">
              <el-button type="primary" class="ms-2" @click="copyOptionsDialog = true">
                <i class="bi bi-copy"></i>
              </el-button>
            </el-tooltip>
          </div>
        </div>
        <div class="col-12 col-md-3" v-else>
          <div class="d-flex">
            <el-select filterable v-model="filter.headerId" placeholder="Select header..."
                       @change="ChangeHeader" :class="clientId < 1 ? 'ms-2' : 'ms-0'">
              <el-option
                  v-for="item in OptionHeaders"
                  :key="item.id"
                  :label="item.title"
                  :value="item.id"
              />
            </el-select>
          </div>
        </div>
        <div class="col-12 col-md-3 d-flex justify-content-end pe-0 mt-2 mt-md-0">
          <el-input type="text" class="w-100" :placeholder="getMessageByCode('search') ??'Search here...'">
            <template #append>
              <el-button>
                <i class="bi bi-search"></i>
              </el-button>
            </template>
          </el-input>
        </div>
      </div>
      <div class="mt-2">
        <el-table :data="Options" header-cell-class-name="tbl-header">
          <template #append>
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :total="filter.totalCount"
                :page="filter.currentPage"
                :page-size="filter.pageSize"
                :page-sizes="[10, 20, 30, 40]"
                layout=" prev, pager, next,sizes"
                class="bg-gray p-2"
                background
                size="small"
            ></el-pagination>
          </template>
          <el-table-column sortable :label="getMessageByCode('value') ??'Value'" prop="optValue" min-width="250">
            <el-table-column min-width="250">
              <template #header>

                <el-input v-if="optionCreate && Option.optId === 0" placeholder="Type here..." type="text"
                          v-model="Option.optValue"></el-input>
                <el-input v-if="optionEdit && Option.optId > 0" placeholder="Type here..." type="text"
                          v-model="Option.optValue"></el-input>

              </template>
              <template #default="scope">
                <div style="min-width: 150px !important;">
                  {{ scope.row.optValue }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column sortable :label="getMessageByCode('title') ??'Title'" prop="optTitle" min-width="250">
            <el-table-column min-width="250">
              <template #header>
                <el-input v-if="optionCreate && Option.optId === 0" placeholder="Type here..." type="text"
                          v-model="Option.optTitle"></el-input>
                <el-input v-if="optionEdit && Option.optId > 0" placeholder="Type here..." type="text"
                          v-model="Option.optTitle"></el-input>
              </template>
              <template #default="scope">
                <div v-if="scope.row.optTitle.length < 100" style="min-width: 150px !important;">
                  {{ scope.row.optTitle }}
                </div>
                <div v-else style="min-width: 150px !important;">
                  <img :src="scope.row.optTitle" alt="value" width="25"/>
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column sortable :label="getMessageByCode('sort_order') ??'Sort Order'" prop="optSortOrder"
                           min-width="250">
            <el-table-column min-width="250">
              <template #header>
                <el-input v-if="optionCreate && Option.optId === 0" placeholder="Type here..."
                          v-model.number="Option.optSortOrder" type="number"></el-input>
                <el-input v-if="optionEdit && Option.optId > 0" placeholder="Type here..."
                          v-model.number="Option.optSortOrder" type="number"></el-input>
              </template>
              <template #default="scope">
                <div style="min-width: 150px !important;" class="text-end">
                  {{ scope.row.optSortOrder }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column :label="getMessageByCode('action') ??'Action'" fixed="right" min-width="240" align="center">
            <el-table-column fixed="right" min-width="180">
              <template #header>
                <el-space>
                  <el-button v-if="optionCreate && Option.optId === 0" :disabled="!Option.optTitle" class="px-2"
                             type="primary"
                             @click="AddOption">
                    <span class="mgc_add_line me-1"></span>
                    {{ getMessageByCode('add') ?? 'Add' }}
                  </el-button>
                  <el-button v-if="optionEdit && Option.optId > 0" :disabled="!Option.optTitle" class="px-2"
                             type="primary"
                             @click="UpdateOption">
                    <span class="mgc_add_line me-1"></span>
                    {{ getMessageByCode('update') ?? 'Update' }}
                  </el-button>
                  <el-button v-if="Option.optTitle.length > 0 || Option.optTitle.length > 0" @click="resetDdOption"
                             class="bg-warning text-white ps-2 pe-2">
                    <span class="bi bi-x-octagon-fill me-2"></span>
                    {{ getMessageByCode('cancel') ?? 'Cancel' }}
                  </el-button>
                </el-space>
              </template>
              <template #default="scope">
                <el-space>
                  <button v-if="optionEdit" @click="takeData(scope.row)" class="btn btn-sm btn-success">
                    <span class="bi bi-pencil-square me-2"></span>
                    {{ getMessageByCode('edit') ?? 'Edit' }}
                  </button>
                  <button v-if="optionDel" class="btn btn-sm btn-danger" @click="deleteOption(scope.row)">
                    <span class="mgc_delete_2_line me-1"></span>
                    {{ getMessageByCode('delete') ?? 'Delete' }}
                  </button>
                </el-space>
              </template>
            </el-table-column>

          </el-table-column>
        </el-table>
      </div>
    </div>


    <el-dialog v-model="addHeaderDialog" width="350">
      <template #header>
        <span>{{ getMessageByCode('optionHeader') ?? 'Option Header' }}</span>
      </template>
      <template #default>
        <el-form label-position="top">
          <el-form-item v-if="clientId < 1" :label="getMessageByCode('client') ?? 'Client'">
            <el-select v-model="optionHeader.clientId">
              <el-option
                  v-for="item in Clients"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item :label="getMessageByCode('optionHeader') ?? 'Option Header'">
            <el-input @keydown.space.prevent v-model="optionHeader.optHeaderTitle"></el-input>
          </el-form-item>
        </el-form>
      </template>
      <template #footer>
        <div class="d-flex justify-content-end">
          <el-button text bg @click="addHeaderDialog = false">
            Cancel
          </el-button>
          <el-button type="primary" class="ms-2" @click="addHeader()">
            Add
          </el-button>
        </div>
      </template>
    </el-dialog>
    <el-dialog v-model="copyOptionsDialog" width="400" :title="getMessageByCode('copyListOptions') ?? 'Copy List Options'">
      <template #default>
        <el-form-item label-position="top" :label="getMessageByCode('client') ?? 'Client'">
          <el-select placeholder="Select Customer" v-model="copyModel.copyTo">
            <el-option
                v-for="client in Clients.filter(client => client.name !== 'Super Admin')"
                :label="client.name"
                :key="client.id"
                :value="client.id"
            >
              <template #default>
                <div class="row">
                  <span class="col-7">{{ client.name }}</span>
                  <span class="text-small col-5">{{ client.market }}</span>
                </div>
              </template>
            </el-option>

          </el-select>
        </el-form-item>
      </template>
      <template #footer>
        <div class="d-flex justify-content-end">
          <el-button text bg @click="copyOptionsDialog = false">
            {{ getMessageByCode('cancel') ?? 'Cancel' }}
          </el-button>
          <el-button type="primary" class="ms-2" @click="copyOptions()">
            {{ getMessageByCode('copy') ?? 'Copy' }}
          </el-button>
        </div>
      </template>
    </el-dialog>


    <div v-if="loading" class="spinner-border"></div>

  </div>
</template>
<script>
import ToolBar from "@/components/Layout/ToolBar.vue";
import optionService from "@/Services/ComponentsServices/optionService";
import clientService from "@/Services/ComponentsServices/clientService";
import {ShowMessage} from "@/Utility/Utility";
import {checkPermission} from "@/Utility/CheckPermission";
import {getLanguage} from "@/Utility/getLanguage";

export default {
  name: "SettingsComp",
  components: {ToolBar},
  data() {
    return {
      loading: false,
      addHeaderDialog: false,
      copyOptionsDialog: false,
      clientId: sessionStorage.getItem("clientId"),
      addBtnText: "Add",
      optionHeader: {
        "clientId": sessionStorage.getItem("clientId") ?? 0,
        "optHeaderId": 0,
        "optHeaderTitle": ""
      },
      optionCreate: false,
      optionView: false,
      optionEdit: false,
      optionDel: false,
      edit: true,
      translations: [],
      OptionHeaders: [],
      Options: [],
      copyModel: {
        copyTo: "",
        copyFrom: "",
        optionHeader: ""
      },
      Clients: [{
        id: 0,
        name: "Super Admin"
      }],
      Option: {
        "optId": 0,
        "optValue": "",
        "optTitle": "",
        "fav_Icon": "",
        "optIdOptHeader": 0,
        "optSortOrder": 0,
        "optClass": "",
        "clientId": sessionStorage.getItem("clientId") ?? 0,
      },
      filter: {
        clientId: sessionStorage.getItem("clientId") ?? 0,
        providerId: 1,
        search: "",
        sort: "",
        pageSize: 10,
        currentPage: 1,
        totalCount: 0,
        headerId: "",
      },
      fileInputKey: Date.now(),
    }
  },
  methods: {
    handleFileChange(event) {
      this.fileInputKey = Date.now();
      const file = event.target.files[0];

      if (file) {
        // Use FileReader to read the image file as a data URL
        const reader = new FileReader();

        reader.onload = () => {

          this.Option.optTitle = reader.result; // Extract the base64-encoded string
        };

        reader.readAsDataURL(file);
      }

    },
    handleSizeChange(newSize) {
      this.filter.pageSize = newSize;
      this.getOptions();
    },
    handleCurrentChange(newPage) {
      this.filter.currentPage = newPage;
      this.getOptions();
    },
    takeData(row) {
      this.Option = {...row};
    },
    ChangeClient() {
      if (typeof this.Option === 'object' && typeof this.filter === 'object' && typeof this.filter.clientId !== 'undefined') {
        this.Option.clientId = this.filter.clientId;
      }
      this.getOptionHeaders();
    },
    ChangeHeader() {
      this.Option.optIdOptHeader = this.filter.headerId;
      this.getOptions();
    },
    resetDdOption() {
      this.Option.optId = 0;
      this.Option.optValue = "";
      this.Option.optTitle = "";
      this.Option.favIcon = "";
      this.Option.optIdOptHeader = this.filter.headerId;
      this.Option.optSortOrder = 0;
      this.Option.optClass = "";
      this.Option.clientId = this.filter.clientId;
      this.addBtnText = "Add";
    },
    async getOptionHeaders() {
      try {
        this.loading = true;
        let response = await optionService.getAllHeaders(this.filter.clientId);
        this.OptionHeaders = response.data.items;
        if (this.OptionHeaders.length > 0) {
          this.filter.headerId = this.OptionHeaders[0].id;
          this.Option.optIdOptHeader = this.OptionHeaders[0].id;
        }
        this.loading = false;
        await this.getOptions();
      } catch {
        this.loading = false;
      }
    },
    async addHeader() {
      this.loading = true;
      this.addHeaderDialog = false;
      try {


        // eslint-disable-next-line no-unreachable
        let response = await optionService.addOptionHeader(this.optionHeader);
        if (response.status === 200) {
          ShowMessage("success", "List option header added");
          await this.getOptionHeaders();
        }
      } catch (e) {
        console.log(e)
      }
      this.loading = false;
    },
    async getOptions() {
      try {
        this.loading = true;
        let response = await optionService.getOptions(this.filter);
        this.Options = response.data.items;
        this.filter.totalCount = response.data.totalCount ?? 0;
        this.filter.currentPage = response.data.currentPage ?? response.data.currentPage === 0 ? 1 : response.data.currentPage || 1;
        this.filter.pageSize = response.data.pageSize;
        this.loading = false;
      } catch {
        this.loading = false;
      }
    },
    async copyOptions() {
      this.loading = true;
      this.copyOptionsDialog = false;
      this.copyModel.optionHeader = this.filter.headerId;
      this.copyModel.copyFrom = this.filter.clientId;
      try {
        let response = await optionService.copyOptions(this.copyModel);
        if (response?.data?.success) {
          ShowMessage("success", response?.data?.message ?? "Options copied to the selected client");

          await this.getClients();
          await this.getOptionHeaders();

        }
      } catch (e) {
        ShowMessage("error", e?.data?.message ?? "Copying options failed");
      }
      this.loading = false;

    },
    AddOrUpdateOption() {
      switch (this.Option.optId) {
        case 0:
          this.AddOption();
          break;
        case null:
          this.Option.optId = 0;
          this.AddOption();
          break;
        default:
          if (this.Option.optId > 0) {
            this.UpdateOption();
          }
          break;
      }
    },
    async AddOption() {
      try {
        this.loading = true;

        // eslint-disable-next-line no-unused-vars,no-unreachable
        let response = await optionService.addOption(this.Option);
        this.resetDdOption();
        await this.getOptions();
        ShowMessage("success", "Option added successfully");
        this.loading = false;
      } catch {
        this.loading = false;
        ShowMessage("error", "Something went wrong!")
      }
    },
    async UpdateOption() {
      try {
        this.loading = true;
        // eslint-disable-next-line no-unused-vars
        let response = await optionService.updateOption(this.Option);
        this.resetDdOption();
        await this.getOptions();
        ShowMessage("success", "Option updated successfully");
        this.loading = false;
      } catch {
        this.loading = false;
        ShowMessage("error", "Something went wrong!")
      }
    },
    async deleteOption(row) {
      try {
        this.loading = true;
        // eslint-disable-next-line no-unused-vars
        let response = await optionService.deleteOption(row.optId)

        await this.getOptions();
        ShowMessage("success", "Option deleted successfully");
        this.loading = false;
      } catch {
        this.loading = false;
        ShowMessage("error", "Something went wrong!")
      }
    },
    async getClients() {
      try {
        this.loading = true;
        if (sessionStorage.getItem('clientId') == null || sessionStorage.getItem('clientId') == 0) {
          let response = await clientService.getAll();
          this.Clients = [{
            id: 0,
            name: "Super Admin"
          }];
          this.Clients = this.Clients.concat(response.data.items);

          if (this.Clients.length > 0) {
            this.filter.clientId = this.Clients[0].id;
            this.Option.clientId = this.Clients[0].id;
            this.optionHeader.clientId = this.Clients[0].id;
          }
        }
        await this.getOptionHeaders();
        this.loading = false;
      } catch {
        this.loading = false;
        this.$store.state.loading = false;
      }
    }
    ,
    getMessageByCode(code) {
      // Find the object with the matching code
      if (this.translations == null) {
        this.translations = getLanguage();
        return;
      }
      const matchingObject = this.translations.find(obj => obj.code === code);
      return matchingObject ? matchingObject.message : null;
    }
  },
  created() {
    this.translations = getLanguage();
    this.optionView = checkPermission("MD_LIST_VIEW")
    this.optionCreate = checkPermission("MD_LIST_CREATE")
    this.optionDel = checkPermission("MD_LIST_DEL")
    this.optionEdit = checkPermission("MD_LIST_EDIT")
    this.getClients();
  }
}
</script>

<style scoped>

</style>